import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotificationDrawerService {
  hidden = new BehaviorSubject<boolean>(true);

  togglePanel(): void {
    this.hidden.next(!this.hidden.value);
  }
}
