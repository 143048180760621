import { Injectable } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { TApiLiveCounter } from '../models';
import { ITechnician } from 'src/app/core/models';

@Injectable({
  providedIn: 'root',
})
export class LiveCounterService {
  private url = `${environment.apimBaseUrl}/dashboard/live-counts`;
  private headers = new HttpHeaders().set(
    'Ocp-Apim-Subscription-Key',
    environment.apimSubscriptionKey
  );

  constructor(private readonly http: HttpClient) {}

  public getLiveCounter(
    labTechnician: ITechnician
  ): Observable<TApiLiveCounter> {
    return this.http
      .get<TApiLiveCounter>(
      `${this.url}/${labTechnician.id}/${labTechnician.labCenterAlias}`,
      { headers: this.headers }
    )
      .pipe(
        map((result) => result),
        catchError(this.handleError('fetchLiveCounter', this.url))
      );
  }

  private handleError(operation: string, url: string) {
    return (err: any) => {
      let errMsg = `ERROR in ${operation}() retrieving ${url}`;
      console.error(`${errMsg}:`, err);
      if (err instanceof HttpErrorResponse) {
        errMsg = `status: ${err.status}, ${err.statusText}`;
      }
      return throwError(() => new Error(errMsg));
    };
  }
}
