import { TApiLiveCounter } from '.';
import {
  computeMonthCount,
  computeWeekCount,
  formatPeriod,
  formatTodayPeriod,
  getWeekNumberOfYear,
} from '../utils/live-counter.utils';

export interface ILiveCounter {
  today: {
    date: string;
    count: number;
  };
  this_week: TApiLiveCounter['weeks'][number] & { date: string; count: number };
  this_month: TApiLiveCounter & { date: string; count: number };
}

export class LiveCounter implements ILiveCounter {
  today: { date: string; count: number };
  this_week: TApiLiveCounter['weeks'][number] & { date: string; count: number };
  this_month: TApiLiveCounter & { date: string; count: number };

  constructor(apiLiveCounter: TApiLiveCounter) {
    this.today = {
      date: formatTodayPeriod(new Date()),
      count:
        apiLiveCounter['weeks'][getWeekNumberOfYear(new Date())]['days'][
          new Date().toLocaleString('en-US', { weekday: 'long' })
        ] ?? 0,
    };
    this.this_week = {
      ...apiLiveCounter['weeks'][getWeekNumberOfYear(new Date())],
      ...{
        date: formatPeriod({
          startDate: new Date(
            apiLiveCounter['weeks'][getWeekNumberOfYear(new Date())].startDate
          ),
          endDate: new Date(
            apiLiveCounter['weeks'][getWeekNumberOfYear(new Date())].endDate
          ),
        }),
        count: computeWeekCount(
          apiLiveCounter['weeks'][getWeekNumberOfYear(new Date())]['days']
        ),
      },
    };
    this.this_month = {
      ...apiLiveCounter,
      ...{
        date: formatPeriod({
          startDate: new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            1
          ),
          endDate: new Date(
            new Date().getFullYear(),
            new Date().getMonth() + 1,
            0
          ),
        }),
        count: computeMonthCount(apiLiveCounter['weeks']),
      },
    };
  }
}
