import { Component, Input } from '@angular/core';
import { ILabCenter } from 'src/app/core/models';
import {
  trigger,
  state,
  style,
  animate,
  transition,
  keyframes,
} from '@angular/animations';
import { ILiveCounter } from '../../models/live-counter.model';

@Component({
  selector: 'ztp-live-count',
  templateUrl: './live-count.component.html',
  styleUrls: ['./live-count.component.scss'],
  animations: [
    trigger('squash', [
      state(
        '*',
        style({
          'text-transform': 'uppercase',
        })
      ),
      transition(':increment', [
        animate(
          '300ms',
          keyframes([
            style({
              transform: 'translateY(-20px)',
            }),
            style({
              transform: 'translateY(0px)',
            }),
          ])
        ),
        animate('300ms'),
      ]),
    ]),
  ],
})
export class LiveCountComponent {
  @Input() currentLabCenter: ILabCenter;

  @Input() ablyRealTimeCount = 0;

  @Input() liveCount:
  | ILiveCounter['today']
  | ILiveCounter['this_week']
  | ILiveCounter['this_month'];
}
