<div class="result-unit-card-placeholder">
  <div class="avatar placeholder"><span class="content"></span></div>
  <div class="infos">
    <div class="infos1">
      <div class="left placeholder"><span class="content"></span></div>
      <div class="right placeholder"><span class="content"></span></div>
    </div>
    <div class="infos2">
      <div class="left placeholder"><span class="content"></span></div>
      <div class="right placeholder"><span class="content"></span></div>
    </div>
    <div class="infos3">
      <div class="left placeholder"><span class="content"></span></div>
      <div class="right placeholder"><span class="content"></span></div>
    </div>
  </div>
</div>