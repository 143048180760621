import { TApiLiveCounter } from '../models';

const getWeekNumberOfYear = (d: Date): number => {
  d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
  d.setUTCDate(d.getUTCDate() + 6 - d.getUTCDay());
  const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
  return Math.floor((d.getTime() - yearStart.getTime()) / (7 * 86400000)) + 1;
};

const computeWeekCount = (
  weekLiveCouter: TApiLiveCounter['weeks'][number]['days']
): number =>
  Object.values(weekLiveCouter).reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0
  );

const computeMonthCount = (monthLiveCount: TApiLiveCounter['weeks']): number =>
  Object.values(monthLiveCount)
    .map((value) => Object.values(value.days))
    .reduce((accumulator, currentArray) => {
      const arraySum = currentArray.reduce(
        (arrayAccumulator, currentValue) => arrayAccumulator + currentValue,
        0
      );
      return accumulator + arraySum;
    }, 0);

const extractWeekPeriods = (
  monthLiveCount: TApiLiveCounter['weeks']
): Pick<TApiLiveCounter['weeks'][number], 'startDate' | 'endDate'>[] =>
  Object.values(monthLiveCount).map((value) => ({
    startDate: value.startDate,
    endDate: value.endDate,
  }));

const formatTodayPeriod = (value: Date): string =>
  `${value.toLocaleString('en-US', {
    weekday: 'short',
  })} ${value.toLocaleString('en-US', {
    day: 'numeric',
  })} ${value.toLocaleString('en-US', {
    month: 'long',
  })} ${value.getFullYear()}`;

const formatPeriod = (value: { startDate: Date; endDate: Date }): string =>
  `${value.startDate.toLocaleString('en-US', {
    weekday: 'short',
  })} ${value.startDate.toLocaleString('en-US', {
    day: 'numeric',
  })} - ${value.endDate.toLocaleString('en-US', {
    weekday: 'short',
  })} ${value.endDate.toLocaleString('en-US', {
    day: 'numeric',
  })} ${value.endDate.toLocaleString('en-US', {
    month: 'long',
  })} ${value.endDate.getFullYear()}`;

export {
  getWeekNumberOfYear,
  computeWeekCount,
  computeMonthCount,
  extractWeekPeriods,
  formatTodayPeriod,
  formatPeriod
};
