import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ztp-patient-info-item',
  templateUrl: './patient-info-item.component.html',
  styleUrls: ['./patient-info-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PatientInfoItemComponent implements OnInit {
  // tslint:disable-next-line: no-input-rename
  @Input() icon: string;

  // tslint:disable-next-line: no-input-rename
  @Input() title: string;

  // tslint:disable-next-line: no-input-rename
  @Input() description: string;

  // tslint:disable-next-line: no-input-rename
  @Input() date: Date;

  ngOnInit(): void {
  }

}
