import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ModalContent } from './modal.interface';
import { ModalService } from './modal.service';

@Component({
  selector: 'ztp-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent {
  @ViewChild('modal') modal: ElementRef;

  @Input() modalContent: ModalContent;

  constructor(private modalService: ModalService, private router: Router) {
  }

  public onPrimaryClick(): void {
    this.modalService.emitAction(this.modalContent.actions.primaryAction);
    this.modalService.emitAction(null);
    this.navigateToRoute(this.modalContent.route);
    this.hideModal();
  }

  public onSecondaryClick(): void {
    this.modalService.emitAction(this.modalContent.actions.secondaryAction);
    this.modalService.emitAction(null);
    this.hideModal();
  }

  public onClickOustide(): void {
    this.hideModal();
  }

  public navigateToRoute(route: string): void {
    if (route.length !== 0) {
      this.router.navigateByUrl(this.modalContent.route);
    }
    return null;
  }


  private hideModal(): void {
    this.modal.nativeElement.className = this.modal.nativeElement.className.replace(
      /\bmodal-visible\b/g,
      'modal-hidden',
    );
    this.modalService.destroy();
  }

}

// TODO:DONE You should use input set / getters when the input value has to be muttated before use
// If not, use the decorator on a variable

// TODO:DONE Author:Cleef
// Denis You have to use the Angular way to access the DOM in line 36.
