import { Pipe, PipeTransform } from '@angular/core';
import { TWeekStatsPeriod } from '../models';

@Pipe({
  name: 'weekPeriod',
  standalone: true,
})
export class WeekPeriodPipe implements PipeTransform {
  transform(value: TWeekStatsPeriod): string {
    return `${new Date(Date.parse(value.startDate)).toLocaleString('en-US', {
      weekday: 'short',
      day: 'numeric',
    })} - ${new Date(Date.parse(value.endDate)).toLocaleString('en-US', {
      weekday: 'short',
      day: 'numeric',
    })} ${new Date(Date.parse(value.endDate)).toLocaleString('en-US', {
      month: 'short',
    })}`;
  }
}
