/* istanbul ignore file */
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector';

import { AdvanceSearchService } from './advance-search.service';
import { AuthenticationService, LabCenterService } from 'src/app/core/services';

import CountriesCodeIso2 from '../../../../assets/countries';
import { ITechnician } from 'src/app/core/models';
import { ResultUnit } from '../../result-unit-list/components/result-unit-card/result-unit.interface';
import { EApprovalStatus, ISearchParams } from 'src/app/core/models/search-params.interface';

@Component({
  selector: 'ztp-advance-search',
  templateUrl: './advance-search.component.html',
  styleUrls: ['./advance-search.component.scss'],
})
export class AdvanceSearchComponent implements OnInit, OnDestroy {
  @Output() onFiltersShown = new EventEmitter<boolean>();

  @Output() onSearchTermChange = new EventEmitter<boolean>();

  @Output() onSearchButtonClick = new EventEmitter<boolean>();

  @Output() onSearchParamsChange = new EventEmitter<ISearchParams>();

  @Input() searchBannerData: {
    searchResultsCount: number;
    searchResultsMinDate: number;
    searchResultsMaxDate: number;
  };

  searchParams: ISearchParams = {};

  filterParams: ISearchParams = {};

  studyNames = [];

  studyNamesClone = [];

  departments = [];

  labTechnician: ITechnician;

  selectedCountryCodeIso2: string;

  africaCountriesCodeIso2 = [];

  showFilters: boolean;

  isShowUnApproved = false;

  fetching = true;

  deviceInfo: DeviceInfo;

  browserLanguage: string[];

  @ViewChild('filterBar') filterBar;

  private studyNamesSubscription: Subscription;

  private storeSub: Subscription;

  private advanceSearchServiceSub: Subscription;

  constructor(
    private labCenterService: LabCenterService,
    private authenticationService: AuthenticationService,
    private advanceSearchService: AdvanceSearchService,
    private deviceService: DeviceDetectorService,
  ) { }

  ngOnInit(): void {
    this.loadStudiesData();
    this.browserLanguage = navigator.language?.split('-') ?? ['en','US'];
    this.africaCountriesCodeIso2 = CountriesCodeIso2.africa;
    this.deviceInfo = this.deviceService.getDeviceInfo();
  }

  public toggleFiltersBar(): void {
    this.showFilters = !this.showFilters;
    this.onFiltersShown.emit(this.showFilters);
  }

  public setSearchTerm(term: string): void {
    this.onSearchTermChange.emit(true);
    if (term && term.length > 0) {
      delete this.searchParams.zovuId;
      delete this.searchParams.resultUnitIdAlias;
      delete this.searchParams.studyName;
      delete this.searchParams.patientName;
      if (/^([A-Z][A-Z])-(\d{9})$/.test(term)) {
        this.searchParams.zovuId = term;
      } else if (/^\d+$/.test(term)) {
        this.searchParams.resultUnitIdAlias = ~~term;
      } else {
        const labStudyName = this.studyNames.find(({ name }) => name.toLowerCase() === term.toLowerCase());
        if (labStudyName) {
          this.searchParams.studyName = labStudyName.name;
        } else {
          this.searchParams.patientName = term;
        };
      }
      this.searchBySearchTerm();
    } else {
      this.resetLabResults();
      this.searchParams = {};
    }
  }

  private searchBySearchTerm(): void {
    this.advanceSearchServiceSub = this.advanceSearchService
      .searchResultUnits(this.labTechnician, this.searchParams)
      .subscribe({
        next: (labResults: ResultUnit[]) => {
          this.fetching = labResults?.length > 0 ? false : true;
          this.onSearchParamsChange.emit(this.searchParams);
          this.onSearchTermChange.emit(false);
        },
        error: (err) => {
          console.log(err);
          this.onSearchParamsChange.emit({});
          this.onSearchTermChange.emit(false);
        },
      });
  }

  public setFilters(filters: any): void {
    this.filterParams = {};
    if (filters['patientNameInput']?.length > 0) {
      this.filterParams.patientName = filters['patientNameInput'];
    } else { delete this.filterParams.patientName; }

    if (filters['zovuIdIso2code']?.length > 0 && filters['zovuInput']?.length > 0) {
      const zovuid = `${filters['zovuIdIso2code']}-${filters['zovuInput'].replace(/\D/g, '').trim()}`;
      if (/^([A-Z][A-Z])-(\d{9})$/.test(zovuid)) {
        this.filterParams.zovuId = zovuid;
      } else { delete this.filterParams.zovuId; }
    } else { delete this.filterParams.zovuId; }

    if (filters['resultUnitIdInput']?.length > 0) {
      if (/^\d+$/.test(filters['resultUnitIdInput'])) {
        this.filterParams.resultUnitIdAlias = ~~filters['resultUnitIdInput'];
      } else { delete this.filterParams.resultUnitIdAlias; }
    } else { delete this.filterParams.resultUnitIdAlias; }

    if (filters['studyNameInput']?.length > 0) {
      this.filterParams.studyName = filters['studyNameInput'];
    } else { delete this.filterParams.studyName; }

    if (filters['departmentInput']?.length > 0) {
      this.filterParams.labDepartment = filters['departmentInput'];
      this.generateStudies(filters['departmentInput']);
    } else {
      delete this.filterParams.labDepartment;
      this.studyNames = this.studyNamesClone;
    }

    if (filters['startDateInput']?.length > 0) {
      this.filterParams.resultMinDate = new Date(filters['startDateInput']).getTime();
    } else { delete this.filterParams.resultMinDate; }

    if (filters['endDateInput']?.length > 0) {
      this.filterParams.resultMaxDate = new Date(filters['endDateInput']).getTime() + 86400000;
    } else { delete this.filterParams.resultMaxDate; }
  }

  public searchByFilters(): void {
    if (this.deviceInfo.deviceType !== 'desktop') {
      this.toggleFiltersBar();
    }
    this.onSearchButtonClick.emit(true);
    this.advanceSearchServiceSub = this.advanceSearchService
      .searchResultUnits(this.labTechnician, this.filterParams)
      .subscribe({
        next: (labResults: ResultUnit[]) => {
          this.fetching = labResults?.length > 0 ? false : true;
          this.onSearchParamsChange.emit(this.filterParams);
          this.onSearchButtonClick.emit(false);
        },
        error: (err) => {
          console.log(err);
          this.onSearchParamsChange.emit({});
          this.onSearchButtonClick.emit(false);
        },
      });
  }

  public fetchMore(): void { } //TODO: implement search more

  public cancelFilters(): void {
    this.toggleFiltersBar();
    this.studyNames = this.studyNamesClone;
    if (this.isFilterActive()) { this.resetLabResults(); };
    this.filterParams = {};
  }

  public clearFilters(): void {
    this.filterBar?.filterForm.reset();
    this.filterParams = {};
    this.studyNames = this.studyNamesClone;
    this.searchParams = {};
    this.resetLabResults();
  }

  public showUnapproved(e): void {
    this.isShowUnApproved = e?.target.checked;
    if (this.isSearchActive()) {
      if (e?.target.checked) {
        this.searchParams.labSubmissionStatus = EApprovalStatus.UNAPPROVED;
        this.searchBySearchTerm();
      } else {
        delete this.searchParams.labSubmissionStatus;
        this.searchBySearchTerm();
      }

    } else if (this.isFilterActive()) {
      if (e?.target.checked) {
        this.filterParams.labSubmissionStatus = EApprovalStatus.UNAPPROVED;
        this.searchByFilters();
      } else {
        delete this.filterParams.labSubmissionStatus;
        this.searchByFilters();
      }

    } else {
      if (e?.target.checked) {
        this.resetLabResults({ labSubmissionStatus: EApprovalStatus.UNAPPROVED });
        this.onSearchParamsChange.emit({ labSubmissionStatus: EApprovalStatus.UNAPPROVED });
      } else {
        this.onSearchParamsChange.emit({});
        this.resetLabResults();
      }
    }
  }

  private loadStudiesData(): void {
    this.storeSub = this.authenticationService
      .getCurrentLabTechnician()
      .subscribe((labTechnician) => {
        this.labTechnician = labTechnician;
        this.selectedCountryCodeIso2 = labTechnician.countryCodeISO2;
        this.studyNamesSubscription = this.labCenterService
          .getTechnicianLabCenterDetails(
            labTechnician?.id,
            labTechnician?.labCenterAlias
          )
          .subscribe((labCenterDetails) => {
            this.studyNames = labCenterDetails?.availableStudies;
            this.studyNamesClone = labCenterDetails?.availableStudies;
            this.generateDepartments(labCenterDetails?.departments);
          });
      });
  }

  private generateDepartments(dep: string[]): void {
    this.departments = dep.map((depElmt) => ({ name: depElmt }));
  }

  private generateStudies(dep: string): void {
    this.studyNames = this.studyNamesClone.filter((study) => study.category === dep);
  }

  public isSearchActive(): boolean {
    return Object.keys(this.searchParams).length !== 0;
  }

  public isFilterActive(): boolean {
    return Object.keys(this.filterParams).length !== 0;
  }

  public resetLabResults(params: ISearchParams = {}): void {
    this.onSearchTermChange.emit(true);
    // this.onSearchButtonClick.emit(true);

    this.advanceSearchServiceSub = this.advanceSearchService.refreshLabResults(this.labTechnician, params).subscribe({
      next: () => {
        this.onSearchTermChange.emit(false);
        // this.onSearchButtonClick.emit(false);
      }
    });
  }

  ngOnDestroy(): void {
    this.studyNamesSubscription?.unsubscribe();
    this.storeSub?.unsubscribe();
    this.advanceSearchServiceSub?.unsubscribe();
  }
}
