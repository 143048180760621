import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
  selector: 'ztp-filter-bar',
  templateUrl: './filter-bar.component.html',
  styleUrls: ['./filter-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterBarComponent implements OnInit, AfterViewInit {
  filterForm = new FormGroup({
    patientNameInput: new FormControl(''),
    resultUnitIdInput: new FormControl(''),
    studyNameInput: new FormControl(null),
    departmentInput: new FormControl(null),
    zovuInput: new FormControl(''),
    zovuIdIso2code: new FormControl(''),
    startDateInput: new FormControl(''),
    endDateInput: new FormControl(''),
  });

  maxZovuIdInputLength = 11;

  maxResultUnitIdInputLength = 8;

  showDatePicker = false;

  @Input() departmentsData: [];

  @Input() testNamesData: [];

  @Input() countriesCodeIso2: [];

  @Input()
  set selectedCountryCode(code: string) {
    this.filterForm.controls['zovuIdIso2code'].setValue(code);
  }

  @Output() onFiltersChange = new EventEmitter<
  Partial<{
    patientNameInput: string;
    resultUnitIdInput: string;
    studyNameInput: any;
    departmentInput: any;
    zovuInput: string;
    zovuIdIso2code: string;
    startDateInput: string;
    endDateInput: string;
  }>
  >();

  @ViewChild('ZovuIdSelecter') zovuIdSelectBox: NgSelectComponent;

  @ViewChild('TestNamesSelecter') testNamesSelectBox: NgSelectComponent;

  @ViewChild('DepartmentsSelecter') departmentsSelectBox: NgSelectComponent;

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.filterForm.valueChanges.subscribe(() => {
      this.onFiltersChange.emit(this.filterForm?.value);
    });
  }

  public openTestNameSelectBox(): void {
    this.testNamesSelectBox.open();
  }

  public openDepartmentSelectBox(): void {
    this.departmentsSelectBox.open();
  }

  public openZovuIdSelectBox(): void {
    this.zovuIdSelectBox.open();
  }

  public customSearch(term: string, item: any): boolean {
    return item?.name.toLowerCase().indexOf(term?.toLowerCase()) > -1;
  }

  public onResultUnitIdInput(e): void {
    const numericPattern = /^\d+$/;
    if (!numericPattern.test(e?.target.value)) {
      this.filterForm.controls['resultUnitIdInput'].setValue(
        e?.target.value.replace(/\D/g, '').trim()
      );
    }
  }

  public onZovuIdInput(e): void {
    let trimmedValue = e?.target.value.replace(/\D/g, '');
    this.filterForm.controls['zovuInput'].setValue(
      trimmedValue
        .split(/(\d{3})/)
        .filter(Boolean)
        .join(' ')
        .trim()
    );
  }

  public onFilterDatesChange(dates: FormGroup): void {
    if (dates?.value) {
      const { startDateInput, endDateInput } = dates.value;
      this.filterForm.controls.startDateInput.setValue(startDateInput);
      this.filterForm.controls.endDateInput.setValue(endDateInput);
    }
  }

  public onClickOustide(): void {
    this.showDatePicker = false;
  }

  public toggleDatePicker(): void {
    this.showDatePicker = !this.showDatePicker;
  }
}
