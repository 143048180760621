export const appPopOutIcon = {
  data: `<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_4271_1278)">
    <path d="M13.5 16.5H2.5C2.23478 16.5 1.98043 16.3946 1.79289 16.2071C1.60536 16.0196 1.5 15.7652 1.5 15.5V4.5C1.5 4.23478 1.60536 3.98043 1.79289 3.79289C1.98043 3.60536 2.23478 3.5 2.5 3.5H7.5V4.5H2.5V15.5H13.5V10.5H14.5V15.5C14.5 15.7652 14.3946 16.0196 14.2071 16.2071C14.0196 16.3946 13.7652 16.5 13.5 16.5Z" fill="currentColor"/>
    <path d="M9.00008 1.5C8.86748 1.5 8.7403 1.55268 8.64653 1.64645C8.55276 1.74021 8.50008 1.86739 8.50008 2C8.50008 2.13261 8.55276 2.25979 8.64653 2.35355C8.7403 2.44732 8.86748 2.5 9.00008 2.5H14.7951L7.87008 9.425C7.81774 9.46982 7.77523 9.52498 7.74522 9.58702C7.71521 9.64905 7.69834 9.71661 7.69569 9.78547C7.69303 9.85433 7.70463 9.923 7.72977 9.98716C7.7549 10.0513 7.79303 10.1096 7.84176 10.1583C7.89049 10.2071 7.94876 10.2452 8.01292 10.2703C8.07709 10.2955 8.14575 10.3071 8.21461 10.3044C8.28347 10.3017 8.35104 10.2849 8.41307 10.2549C8.4751 10.2249 8.53026 10.1823 8.57508 10.13L15.5001 3.205V9C15.5001 9.13261 15.5528 9.25979 15.6465 9.35355C15.7403 9.44732 15.8675 9.5 16.0001 9.5C16.1327 9.5 16.2599 9.44732 16.3536 9.35355C16.4474 9.25979 16.5001 9.13261 16.5001 9V1.5H9.00008Z" fill="currentColor"/>
    </g>
    <defs>
    <clipPath id="clip0_4271_1278">
    <rect width="18" height="18" fill="white"/>
    </clipPath>
    </defs>
    </svg>
    `,
  name: 'pop-out',
};
