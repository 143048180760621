import { IPatient } from 'src/app/features/result-unit-list/components/patient-card/patient.interface';
import { ResultUnit } from 'src/app/features/result-unit-list/components/result-unit-card/result-unit.interface';
import { SubmissionDraft } from 'src/app/features/result-unit-upload/submission-page/submission.interface';
export enum EVENT {
  'SOCKET NOTIFICATIONS',
}


export interface ILogEntry {
  client: string;
  userId: string;
  labCenterId: string;
  type: string | object;
  event: string;
  subject: string;
  value: string;
  state: LogState;
}

export interface ILog {
  date: Date;
  userId: string;
  labCenterId: string;
  type: string | object;
  log: LogItem;
}

export type LogState = {
  currentPatient: IPatient;
  currentLabResult: ResultUnit;
  labTechnician: {
    id: number;
    firstName: string;
    lastName: string;
    privilegeLevel: string;
  };
  pendingLabResults: SubmissionDraft[];
};

export type LogItem = {
  state?: LogState;
  activity?: string;
};


export class LogEntry {
  client: string;

  date: Date;

  labCenterId: string;

  userId: string;

  type: string | object;

  log: LogItem;

  event: string;

  subject: string;

  value: string;

  state: LogState;

  constructor(data: ILogEntry) {
    this.type = data.type;
    this.labCenterId = data.labCenterId;
    this.userId = data.userId;
    this.subject = data.subject;
    this.event = data.event;
    this.value = data.value;
    this.state = data.state;
    this.client = data.client;
  }

  buildLogObject(): ILog {
    let state = this.type === 'activity' ? null : this.state;
    let activity = this.type === 'resultUnitState' ? '' : this.formatLogActivity();
    const log = {
      client: this.client,
      date: new Date(),
      userId: this.userId,
      labCenterId: this.labCenterId,
      type: this.type,
      log: {
        state,
        activity,
      },
    };

    return log;
  }

  formatLogActivity(): string {
    return this.event + ' : ' + this.subject + ' ' + this.value + ' ' + '.';
  }
}

