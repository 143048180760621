export default {
  africa: [
    { name: 'Algeria', iso2: 'DZ' },
    { name: 'Angola', iso2: 'AO' },
    { name: 'Benin', iso2: 'BJ' },
    { name: 'Botswana', iso2: 'BW' },
    { name: 'Burkina Faso', iso2: 'BF' },
    { name: 'Burundi', iso2: 'BI' },
    { name: 'Cameroon', iso2: 'CM' },
    { name: 'Cabo Verde', iso2: 'CV' },
    { name: 'Central African Republic', iso2: 'CF' },
    { name: 'Chad', iso2: 'TD' },
    { name: 'Comoros', iso2: 'KM' },
    { name: 'Congo', iso2: 'CG' },
    { name: 'Democratic Republic of Congo', iso2: 'CD' },
    { name: 'Ivory Coast', iso2: 'CI' },
    { name: 'Djibouti', iso2: 'DJ' },
    { name: 'Egypt', iso2: 'EG' },
    { name: 'Equatorial Guinea', iso2: 'GQ' },
    { name: 'Eritrea', iso2: 'ER' },
    { name: 'Ethiopia', iso2: 'ET' },
    { name: 'Gabon', iso2: 'GA' },
    { name: 'Gambia', iso2: 'GM' },
    { name: 'Ghana', iso2: 'GH' },
    { name: 'Guinea', iso2: 'GN' },
    { name: 'Guinea-Bissau', iso2: 'GW' },
    { name: 'Kenya', iso2: 'KE' },
    { name: 'Lesotho', iso2: 'LS' },
    { name: 'Liberia', iso2: 'LR' },
    { name: 'Libya', iso2: 'LY' },
    { name: 'Madagascar', iso2: 'MG' },
    { name: 'Malawi', iso2: 'MW' },
    { name: 'Mali', iso2: 'ML' },
    { name: 'Mauritania', iso2: 'MR' },
    { name: 'Mauritius', iso2: 'MU' },
    { name: 'Mayotte', iso2: 'YT' },
    { name: 'Morocco', iso2: 'MA' },
    { name: 'Mozambique', iso2: 'MZ' },
    { name: 'Namibia', iso2: 'NA' },
    { name: 'Niger', iso2: 'NE' },
    { name: 'Nigeria', iso2: 'NG' },
    { name: 'Réunion', iso2: 'RE' },
    { name: 'Rwanda', iso2: 'RW' },
    { name: 'Saint Helena, Ascension and Tristan da Cunha', iso2: 'SH' },
    { name: 'Sao Tome and Principe', iso2: 'ST' },
    { name: 'Senegal', iso2: 'SN' },
    { name: 'Seychelles', iso2: 'SC' },
    { name: 'Sierra Leone', iso2: 'SL' },
    { name: 'Somalia', iso2: 'SO' },
    { name: 'South Africa', iso2: 'ZA' },
    { name: 'Sudan', iso2: 'SD' },
    { name: 'Switzerland', iso2: 'CH' },
    { name: 'Tanzania', iso2: 'TZ' },
    { name: 'Togo', iso2: 'TG' },
    { name: 'Tunisia', iso2: 'TN' },
    { name: 'Uganda', iso2: 'UG' },
    { name: 'Western Sahara', iso2: 'EH' },
    { name: 'Zambia', iso2: 'ZM' },
    { name: 'Zimbabwe', iso2: 'ZW' },
  ],
};
