export enum ESubmissionNotificationAction {
  CANCEL = 'cancel',
  CLOSE = 'close',
  RETRY = 'retry',
  RE_UPLOAD = 're_upload',
}

export interface ISubmissionNotification {
  resultUnitId: string;
  submissionId: string;
  submissionDatetime: number;
  labCenter: {
    id: string;
    alias: string;
  };
  patientId: string;
  technicianId: string;
  mediaId: string;
}
