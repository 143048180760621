<span
  [ngClass]="{
    'badge': true, 
    'development': env==='dev',
    'qa': env==='qa',
    'staging': env==='stg',
    'production': env==='prod'
  }"
>
{{ env | uppercase}}
</span>
