/* istanbul ignore file */
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ztp-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss'],
})
export class HelpComponent implements OnInit {

  ngOnInit(): void {
  }

}
