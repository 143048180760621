<div class="upload-card" *transloco="let t; read: 'upload_panel'">
    <h3 class="title">{{ t('upload_header') }}</h3>
    <div [ngClass]="{'drag-camera-zone': true, 'drag-camera-zone-disabled': invalidFilesQueue?.length > 0, 'drag-camera-zone-disabled-onedit': !canEditLabResult}">
        <div ng2FileDrop (fileOver)="fileOverBase($event)" (dragover)="setFileDragOver($event)"
            (dragleave)="setFileDragLeave($event)" [uploader]="uploader" class="drag-zone" (click)="touchDragZone()">
            <div class="drop-image">
                <input [disabled]="fileQueueInvalid" type="file" id="upload-file" (click)="setFileSelectBoxOpen($event)" ng2FileSelect
                    [uploader]="uploader" multiple [formControl]="uploadControl" />
                <label for="upload-file" class="drop-text">
                    <span class="plus">+&nbsp;</span>
                    <span class="drop-desktop">{{ t('upload_or_drag_button') }}</span>
                    <span class="drop-tablet">{{ t('upload_button') }}</span>
                </label>
            </div>
        </div>
        <div class="camera-zone">
            <input [disabled]="fileQueueInvalid" type="file" class="custom-photo-input" capture="camera"
                id="cameraInput" name="cameraInput" (change)="onTakePicture($event)" (click)="touchDragZone()" />
            <label for="cameraInput" class="drop-text">
                <svg-icon icon="camera" fontSize="30px" key="camera" class="camera-icon"></svg-icon>
                &nbsp;
                <span class="drop">{{ t('capture_button') }}</span>
            </label>
        </div>
    </div>

    <div *ngIf="uploader && uploader.queue.length > 0" class="drop-info">
        <span class="info-selected">{{filesCounterMessage(uploader.queue.length, 'uploaded')}}</span>
        <span *ngIf="false" class="info-progress">{{filesCounterMessage(0, 'uploading')}}</span>
        <span *ngIf="invalidFilesQueue?.length > 0" class="info-error">{{filesCounterMessage(invalidFilesQueue?.length,
            'invalid')}}</span>
    </div>

    <div class="files" *ngIf="uploader && uploader.queue.length > 0">
        <ng-container *ngFor="let item of uploader.queue">
            <ng-container *ngIf="validFileExtensions.indexOf(item?.file?.type) == -1;else sizeCheck">
                <div [ngClass]="{'file-tile': true, 'file-tile-invalid': true}">
                    <span class="filename">{{item?.file?.name}}</span>
                    <span class="message">{{ t('invalid_file_type_msg') }}</span>
                    <svg-icon key="close-tile" class="close-icon" (click)="removeItem(item)"></svg-icon>
                </div>
            </ng-container>
            <ng-template #sizeCheck>
                <ng-container *ngIf="maxFileSize < item?.file?.size;else fileValid">
                    <div [ngClass]="{'file-tile': true, 'file-tile-invalid': true}">
                        <span class="filename">{{item?.file?.name}}</span>
                        <span class="message">{{ t('invalid_file_size_msg') }}</span>
                        <svg-icon key="close-tile" class="close-icon" (click)="removeItem(item)"></svg-icon>
                    </div>
                </ng-container>
            </ng-template>
            <ng-template #fileValid>
                <div [ngClass]="{'file-tile': true, 'file-tile-valid': true}">
                    <span (click)="openPreview(item)" class="filename">{{item?.file?.name}}</span>
                    <svg-icon key="close-tile" class="close-icon" (click)="checkBeforeRemoveItem(item)"></svg-icon>
                </div>
            </ng-template>
        </ng-container>

        <!--
            <div [ngClass]="{'file-tile': true, 'file-tile-uploading': true}">
            <span class="loader"></span>
            <span class="message">{{ t('uploading_file_msg') }}</span>
        </div>
        -->
    </div>
</div>