import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { environment } from 'src/environments/environment';

@Component({
  selector: 'ztp-env-badge',
  templateUrl: './env-badge.component.html',
  styleUrls: ['./env-badge.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EnvBadgeComponent implements OnInit {
  env: string;

  ngOnInit(): void {
    this.env = environment.nodeEnv;
  }
}
