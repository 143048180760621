/* eslint-disable @angular-eslint/directive-selector */
import {
  Directive,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { FeatureFlagsService } from './feature-flags.service';

@Directive({
  selector: '[featureFlag]',
})
export class FeatureFlagDirective implements OnInit {
  private requiredFlag = '';

  private isHidden = true;

  @Input() set featureFlag(val: string) {
    if (val) {
      this.requiredFlag = val;
      this.updateView();
    }
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private featureFlags: FeatureFlagsService,
  ) {}

  ngOnInit() {
    this.updateView();
  }

  private updateView() {
    // if (this.checkValidity()) {
    // eslint-disable-next-line no-constant-condition
    if (false) {
      if (this.isHidden) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.isHidden = false;
      }
    } else {
      this.viewContainer.clear();
      this.isHidden = true;
    }
  }

  private checkValidity() {
    return (
      this.requiredFlag &&
        this.featureFlags.isFeatureFlagEnabled(this.requiredFlag)
    );
  }
}
