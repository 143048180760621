import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IHealthcareProvider } from '../models/healthcare-provider';

@Injectable({
  providedIn: 'root',
})
export class HealthcareProviderService {
  private readonly url = 'http://localhost:3000/healthentities/services';
  private readonly headers = new HttpHeaders().set(
    'Ocp-Apim-Subscription-Key',
    environment.apimSubscriptionKey
  );

  constructor(private readonly http: HttpClient) {}

  public getProvider(
    isoCountry: string
  ): Observable<IHealthcareProvider['provider'][]> {
    return this.http
      .get<IHealthcareProvider['provider'][]>(
      `${this.url}/technician?isoCountry=${isoCountry}`
      // {
      //   headers: this.headers,
      // }
    )
      .pipe(
        map((provider) =>
          provider.map((p) => ({
            ...p,
            name: `${p.title} ${p.givenName} ${p.surname}`,
            id: p.userId,
          }))
        ),
        catchError(this.handleError('getProvider'))
      );
  }

  public getClinic(
    isoCountry: string
  ): Observable<IHealthcareProvider['clinic'][]> {
    return this.http
      .get<IHealthcareProvider['clinic'][]>(
      `${this.url}/clinic?isoCountry=${isoCountry}`
      // {
      //   headers: this.headers,
      // }
    )
      .pipe(
        map((clinic) => clinic),
        catchError(this.handleError('getClinic'))
      );
  }

  private handleError(operation: string): (err: any) => Observable<never> {
    return (err: any) => {
      let errMsg = `ERROR in ${operation}() retrieving ${this.url}`;
      console.error(`${errMsg}:`, err);
      if (err instanceof HttpErrorResponse) {
        errMsg = `status: ${err.status}, ${err.statusText}`;
      }
      return throwError(() => new Error(errMsg));
    };
  }
}
