<div
  [ngClass]="{ 'result-list': true, 'result-list-filters': this.showFilters }"
  *transloco="let t; read: 'result_list'"
>
  <div class="result-list-header">
    <ztp-advance-search
      (onFiltersShown)="toggleFiltersView($event)"
      (onSearchTermChange)="showSearchLoader($event)"
      (onSearchButtonClick)="showSearchLoader($event)"
      (onSearchParamsChange)="setSearchParams($event)"
      [searchBannerData]="searchResultsMeta"
      class="advance-search"
    ></ztp-advance-search>
  </div>
  <div class="result-list-body">
    <div class="list-section">
      <div
        class="placeholder-section"
        *ngIf="showLoadPlaceHolder; else showLabResults"
      >
        <ztp-result-unit-card-placeholder
          *ngFor="let item of fakeLabs; trackBy: trackByFn"
        ></ztp-result-unit-card-placeholder>
      </div>
      <ng-template #showLabResults>
        <div
          class="search-results"
          infiniteScroll
          [infiniteScrollDistance]="2.5"
          [infiniteScrollThrottle]="50"
          (scrolled)="onScroll()"
          [scrollWindow]="false"
          *ngIf="labResults?.length !== 0; else showNoResult"
        >
          <ztp-result-unit-card
            *ngFor="let labResult of labResults; let currentIndex = index; trackBy: trackByFn"
            [labResult]="labResult"
            [approvedMarkerMsg]="t('approved_marker')"
            [active]="labResult?.id == currentLabResult?.id"
            (click)="setSelectedLabResult(labResult, currentIndex)"
            class="list-item"
          >
          </ztp-result-unit-card>
          <div
            class="placeholder-next"
            *ngIf="showMorePlaceHolder"
            >
            <ztp-result-unit-card-placeholder
              *ngFor="let item of moreFakeLabs; trackBy: trackByFn"
            ></ztp-result-unit-card-placeholder>
          </div>
        </div>
        <ng-template #showNoResult>
          <div class="no-result">
            {{ t('no_result_msg') }}
            <!-- {{t('search_no_result_msg')}} -->
            <img
              src="/assets/images/no-result.svg"
              alt=""
              height="120"
              width="180"
            />
          </div>
        </ng-template>
      </ng-template>
    </div>

    <div
      *ngIf="currentLabResult && labResults.length !== 0"
      class="infos-section"
    >
      <div (click)="closeDetailsBody()" class="go-back">
        <svg-icon key="chevron-back" fontSize="18px"></svg-icon>
        <span>Back</span>
      </div>
      <ztp-patient-card
        [patient]="currentLabResult?.patient"
        class="demographic-info"
      ></ztp-patient-card>
      <ztp-result-unit-preview
        [labResult]="currentLabResult"
        [labTechnician]="currentLabTechnician"
        [labResultIndex]="currentLabResultIndex"
        (onApprovalRequest)="showLoader($event)"
        (onLabResultChange)="setSelectedLabResult($event, null)"
        class="lab-result-body"
      ></ztp-result-unit-preview>
    </div>
  </div>
</div>
<div *ngIf="showLoadRequestLoader" class="approval-spinner">
  <div class="spinner"></div>
</div>
