<div
  [class]="'report-card ' + status"
  [class.notavailable]="reportAvailable === false"
>
  <div>
    <span class="lab-name">{{
      currentLabCenter.name.length > 19
        ? (currentLabCenter.name | slice: 0:19) + '...'
        : currentLabCenter.name
    }}</span>
    <span class="address"
      >{{ currentLabCenter.city }}, {{ currentLabCenter.country }}</span
    >
  </div>
  <div>
    <span class="period">1 - {{ report[0] | monthDays }} {{ report[0] }}</span>
    <span (click)="downloadPdf(report)" class="monthly-report"
      ><span>{{ 'dashboard.report-archives.monthly-report' | transloco }}</span>
      <svg-icon *ngIf="!reportDownloadInProgress" key="pop-out"></svg-icon>
      <div *ngIf="reportDownloadInProgress" class="lds-dual-ring"></div>
    </span>
    <span *ngIf="reportAvailable" class="count" [class.count-active]="status"
      ><span class="results-submitted">{{
        report[1]?.submissionStatistics?.totalSubmissions
      }}</span
      >&nbsp;
      {{
        'dashboard.operation-summary.count-summary.results-submitted'
          | transloco
      }}</span
    >
    <span *ngIf="!reportAvailable" class="not-available-count">
      <svg-icon key="info"></svg-icon>
      <span>{{ 'dashboard.report-archives.empty-report' | transloco }}</span>
    </span>
  </div>
</div>
