/* eslint-disable @typescript-eslint/no-unused-vars */
import { ChartOptions } from 'chart.js';

export const chartConfig: ChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      ticks: {
        color: '#fff',
      },
      border: {
        display: false,
      },
      grid: {
        offset: false,
        display: false,
      },
    },
    y: {
      ticks: {
        color: '#fff',
      },
      border: {
        display: false,
      },
      grid: {
        display: false,
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      titleAlign: 'right',
      bodyAlign: 'right',
      usePointStyle: true,
      enabled: true,
      backgroundColor: '#333333',
      callbacks: {
        labelPointStyle(context) {
          return {
            pointStyle: false,
            rotation: 0,
          };
        },
      },
    },
  },
};
