import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { TimeagoIntl } from 'ngx-timeago';
import { getBrowserLang } from '@ngneat/transloco';
import { strings as englishStrings } from 'ngx-timeago/language-strings/en';
import { strings as frenchStrings } from 'ngx-timeago/language-strings/fr';

import { NotificationDrawerService } from './notification-drawer.service';
import { SubmissionService } from 'src/app/features/result-unit-upload/submission-page/submission-page.service';
import { dbService, IDbMedia } from '../../services/db.service';

import { ESubmissionMode, SubmissionDraft } from 'src/app/features/result-unit-upload/submission-page/submission.interface';
import { ESubmissionNotificationAction } from './notification-tile/notification.interface';
import { ResultUnitListService } from 'src/app/features/result-unit-list/result-unit-list-page/result-unit-list.service';

@Component({
  selector: 'ztp-notification-drawer',
  templateUrl: './notification-drawer.component.html',
  styleUrls: ['notification-drawer.component.scss']
})
export class NotificationDrawerComponent implements OnInit, OnDestroy {
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() close = new EventEmitter<boolean>();

  private notificationDrawerSub: Subscription;

  private labSubmissionsSub: Subscription;

  hidden = false;

  labSubmissions: SubmissionDraft[];

  constructor(private drawerService: NotificationDrawerService, private submissionService: SubmissionService, private resultListService: ResultUnitListService, private router: Router, intl: TimeagoIntl) {
    this.notificationDrawerSub = this.drawerService.hidden.subscribe(status => this.hidden = status);
    switch (getBrowserLang()) {
    case 'fr':
      intl.strings = frenchStrings;
      intl.changes.next();
      break;
    default:
      intl.strings = englishStrings;
      intl.changes.next();
      break;
    }
  }

  ngOnInit() {
    this.getNotifications();
  }

  onClose() {
    this.drawerService.togglePanel();
  }

  handleNotificationEvent(payload: { data: SubmissionDraft; action: ESubmissionNotificationAction }) {
    switch (payload?.action) {
    case ESubmissionNotificationAction.RETRY:
      this.retrySubmission(payload?.data);
      break;
    case ESubmissionNotificationAction.RE_UPLOAD:
      this.reUploadSubmission(payload?.data);
      break;
    case ESubmissionNotificationAction.CANCEL:
      this.cancelSubmission(payload?.data);
      break;
    case ESubmissionNotificationAction.CLOSE:
      this.closeSubmission(payload?.data);
      break;

    default:
      break;
    }
  }

  async retrySubmission(draft: SubmissionDraft): Promise<void> {
    let submissionDraft = new SubmissionDraft(draft?.resultUnit);
    let resultUnit = draft?.resultUnit;
    submissionDraft.startTimer();
    this.submissionService.addSumissionDraft(submissionDraft);
    const draftReport: IDbMedia = await dbService.retrieveSubmissionDraftMedia(draft?.submissionId);
    if (draftReport && draftReport.submissionId === draft.submissionId) {
      resultUnit.submission.media.rawFile = draftReport?.rawFile;
    }
    this.submissionService.addLabResult(draft?.resultUnit.submission.updateBy.id, resultUnit).subscribe();
  }

  reUploadSubmission(draft: SubmissionDraft): void {
    this.resultListService.saveCurrentLabResult(draft?.resultUnit);
    this.submissionService.setSubmissionMode(ESubmissionMode.RE_UPLOAD_SUBMISSON);
    this.router.navigate(['/submission']).then(() => {
      this.drawerService.togglePanel();
    });
  }

  cancelSubmission(draft: SubmissionDraft): void {
    this.submissionService.deleteSubmissionDraft(draft?.submissionId, true);
  }

  closeSubmission(draft: SubmissionDraft): void {
    this.submissionService.deleteSubmissionDraft(draft?.submissionId);
  }

  getNotifications() {
    this.labSubmissionsSub = this.submissionService.stateChanged.subscribe((state) => {
      if (state && state.submissionDrafts) {
        this.labSubmissions = Object.values(state.submissionDrafts);
      }
    });
  }

  ngOnDestroy() {
    this.notificationDrawerSub?.unsubscribe();
    this.labSubmissionsSub?.unsubscribe();
  }
}
