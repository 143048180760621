import {
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
  NgModule,
  ErrorHandler,
} from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  LocationStrategy,
  PathLocationStrategy,
  registerLocaleData,
} from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import localeFr from '@angular/common/locales/fr';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SvgIconsModule } from '@ngneat/svg-icon';
import {
  MsalGuard,
  MsalInterceptor,
  MsalBroadcastService,
  MsalModule,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
} from '@azure/msal-angular';
import { NgSelectModule } from '@ng-select/ng-select';
import { ApplicationinsightsAngularpluginErrorService } from '@microsoft/applicationinsights-angularplugin-js';

import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { ResultUnitUploadModule } from './features/result-unit-upload/result-unit-upload.module';
import { ResultUnitListModule } from './features/result-unit-list/result-unit-list.module';
import { TranslocoRootModule } from './transloco/transloco-root.module';

import {
  AuthenticationService,
  LogService,
  SocketServer,
} from './core/services';
import { SubmissionService } from './features/result-unit-upload/submission-page/submission-page.service';

import { FeatureFlagDirective } from './feature-flags-page/feature-flags.directive';

import { AppComponent } from './app.component';
import { ContactComponent } from './contact/contact.component';
import { HelpComponent } from './help/help.component';
import { NotfoundComponent } from './notfound/notfound.component';
import { FeatureFlagsPageComponent } from './feature-flags-page/feature-flags-page.component';
import { SignInPageComponent } from './sign-in-page/sign-in-page.component';

import { zovuIcons } from './svg/zovu';
import {
  MSALGuardConfigFactory,
  MSALInstanceFactory,
  MSALInterceptorConfigFactory,
} from './auth-config/auth-config';
import { DashboardModule } from './features/dashboard/dashboard.module';

registerLocaleData(localeFr, 'fr');
@NgModule({
  declarations: [
    AppComponent,
    ContactComponent,
    FeatureFlagDirective,
    FeatureFlagsPageComponent,
    HelpComponent,
    NotfoundComponent,
    SignInPageComponent,
  ],
  imports: [
    CoreModule,
    SharedModule,
    ResultUnitUploadModule,
    ResultUnitListModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    TranslocoRootModule,
    MsalModule,
    NgSelectModule,
    SvgIconsModule.forRoot({
      sizes: {
        xs: '10px',
        sm: '24px',
        md: '32px',
        lg: '50px',
        xl: '100px',
      },
      defaultSize: 'sm',
      icons: zovuIcons,
    }),
    DashboardModule,
  ],
  providers: [
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: ErrorHandler,
      useClass: ApplicationinsightsAngularpluginErrorService
    },
    MsalGuard,
    MsalService,
    MsalBroadcastService,
    AuthenticationService,
    LogService,
    SocketServer,
    SubmissionService,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class AppModule {}
