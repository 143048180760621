<div class="feature-flags">
    <div class="actions-bar">
      <label for="box" class="text">Set selected</label>
      <div class="switch button r">
        <input type="checkbox" id="box" class="check" [checked]="checkAll" (click)="performSwitchAction()">
        <div class="knobs"></div>
        <div class="layer"></div>
      </div>
    </div>
  
    <div class="header">
        <label class="select-all">
          <input type="checkbox" id="select" (click)="markAllAsSelected()" [selected]="">
          <span class="mark"></span>
        </label>
      <span class="name col">
        <img src="/assets/svg/sort.svg" alt="" width="35" height="35" /> Name
      </span>
  
      <span class="desc col">
        <img src="/assets/svg/sort.svg" alt="" width="35" height="35" />
        Description
      </span>
  
      <span class="group col">
        <img src="/assets/svg/sort.svg" alt="" width="35" height="35" /> Epic
      </span>
  
      <span class="date col">
        <img src="/assets/svg/sort.svg" alt="" width="35" height="35" /> Date
      </span>
    </div>
  
    <div *ngIf="flagsItemCount>0" class="content">
      <ng-container *ngFor="let feature of features | keyvalue">
        <ztw-feature-flag [selected]="selectAll" [feature]="feature?.value" (onSelectedEvent)="performSelectedAction($event)" class="feature-item"></ztw-feature-flag>
      </ng-container>
    </div>
    <div *ngIf="flagsItemCount==0" class="content-loader">
      <ng-container *ngFor="let loader of [0,1,2]">
        <div class="feature-item-loader">
          <div class="actions">
            <label class="container">
              <input type="checkbox" disabled>
              <span class="checkmark"></span>
            </label>
        
            <div class="switch button r">
              <input type="checkbox" class="checkbox" disabled>
              <div class="knobs"></div>
              <div class="layer"></div>
            </div>
          </div>
          <span class="name"><span class="delimiter"></span></span>
          <span class="desc">
          <span class="delimiter"></span>
          </span>
          <span class="group"><span class="delimiter"></span></span>
          <span class="date"><span class="delimiter"></span></span>
        </div>
      </ng-container>
    </div>
  </div>
  