import { ChartOptions } from 'chart.js';

export const chartConfig: ChartOptions = {
  scales: {
    x: {
      ticks: {
        color: '#545454',
      },
      border: {
        display: false,
      },
      grid: {
        offset: false,
        display: false,
      },
    },
    y: {
      ticks: {
        color: '#545454',
      },
      border: {
        display: false,
      },
      grid: {
        display: false,
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      titleAlign: 'right',
      bodyAlign: 'right',
      usePointStyle: true,
      enabled: true,
      backgroundColor: '#333333',
      callbacks: {
        labelPointStyle() {
          return {
            pointStyle: false,
            rotation: 0,
          };
        },
      },
    },
  },
};
