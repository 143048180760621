import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ztpGradiantAngle',
})
export class ZovuGradiantAnglePipe implements PipeTransform {
  transform(value: string): number {
    try {
      return parseInt(value.match(/\d+/).join('')) % 360;
    } catch (e){
      return 360;
    }
  }
}
