<div *ngIf="_patient" class="patient-info">
  <span class="title">Patient</span>
  <div class="content">
    <div class="patient-info-header">
      <span *ngIf="showPlaceHolder; else showAvatar" class="image placeholder"
        ><span class="content"></span
      ></span>
      <ng-template #showAvatar>
        <span
          class="avatar"
          [style.background]="
            'linear-gradient(' +
            (_patient?.zovuId | ztpGradiantAngle) +
            'deg, #0C586C -36.29%, #79C99A 77.8%)'
          "
        >
          <span
            >{{ _patient?.givenName | slice: 0:1
            }}{{ _patient?.surname | slice: 0:1 }}</span
          >
        </span>
      </ng-template>

      <div class="info" *ngIf="showPlaceHolder; else showInfo">
        <span class="placeholder inline"><span class="content"></span></span>
        <span class="placeholder inline"><span class="content"></span></span>
      </div>
      <ng-template #showInfo>
        <div class="info">
          <span class="name"
            >{{ _patient?.givenName | titlecase }}&nbsp;{{
              _patient?.surname | titlecase
            }}</span
          >
          <span class="zovu-id"
            >Zovu ID: {{ _patient?.zovuId | ztpZovuId }}</span
          >
        </div>
      </ng-template>
    </div>
    <span class="separator"></span>
    <ul class="patient-info-body">
      <li class="patient-info-body-item">
        <svg-icon key="calendar" color="#061E24" fontSize="25px"></svg-icon>
        <div>
          <span class="title">{{
            'result_list.patient_pannel.dob' | transloco
          }}</span>
          <span
            *ngIf="showPlaceHolder; else showDob"
            class="value dob placeholder"
            ><span class="content"></span
          ></span>
          <ng-template #showDob>
            <span
              class="value dob"
              [ztpTooltip]="
                _patient?.dateOfBirth.replace('-', '/')
                  | date
                    : 'longDate'
                    : 'UTC'
                    : (browserLanguage[0] === 'fr' ? 'fr' : 'en')
              "
              >{{
                _patient?.dateOfBirth.replace('-', '/')
                  | date
                    : (browserLanguage[0] === 'fr'
                        ? 'mediumDate'
                        : "MMM dd ''yy")
                    : 'UTC'
                    : (browserLanguage[0] === 'fr' ? 'fr' : 'en')
              }}</span
            >
          </ng-template>
        </div>
      </li>
      <li class="patient-info-body-item">
        <svg-icon key="person" fontSize="25px"></svg-icon>
        <div>
          <span class="title">{{
            'result_list.patient_pannel.gender' | transloco
          }}</span>
          <span
            *ngIf="showPlaceHolder; else showGender"
            class="value dob placeholder"
            ><span class="content"></span
          ></span>
          <ng-template #showGender
            ><span class="value">{{
              _patient?.gender | titlecase | transloco
            }}</span></ng-template
          >
        </div>
      </li>
    </ul>
  </div>
</div>
