/* istanbul ignore file */
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ztp-step-widget',
  templateUrl: './help-step.component.html',
  styleUrls: ['./help-step.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HelpStepComponent implements OnInit {
  // tslint:disable-next-line: no-input-rename
  @Input() icon: string;

  // tslint:disable-next-line: no-input-rename
  @Input() title: string;

  // tslint:disable-next-line: no-input-rename
  @Input() description: string;

  ngOnInit(): void {
  }
}
