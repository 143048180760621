import { RouterModule, Routes } from '@angular/router';
import { NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { MsalGuard } from '@azure/msal-angular';

import { ResultUnitListPageComponent } from './features/result-unit-list/result-unit-list-page/result-unit-list-page.component';
import { PatientSearchPageComponent } from './features/result-unit-upload/patient-search-page/patient-search-page.component';
import { SubmissionPageComponent } from './features/result-unit-upload/submission-page/submission-page.component';
import { FeatureFlagsPageComponent } from './feature-flags-page/feature-flags-page.component';
import { SignInPageComponent } from './sign-in-page/sign-in-page.component';

import { HelpComponent } from './help/help.component';
import { ContactComponent } from './contact/contact.component';
import { NotfoundComponent } from './notfound/notfound.component';
import { DashboardPageComponent } from './features/dashboard/dashboard-page/dashboard-page.component';
import { LabHeadGuard } from './features/dashboard/guards/lab-head.guard';

const routes: Routes = [
  {
    path: 'dashboard',
    component: DashboardPageComponent,
    canActivate: [MsalGuard, LabHeadGuard],
  },
  {
    path: 'home',
    component: ResultUnitListPageComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'upload',
    component: PatientSearchPageComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'submission',
    component: SubmissionPageComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'contact',
    component: ContactComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'help',
    component: HelpComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'signin',
    component: SignInPageComponent,
  },
  {
    path: 'logout',
    component: SignInPageComponent,
  },
  {
    path: 'notfound',
    component: NotfoundComponent,
  },
  {
    path: 'featureflags',
    component: FeatureFlagsPageComponent,
  },
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: '/notfound'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class AppRoutingModule { }
