<div class='signin'>
  <div [ngClass]="{ 'flex-center cover': true, 'loged-back-cover': !loggedIn }">
    <h1 class="title">{{'signin_page.welcome' | transloco }}</h1>
  </div>
  <div [ngClass]="{ 'flex-center content': true, 'loged-back-content': !loggedIn }">
    <div class="header">
      <div class="logo-container">
        <img src="/assets/images/logo/logo2.svg" alt="zovu logo" class="logo" />
        <ztp-env-badge></ztp-env-badge>
      </div>
      <span *ngIf="loggedIn" class="title">{{'signin_page.description' | transloco }} </span>
    </div>

    <form id="form" *ngIf="loggedIn" noValidate class="flex-center form">
      <button type="submit" [ngClass]="{'btn-submit': true, 'disable': loading}" (click)="login()">{{'signin_page.get_started' | transloco }}</button>
    </form>
  </div>
</div>