import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ztpZovuId',
})
export class ZovuIdFormatPipe implements PipeTransform {

  transform(value: string): string {
    if (!value) {
      return '';
    }
    const idParts = value.split('-');
    const id = idParts[1]?.match(/.{3}/g);
    return `${idParts[0]} ${id.join(' ')}`;
  }
}
