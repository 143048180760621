<div [ngClass]="{ 'feature': true, 'feature-deactivated': !feature?.activated, 'feature-activated': feature?.activated }">
    <div class="actions">
      <label class="container">
        <input type="checkbox" id="box-select" [checked]="selected" (click)="markAsSelected()">
        <span class="checkmark"></span>
      </label>
  
      <div class="switch button r">
        <input type="checkbox" class="checkbox" id="box-switch" [checked]="feature?.activated" (click)="performSwitchAction()">
        <div class="knobs"></div>
        <div class="layer"></div>
      </div>
    </div>
    <span class="name"><span class="delimiter"></span>{{feature?.name}}</span>
    <span class="desc">
    <span class="delimiter"></span> {{feature?.description | slice: 0:300}}
    </span>
    <span class="group"><span class="delimiter"></span>{{feature?.epic}}</span>
    <span class="date"><span class="delimiter"></span>{{feature?.date | zovuDateFormat}}</span>
  </div>
  