import { Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';

import { IToast } from './toast-tile/toast-tile.interface';

@Injectable({
  providedIn: 'root',
})
export class ToasterService {
  toastEvents: Observable<IToast>;
  private _toastEvents = new Subject<IToast>();

  constructor() {
    this.toastEvents = this._toastEvents.asObservable();
  }

  addToast(toastData: IToast): void {
    this._toastEvents.next(toastData);
  }
}

