import { CommonModule } from '@angular/common';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
  NO_ERRORS_SCHEMA,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { SvgIconsModule } from '@ngneat/svg-icon';

import { TranslocoRootModule } from 'src/app/transloco/transloco-root.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { AdvanceSearchModule } from '../advance-search/advance-search.module';
import { SharedModule } from 'src/app/shared/shared.module';

import { ResultUnitListPageComponent } from './result-unit-list-page/result-unit-list-page.component';

import {
  ResultUnitCardComponent,
  ResultUnitPreviewComponent,
  PatientCardComponent,
  ResultUnitCardPlaceholderComponent,
} from './components';

import { appCheckIcon } from 'src/app/svg/zovu/check';
import { appCalendarIcon } from 'src/app/svg/zovu/calendar';
import { appPersonIcon } from 'src/app/svg/zovu/person';
import { appPhoneCallIcon } from 'src/app/svg/zovu/phone-call';
import { appArobaseIcon } from 'src/app/svg/zovu/arobase';
import { appEditIcon } from 'src/app/svg/zovu/edit';
import { appPdfIcon } from 'src/app/svg/zovu/pdf';
import { appDotMenuIcon } from 'src/app/svg/zovu/dot-menu';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    NgSelectModule,
    ReactiveFormsModule,
    InfiniteScrollModule,
    SvgIconsModule.forChild([
      appCheckIcon,
      appCalendarIcon,
      appPersonIcon,
      appPhoneCallIcon,
      appArobaseIcon,
      appEditIcon,
      appPdfIcon,
      appDotMenuIcon,
    ]),
    TranslocoRootModule,
    SharedModule,
    AdvanceSearchModule,
  ],
  declarations: [
    ResultUnitListPageComponent,
    ResultUnitCardComponent,
    ResultUnitCardPlaceholderComponent,
    ResultUnitPreviewComponent,
    PatientCardComponent,
  ],
  providers: [],
  exports: [ResultUnitListPageComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class ResultUnitListModule {}
