export interface ModalContent {
  title: string;
  message: string;
  actions: ModalActions;
  type: ModalType;
  route?: string;
  fileName?: string;
}

export interface ModalActions {
  primaryAction: string;
  secondaryAction: string;
}

export enum ModalType {
  WARNING = 'warning',
  CONFIRMATION = 'confirmation',
  DELETION = 'deletion',
  RENAMING = 'renaming',
}

export enum VerificationModalActions {
  PASSED_VERIFICATION = 'patient-verified',
  FAILED_VERIFICATION = 'patient-not-verified',
  FAILED_SERVER_ERROR = 'server-error',
  FAILED_INVALID_ID = 'invalid-id',
  CLOSED_MODAL = 'modal-closed',
}
