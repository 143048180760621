import { Injectable, NgZone } from '@angular/core';
import { Observable } from 'rxjs';
import { SseService } from './sse.service';
import { environment } from 'src/environments/environment';
import { ISubmissionNotification } from '../components/notification-drawer/notification-tile/notification.interface';

@Injectable({
  providedIn: 'root',
})
export class AblyService {
  private url = `https://realtime.ably.io/event-stream?v=1.2&key=${environment.ablySubscriptionKey}&channels=`;
  private fifteenMinutes = 900000;
  private timeout;

  constructor(private readonly sseService: SseService, private readonly zone: NgZone) { }

  public subscribeToLiveCount(labCenterId: string): Observable<MessageEvent<any>> {
    return this.subscribeToEventSource(this.getChannelUrl(labCenterId, 'dashboard'));
  }

  public subscribeToSubmissionNotifications(labTechnicianId: string): Observable<MessageEvent<ISubmissionNotification> | string> {

    this.autoDisconnectNotificationEventSource(labTechnicianId);
    return this.subscribeToEventSource(this.getChannelUrl(labTechnicianId, 'notifications'));
  }

  public disconnectAllEventSources(): void {
    this.sseService.disconnectAllEventSources();
  }

  public disconnectSubmissionNotificationEventSource(labTechnicianId: string): void {
    this.sseService.disconnectEventSource(this.getChannelUrl(labTechnicianId, 'notifications'));
  }

  public disconnectLiveCountEventSource(labCenterId: string): void {
    this.sseService.disconnectEventSource(this.getChannelUrl(labCenterId, 'dashboard'));
  }

  private getChannelUrl(id: string, channel: string): string {
    return `${this.url}results:submission:${id}_${channel}`;
  }

  private autoDisconnectNotificationEventSource(labCenterId): void {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.timeout = setTimeout(() => {
      this.disconnectSubmissionNotificationEventSource(labCenterId);
    }, this.fifteenMinutes);
  }

  private subscribeToEventSource(url: string): Observable<MessageEvent<any>> {
    return new Observable((subscriber) => {
      let eventSource: EventSource;
      try {
        eventSource = this.sseService.getEventSource(url);
      } catch (error) {
        subscriber.error(error);
        return;
      }

      eventSource.onmessage = (event) => {
        this.zone.run(() => {
          subscriber.next(event);
        });
      };

      eventSource.onerror = (error) => {
        this.zone.run(() => {
          subscriber.error(error);
        });
        this.sseService.disconnectEventSource(url);
      };

      return () => {
        this.sseService.disconnectEventSource(url);
      };
    });
  }

}
