<div class="live-counter-tabs">
  <ng-container>
    <div class="titles">
      <span
        [ngClass]="selectedTab === idx && 'active'"
        *ngFor="let tab of getTabs() | keyvalue: keepOrder; let idx = index"
        (click)="chooseTab(idx)"
        transloco="dashboard.live-metrics.{{ tabs[idx] }}"
      ></span>
    </div>

    <div *ngIf="liveCounter">
      <div *ngIf="selectedTab === tabs.today" class="tabs">
        <div class="container">
          <div class="count">
            <ztp-live-count
              [ablyRealTimeCount]="ablyRealTimeCount"
              [currentLabCenter]="currentLabCenter"
              [liveCount]="liveCounter.today || 0"
            ></ztp-live-count>
          </div>
        </div>
      </div>

      <div *ngIf="selectedTab === tabs.this_week" class="tabs">
        <div class="container">
          <div (click)="toogleChart()" class="icon">
            <svg-icon
              [key]="showChart ? 'bar-chart-filled' : 'bar-chart-outlined'"
            ></svg-icon>
          </div>
          <div class="count">
            <ztp-live-count
              [ablyRealTimeCount]="ablyRealTimeCount"
              [currentLabCenter]="currentLabCenter"
              [liveCount]="liveCounter.this_week"
            ></ztp-live-count>
          </div>
          <div class="chart" *ngIf="showChart">
            <ztp-live-chart
              [data]="chartData.this_week"
              [showPeriodSelector]="false"
            ></ztp-live-chart>
          </div>
        </div>
      </div>

      <div *ngIf="selectedTab === tabs.this_month" class="tabs">
        <div class="container">
          <div (click)="toogleChart()" class="icon">
            <svg-icon
              [key]="showChart ? 'bar-chart-filled' : 'bar-chart-outlined'"
            ></svg-icon>
          </div>
          <div class="count">
            <ztp-live-count
              [ablyRealTimeCount]="ablyRealTimeCount"
              [currentLabCenter]="currentLabCenter"
              [liveCount]="liveCounter.this_month"
            ></ztp-live-count>
          </div>
          <div class="chart" *ngIf="showChart">
            <ztp-live-chart
              [data]="chartData.this_month"
              [showPeriodSelector]="true"
            ></ztp-live-chart>
          </div>
        </div>
      </div>
    </div>
    <div>
      <ztp-live-counter-card-placeholder
        *ngIf="showLoadPlaceHolder"
      ></ztp-live-counter-card-placeholder>
    </div>
  </ng-container>
</div>
