/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @angular-eslint/component-selector */
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { PDFDocument } from 'pdf-lib';
import { DOC_ORIENTATION, NgxImageCompressService } from 'ngx-image-compress';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { ModalService } from '../../../../shared/modal/modal.service';
import { ResultUnitListService } from 'src/app/features/result-unit-list/result-unit-list-page/result-unit-list.service';
import { TranslocoHttpLoader } from 'src/app/transloco/transloco.service';

import { ModalComponent } from '../../../../shared/modal/modal.component';
import { FilePreviewModalComponent } from '../file-preview-modal/file-preview-modal.component';
import {
  ModalContent,
  ModalType,
} from '../../../../shared/modal/modal.interface';
import { renameItem } from './upload-card.helper';

export interface IReport {
  fileItem: FileItem | string;
  fileName: string;
  fileExtension: string;
  fileContentType: string;
  pagesCount: number;
}
@Component({
  selector: 'ztw-upload-card',
  templateUrl: './upload-card.component.html',
  styleUrls: ['./upload-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadCardComponent implements OnInit, OnDestroy {
  @Input() uploadControl = new FormControl();

  @Output() sendFileQueue = new EventEmitter<FileUploader>();

  @Output() sendFile = new EventEmitter<IReport>();

  @Output() onBoxOpen = new EventEmitter<boolean>();

  @Output() sendFileQueueInvalid = new EventEmitter<boolean>();

  @Output() onFileCompressing = new EventEmitter<boolean>();

  @Input() uploadInput: FileItem[];

  @Input() canEditLabResult = true;

  private modalContent: ModalContent;

  report: IReport = {
    fileItem: '',
    fileName: '',
    fileExtension: '',
    fileContentType: '',
    pagesCount: 0,
  };

  labSubscription: Subscription;

  selectedFile = null;

  currentItem: FileItem;

  uploader: FileUploader;

  hasBaseDropZoneOver: boolean;

  fileQueueInvalid: boolean;

  response: string;

  captureButton = false;

  primaryAction: any;

  filesAlreadySavedNames: string[] = [];

  timer;

  touchCount = 0;

  editLabResult;

  translationData;

  maxFileSize = 10485760;

  validFileExtensions = [
    'image/png',
    'image/jpg',
    'image/jpeg',
    'application/pdf',
  ];

  invalidFilesQueue: string[] = [];

  modalSubscription: Subscription;

  coreSub: Subscription;

  constructor(
    private modalService: ModalService,
    private cd: ChangeDetectorRef,
    private labResultService: ResultUnitListService,
    private translocoService: TranslocoHttpLoader,
    private imageCompress: NgxImageCompressService
  ) {}

  ngOnInit() {
    this.touchCount = 0;
    this.initializeUploader();
    this.loadTranslation();
    // this.loadReports();
    this.modalSubscription = this.modalService.modalActions
      .pipe(filter((data) => data !== null))
      .subscribe((action) => {
        this.initActionCallback(action);
      });
  }

  public loadTranslation(): void {
    this.modalSubscription = this.translocoService
      .getTranslation()
      .subscribe((data) => {
        this.translationData = data;
        this.modalContent = {
          message: data['modal_submission']['message'],
          title: 'Confirmation',
          route: '',
          actions: {
            primaryAction: data['modal_submission']['primary_action'],
            secondaryAction: data['modal_submission']['secondary_action'],
          },
          type: ModalType.CONFIRMATION,
        };
      });
  }

  public initializeUploader(): void {
    this.uploader = new FileUploader({
      disableMultipart: true,
      formatDataFunctionIsAsync: true,
    });

    this.uploader.onAfterAddingFile = (item) => {
      // this.modalContent.type = ModalType.CONFIRMATION;
      this.currentItem = item;
      if (this.filesAlreadySavedNames.includes(item?.file.name)) {
        this.openModal(item?.file.name, 'renaming');
        this.modalContent.type = ModalType.RENAMING;
      }
      this.filesAlreadySavedNames.push(item.file.name);
    };

    this.uploader.onAfterAddingAll = (_items) => {
      this.checkFilesValidity();
    };

    this.hasBaseDropZoneOver = true;
    this.sendFileQueue.emit(this.uploader);
    this.refreshAfterUpdate();
  }

  public async checkFilesValidity() {
    this.invalidFilesQueue = [];
    for (let i = 0; i < this.uploader?.queue?.length; ++i) {
      if (
        this.validFileExtensions.indexOf(this.uploader?.queue[i]?.file?.type) ==
        -1
      ) {
        this.invalidFilesQueue.push(this.uploader?.queue[i]?.file?.name);
      } else {
        if (this.uploader?.queue[i]?.file?.size > this.maxFileSize) {
          this.invalidFilesQueue.push(this.uploader?.queue[i]?.file?.name);
        }
      }
    }
    if (this.invalidFilesQueue.length == 0) {
      this.onFileCompressing.emit(true);
      this.report.fileItem = await this.consolidateFilesToReport();
      this.report.fileExtension = 'pdf';
      this.report.fileContentType = 'application/pdf';
      this.sendFile.emit(this.report);
      this.onFileCompressing.emit(false);
    }
    this.fileQueueInvalid = this.invalidFilesQueue.length != 0;
    this.sendFileQueueInvalid.emit(this.fileQueueInvalid);
  }

  public async consolidateFilesToReport(): Promise<string> {
    const allDocs = await this.convertImagesToPdf(this.uploader?.queue);
    return this.mergeAllPDFDocuments(allDocs);
  }

  public async convertImagesToPdf(fileItems: FileItem[]): Promise<any[]> {
    return Promise.all(
      fileItems.map(async (fileItem) => {
        if (fileItem.file.type === 'application/pdf') {
          const pdfBytes: string | ArrayBuffer = await this.readFileItem(
            fileItem
          );
          return pdfBytes;
        } else {
          const pdf = await PDFDocument.create();
          let isPng = false;
          const imageBytes: string | ArrayBuffer = await this.readFileItem(
            fileItem
          );
          const compressedImageBytes = await this.compressImage(
            imageBytes,
            50,
            50
          );

          let image: any;
          if (fileItem.file.type === 'image/png') {
            image = await pdf.embedPng(compressedImageBytes);
            isPng = true;
          } else {
            image = await pdf.embedJpg(compressedImageBytes);
          }

          let imageDims: any;
          const imageSizes = await this.getImageDimensions(
            compressedImageBytes
          );

          if (imageSizes.width <= 512) {
            if (isPng) {
              image = await pdf.embedPng(imageBytes);
            } else {
              image = await pdf.embedJpg(imageBytes);
            }
            imageDims = image.scale(0.5);
          } else if (imageSizes.width > 512 && imageSizes.width <= 1024) {
            imageDims = image.scale(0.5);
          } else if (imageSizes.width > 1024 && imageSizes.width < 1440) {
            imageDims = image.scale(0.4);
          } else if (imageSizes.width >= 1440) {
            imageDims = image.scale(0.3);
          } else {
            imageDims = image.scale(0.45);
          }

          const page = pdf.addPage();
          page.drawImage(image, {
            x: page.getWidth() / 2 - imageDims.width / 2,
            y: page.getHeight() / 2 - imageDims.height / 2,
            width: imageDims.width,
            height: imageDims.height,
          });
          return pdf.saveAsBase64();
        }
      })
    );
  }

  public async compressImage(
    imageBytes: string | ArrayBuffer,
    scale: number,
    quality: number
  ): Promise<string> {
    return this.imageCompress.compressFile(
      imageBytes as string,
      DOC_ORIENTATION.Default,
      scale,
      quality
    );
  }

  public async mergeAllPDFDocuments(docs: string[]): Promise<string> {
    if (docs.length > 0) {
      const mergedPdf = await PDFDocument.create();

      for (const doc of docs) {
        const pdf = await PDFDocument.load(doc);
        const copiedPages = await mergedPdf.copyPages(
          pdf,
          pdf.getPageIndices()
        );
        copiedPages.forEach((page) => {
          mergedPdf.addPage(page);
        });
      }

      this.report.pagesCount = mergedPdf.getPages().length;
      return mergedPdf.saveAsBase64();
    }
    return null;
  }

  public readFileItem(fileItem: FileItem): Promise<string | ArrayBuffer> {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(fileItem?._file);
    });
  }

  public getImageDimensions(
    imageBytes: string
  ): Promise<{ width: number; height: number }> {
    return new Promise((resolve) => {
      const image = new Image();
      image.src = imageBytes;
      image.onload = () =>
        resolve({ width: image.width, height: image.height });
    });
  }

  public checkBeforeRemoveItem(item: FileItem): void {
    this.currentItem = item;
    this.modalContent.type = ModalType.DELETION;
    this.openModal(item.file.name, 'deletion');
  }

  public removeItem(item: FileItem): void {
    this.currentItem = item;
    this.filesAlreadySavedNames = this.filesAlreadySavedNames.filter(
      (name) => name !== this.currentItem.file.name
    );
    this.currentItem.remove();
    this.checkFilesValidity();
    this.cd.markForCheck();
    this.uploadControl.markAsDirty();
  }

  private openModal(fileName: string, type: string): void {
    let message = '';
    const actions = { primaryAction: '', secondaryAction: '' };
    const file = fileName;
    switch (type) {
    case 'warning':
      message = this.translationData['modal_warning']['message'];
      actions.primaryAction =
          this.translationData['modal_warning']['primary_action'];
      actions.secondaryAction =
          this.translationData['modal_warning']['secondary_action'];
      break;
    case 'renaming':
      message = this.translationData['modal_renaming']['message'];
      actions.primaryAction =
          this.translationData['modal_renaming']['primary_action'];
      actions.secondaryAction =
          this.translationData['modal_renaming']['secondary_action'];
      break;
    case 'confirmation':
      message = this.translationData['modal_submission']['message'];
      actions.primaryAction =
          this.translationData['modal_submission']['primary_action'];
      actions.secondaryAction =
          this.translationData['modal_submission']['secondary_action'];
      break;
    case 'deletion':
      message = this.translationData['modal_deletion']['message'];
      actions.primaryAction =
          this.translationData['modal_deletion']['primary_action'];
      actions.secondaryAction =
          this.translationData['modal_deletion']['secondary_action'];
      break;
    default:
      break;
    }
    this.modalService.init(ModalComponent, {
      modalContent: {
        ...this.modalContent,
        message,
        actions,
        type,
        fileName: file,
      },
    });
  }

  public touchDragZone(): void {
    this.touchCount =
      (this.touchCount === 0 && this.editLabResult === true) ||
      this.touchCount === 0
        ? this.touchCount + 1
        : this.touchCount;
  }

  public fileOverBase(e: boolean): void {
    this.hasBaseDropZoneOver = e;
  }

  public setFileSelectBoxOpen(_e: any): void {
    this.onBoxOpen?.emit(true);
  }
  public setFileSelectBoxClose(_e: any): void {
    // TODO implement on file select box canceled
  }

  public setFileDragOver(_e: any): void {
    this.onBoxOpen?.emit(true);
  }

  public setFileDragLeave(_e: any): void {
    this.onBoxOpen?.emit(false);
  }

  public onTakePicture(event): void {
    this.selectedFile = new FileItem(this.uploader, event.target.files[0], {
      disableMultipart: true,
      formatDataFunctionIsAsync: true,
    });
    this.uploader.queue.push(this.selectedFile);
    this.modalContent.type = ModalType.CONFIRMATION;
    this.currentItem = this.selectedFile;
    if (
      this.filesAlreadySavedNames.includes(this.selectedFile?.file.name) &&
      this.uploader.queue.length > 1
    ) {
      this.openModal(this.selectedFile?.file.name, 'renaming');
      this.modalContent.type = ModalType.RENAMING;
    }
    this.filesAlreadySavedNames.push(this.selectedFile?.file.name);
    this.checkFilesValidity();
    this.sendFileQueue.emit(this.uploader);
    this.refreshAfterUpdate();
  }

  private initActionCallback(action: string): void {
    // TODO: Ideally,the action should be in the constants file. Replace TODO with object lookup.
    // the object will take longer in the implementation than the switch because we will not be able to combine the actions
    switch (action) {
    case 'Continue':
    case 'Continuer':
    case 'Cancel':
    case 'Annuler':
    default:
      break;

    case 'Delete':
    case ' Annuler':
    case ' Cancel':
    case 'Supprimer':
      // this.deletingCallback();
      this.filesAlreadySavedNames = this.filesAlreadySavedNames.filter(
        (name) => name !== this.currentItem.file.name
      );
      this.currentItem.remove();
      this.checkFilesValidity();
      this.cd.markForCheck();
      this.uploadControl.markAsDirty();
      break;

    case 'Abort':
    case 'Abandonner':
      // this.refusingCallback();
      this.uploader.queue.pop();
      this.checkFilesValidity();
      this.cd.markForCheck();
      break;

    case 'Rename':
    case 'Renommer':
      // this.continueCallback();
      this.uploader.queue[this.uploader.queue.length - 1] = renameItem(
        this.currentItem,
        this.uploader
      );
      this.cd.markForCheck();
      break;

    case ' Delete':
    case ' Effacer':
      // this.deleteAllInQueue();
      this.uploader.queue.splice(0, this.uploader.queue.length - 1);
      this.touchCount += 1;
      this.cd.markForCheck();
      break;
    }
  }

  private refreshAfterUpdate() {
    this.timer = setInterval(() => {
      this.cd.markForCheck();
    });
  }

  openPreview(item: FileItem) {
    const input = {
      fileItem: item,
    };
    this.modalService.init(FilePreviewModalComponent, input);
  }

  filesCounterMessage(n: number, type: 'uploaded' | 'uploading' | 'invalid') {
    if (String(n).length == 1) {
      return `0${n} file${n > 1 ? 's' : ''} ${type}`;
    } else {
      return `${n} files ${type}`;
    }
  }

  ngOnDestroy() {
    this.touchCount = 0;
    this.modalSubscription?.unsubscribe();
    this.labSubscription?.unsubscribe();
    this.coreSub?.unsubscribe();
    clearInterval(this.timer);
  }
}
