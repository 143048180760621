import { Component, Input, OnInit } from '@angular/core';
import { ChartOptions, ChartData } from 'chart.js';
import { chartConfig } from '../../configs/chart.config';

@Component({
  selector: 'ztp-live-chart',
  templateUrl: './live-chart.component.html',
  styleUrls: ['./live-chart.component.scss'],
})
export class LiveChartComponent implements OnInit {
  @Input() showPeriodSelector = false;
  @Input() data: {
    startDate: string;
    endDate: string;
    labels: string[];
    values: number[];
  }[];

  overrides: ChartOptions = chartConfig;

  barChartData: ChartData<'bar'>;

  ngOnInit(): void {
    this.constructChart(0);
  }

  constructChart(weekIndex: number = 0): void {
    this.barChartData = {
      labels: this.data[weekIndex].labels.map((label) => label.substring(0, 3)),
      datasets: [
        {
          data: this.data[weekIndex].values,
          label: '',
          backgroundColor: '#fff',
          hoverBackgroundColor: '#62B592',
          borderRadius: 5,
          borderSkipped: false,
          barThickness: 20,
        },
      ],
    };
  }
}
