<div class="dashboard-title">
  <div>
    <img [src]="currentLabCenter.logo" alt="Lab logo" />
    <span>{{ currentLabCenter.name }}</span>
  </div>
  <div>
    <span>{{ 'dashboard.welcome' | transloco }}</span>
    <span
      >{{ labTechnician?.givenName }}&nbsp;{{ labTechnician?.surname }}</span
    >
  </div>
</div>

<div class="dashboard-content">
  <div class="live-counter">
    <ztp-live-counter-tabs
      [currentLabCenter]="currentLabCenter"
    ></ztp-live-counter-tabs>
  </div>
  <div class="report-archives">
    <ztp-report-archives
      [currentLabCenter]="currentLabCenter"
    ></ztp-report-archives>
  </div>
  <div class="operation-summary">
    <ztp-operation-summary [currentLabCenter]="currentLabCenter"></ztp-operation-summary>
  </div>
</div>
