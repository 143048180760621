<form novalidate [formGroup]="filterForm" class="filter-container">
  <span class="name-input">
    <input type="text" [placeholder]="'advance_search.inputs.patient_name' | transloco" formControlName="patientNameInput" />
  </span>

  <div class="zovuid-ruid-container">
    <div class="zovuid-input">
      <div [ngClass]="{ 'id-field': true, 'id-field-error': false }">
        <div class="iso-code-container">
          <ng-select #ZovuIdSelecter class="iso-code-select" [items]="countriesCodeIso2" bindLabel="iso2" bindValue="iso2"
            formControlName="zovuIdIso2code" [ngModelOptions]="{ standalone: true }" [searchable]="false"
            [virtualScroll]="false">
          </ng-select>
          <svg-icon fontSize="12px" icon="chevron-down" key="chevron-down" (click)="openZovuIdSelectBox()"
            class="arrow-icon" alt="arrow chevron icon">
          </svg-icon>
        </div>
        <input type="text" name="zovuid" [placeholder]="'advance_search.inputs.zovu_id' | transloco" formControlName="zovuInput"
          (input)="onZovuIdInput($event)" #zovuinput autocomplete="off" [maxlength]="maxZovuIdInputLength" />
      </div>
    </div>
  
  
    <span class="ruid-input">
      <input type="text" [placeholder]="'advance_search.inputs.result_unit_id' | transloco" formControlName="resultUnitIdInput"
        (input)="onResultUnitIdInput($event)" [maxlength]="maxResultUnitIdInputLength" />
    </span>
  </div>

  <div class="department-testname-container">
    <span class="department-input">
      <ng-select #DepartmentsSelecter class="department-select" [searchFn]="customSearch" [items]="departmentsData"
        bindLabel="name" bindValue="name" formControlName="departmentInput" [searchable]="true" [virtualScroll]="false"
        [placeholder]="'advance_search.inputs.department' | transloco">
      </ng-select>
      <svg-icon fontSize="12px" icon="chevron-down" key="chevron-down" (click)="openDepartmentSelectBox()"
        class="arrow-icon" alt="arrow chevron icon">
      </svg-icon>
    </span>
  
  
    <span class="testname-input">
      <ng-select #TestNamesSelecter class="testname-select" [searchFn]="customSearch" [items]="testNamesData"
        bindLabel="name" bindValue="name" formControlName="studyNameInput" [searchable]="true" [virtualScroll]="false"
        [placeholder]="'advance_search.inputs.study_name' | transloco">
      </ng-select>
      <svg-icon fontSize="12px" icon="chevron-down" key="chevron-down" (click)="openTestNameSelectBox()"
        class="arrow-icon" alt="arrow chevron icon">
      </svg-icon>
    </span>
  </div>
  

  <span class="date-input" (ztpClickOutside)="onClickOustide()">
    <span *ngIf="!filterForm?.value.startDateInput; else showDateValue" class="placeholder">{{'advance_search.inputs.date_of_result' | transloco}}</span>

    <ng-template #showDateValue>
      <div *ngIf="filterForm?.value.startDateInput == filterForm?.value.endDateInput; else showRangeValue" class="date-value">
        <span class="date">{{filterForm?.value.startDateInput}}</span>
      </div>
      <ng-template #showRangeValue>
        <div class="date-value">
          <span>From</span>
          <span class="date">{{filterForm?.value.startDateInput}}</span>
          <span>to</span>
          <span class="date">{{filterForm?.value.endDateInput}}</span>
        </div>
      </ng-template>
    </ng-template>
    
    <ztp-datepicker *ngIf="showDatePicker" class="datepicker-container" (onDateChanges)="onFilterDatesChange($event)"></ztp-datepicker>
    <svg-icon (click)="toggleDatePicker()" key="calendar" color="#0C586C"></svg-icon>
  </span>
</form>