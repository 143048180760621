import {
  ChangeDetectionStrategy,
  Component,
  Input,
} from '@angular/core';

import { ResultUnit } from './result-unit.interface';

@Component({
  selector: 'ztp-result-unit-card',
  templateUrl: './result-unit-card.component.html',
  styleUrls: ['./result-unit-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResultUnitCardComponent {
  @Input() labResult: ResultUnit;

  @Input() active: boolean;
}
