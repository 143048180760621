/* istanbul ignore file */
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { filter, Subscription, timer } from 'rxjs';

import { ModalService } from 'src/app/shared/modal/modal.service';
import { TranslocoHttpLoader } from 'src/app/transloco/transloco.service';
import { ResultUnitListService } from 'src/app/features/result-unit-list/result-unit-list-page/result-unit-list.service';
import { ResultUnitPreviewService } from './result-unit-preview.service';
import { AdvanceSearchService } from 'src/app/features/advance-search/advance-search/advance-search.service';

import { ModalComponent } from 'src/app/shared/modal/modal.component';
import { FilePreviewModalComponent } from 'src/app/features/result-unit-upload/components/file-preview-modal/file-preview-modal.component';

import { ModalContent, ModalType } from 'src/app/shared/modal/modal.interface';
import { EApprovalStatus, ResultUnit } from '../result-unit-card/result-unit.interface';
import { ITechnician } from 'src/app/core/models';

@Component({
  selector: 'ztp-result-unit-preview',
  templateUrl: './result-unit-preview.component.html',
  styleUrls: ['./result-unit-preview.component.scss'],
})
export class ResultUnitPreviewComponent implements OnInit, OnDestroy {
  @Input()
  set labResult(labResult: ResultUnit) {
    this._labResult = labResult;
    this.labResultApproved = labResult?.approval?.status === 'approved';
    this.labResultProtected = labResult?.submission?.category?.protected;
    this.showPlaceHolder = true;
    this.startTimer();
  }

  @Input() labResultIndex: number;

  @Input() labTechnician: ITechnician;

  @Output() onApprovalRequest = new EventEmitter<boolean>();

  @Output() onLabResultChange = new EventEmitter<ResultUnit>();

  private placeHolderTimer = timer(300);

  private timerSub: Subscription;

  showPlaceHolder: boolean;

  _labResult: ResultUnit;

  labResultApproved: boolean;

  labResultProtected: boolean;

  showActionMenu: boolean;

  editModalContent: ModalContent;

  unApproveModalContent: ModalContent;

  private modalSubscription: Subscription;

  private labResultPreviewSub: Subscription;

  private labResultsSub: Subscription;

  constructor(
    private labResultService: ResultUnitListService,
    private router: Router,
    private modalService: ModalService,
    private translocoService: TranslocoHttpLoader,
    private labResultPreviewService: ResultUnitPreviewService,
    private labResultSearchService: AdvanceSearchService
  ) { }

  ngOnInit(): void {
    this.loadTranslation();
    this.modalSubscription = this.modalService.modalActions
      .pipe(filter((data) => data !== null))
      .subscribe((action: string) => {
        this.verifyAction(action);
      });
  }

  public saveApproval(status: EApprovalStatus = EApprovalStatus.APPROVED): void {
    this.onApprovalRequest.emit(true);
    this.labResultPreviewSub = this.labResultPreviewService.putLabResultApproval(
      this.labTechnician,
      this._labResult?.id,
      this.labResultIndex,
      status
    ).subscribe({
      next: (response) => {
        this.labResultsSub = this.labResultSearchService.saveLabResultApproval(response.resultUnit, response.resultUnitIndex).subscribe((lab) => {
          if (this._labResult.id === lab.id) {
            this.onLabResultChange.emit(lab);
          }
          this.onApprovalRequest.emit(false);
        });
      },
      error: () => {
        this.onApprovalRequest.emit(false);
      },
    });
  }

  public showEditConfirmationModal(): void {
    const modalInputs: { modalContent: ModalContent } = {
      modalContent: this.editModalContent,
    };
    this.modalService.init(ModalComponent, modalInputs);
  }

  public showUnapproveConfirmationModal(): void {
    const modalInputs: { modalContent: ModalContent } = {
      modalContent: this.unApproveModalContent,
    };
    this.modalService.init(ModalComponent, modalInputs);
  }

  public openPreview(item: ResultUnit): void {
    this.modalService.init(FilePreviewModalComponent, {
      labResult: item,
      technicianId: this.labTechnician?.id,
      isLoading: true,
    });
  }

  private navigateToEditPage(): void {
    this.labResultService.saveCurrentLabResult(this._labResult);
    this.labResultService.editCurrentLabResult();
    this.router.navigateByUrl('/submission').then(() => {
      this.labResultsSub = this.labResultSearchService.refreshLabResults(this.labTechnician, {}).subscribe();
    });
  }

  private loadTranslation(): void {
    this.translocoService.getTranslation().subscribe((data) => {
      this.editModalContent = {
        message: data['modal_result_unit_edit']['message'],
        title: data['modal_result_unit_edit']['title'],
        route: '',
        actions: {
          primaryAction: data['modal_result_unit_edit']['primary_action'],
          secondaryAction: data['modal_result_unit_edit']['secondary_action'],
        },
        type: ModalType.CONFIRMATION,
      };

      this.unApproveModalContent = {
        message: data['modal_result_unit_unapprove']['message'],
        title: data['modal_result_unit_unapprove']['title'],
        route: '',
        actions: {
          primaryAction: data['modal_result_unit_unapprove']['primary_action'],
          secondaryAction: data['modal_result_unit_unapprove']['secondary_action'],
        },
        type: ModalType.CONFIRMATION,
      };
    });
  }

  private verifyAction(action: string): void {
    if (action === 'Yes' || action === 'Oui') {
      this.navigateToEditPage();
    }

    if (action === 'Remove' || action === 'Retirer') {
      this.saveApproval(EApprovalStatus.UNAPPROVED);
    }
  }

  public toggleActionMenu(): void {
    this.showActionMenu = !this.showActionMenu;
  }

  public onClickOustide(): void {
    this.showActionMenu = false;
  }

  public startTimer(): void {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    this.timerSub = this.placeHolderTimer.subscribe((_output) => {
      this.showPlaceHolder = false;
      this.timerSub?.unsubscribe();
    });
  }

  ngOnDestroy() {
    this.modalSubscription?.unsubscribe();
    this.labResultPreviewSub?.unsubscribe();
    this.labResultsSub?.unsubscribe();
  }
}

//TODO: Add a file preview modal component
//TODO: update modal action payloads dispatch logic
