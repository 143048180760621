<div class="contact-container">
    <div class="contact-body">
        
        <div class="contact-img">
            <picture>
                <img src="/assets/images/contact.svg" style="height:215px;width:204px;">
            </picture>
        </div>
        <div class="contact-title">Contact below</div>
        <div class="contact-desc">Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise
            en page avant impression. Le Lorem Ipsum est le faux texte standard de l'imprimerie depuis les années 1500,
            quand un imprimeur anonyme assembla ensemble des morceaux de texte pour réaliser un livre spécimen de
            polices de
            texte.</div>
        <div class="contact-adress">
            <div class="widget phone">
                <div class="icon">
                    <svg-icon key="phone-call" color='red'></svg-icon>
                </div>
                <div class="content">
                    <p class="title">Mobile</p>
                    <p class="content">9292939389</p>
                </div>
            </div>
            <div class="separator"></div>
            <div class="widget">
                <div class="icon">
                    <svg-icon key="mail"></svg-icon>
                </div>
                <div class="content">
                    <p class="title">Email</p>
                    <p class="content">help@zovu.com</p>
                </div>
            </div>
        </div>
    </div>
</div>