<div class="zv-notification-unit" *ngIf="submission?.submissionStatus !== 're_upload'; else showReuploadTile">
    <div class="zv-notification-unit-action--container">
        <div class="zv-notification-unit-action"
            *ngIf="submission?.submissionStatus === 'failed'">
            <label class="actionMenuCheckbox">
                <input type="checkbox" />
                <div class="notif-menu-icon">
                    <svg-icon key="notif-item-menu" fontSize="18px"></svg-icon>
                </div>
                <div class="zv-notification-unit-action--menu">
                    <div class="zv-notification-unit-action--menu--unit" (click)="retrySubmission()">
                        <div>
                            <svg-icon key="retry"></svg-icon>
                        </div>
                        <div>
                            {{ 'notification_drawer.unit_content.actions_menu.retry' | transloco }}
                        </div>
                    </div>
                    <div class="zv-notification-unit-action--menu--unit" (click)="closeSubmission()">
                        <div>
                            <svg-icon key="delete-failed-notif"></svg-icon>
                        </div>
                        <span>
                            {{ 'notification_drawer.unit_content.actions_menu.delete' | transloco }}
                        </span>
                    </div>
                </div>
            </label>
        </div>
    </div>
    <div class="zv-notification-unit-content">
        <div class="zv-notification-unit-avatar--container">
            <span class="avatar">
                {{ submission?.resultUnit?.patient?.givenName | slice: 0:1}}{{
                submission?.resultUnit?.patient?.surname | slice: 0:1 }}
            </span>
        </div>
        <div class="zv-notification-unit-info--container">
            <div class="zv-notification-unit-info--header">
                <div class="zv-notification-unit--message" *transloco="let t">
                    <span
                        [innerHTML]="t(getTileMessage(),{lastName: submission?.resultUnit?.patient?.surname}) | safe:'html'">{{submission?.resultUnit?.patient?.surname}}</span>
                </div>
                <div class="zv-notification-unit-status">
                    <span class="patient--testname">{{submission?.resultUnit?.submission?.category?.studyName}}</span>
                    <svg-icon key="success-notif" fontSize="18px" *ngIf="submission?.submissionStatus === 'success' ">
                    </svg-icon>
                    <svg-icon key="failed-notif" fontSize="18px" *ngIf="submission?.submissionStatus === 'failed' ">
                    </svg-icon>
                </div>
            </div>
            <div class="zv-notification-unit-info--loader" [ngClass]="submission?.submissionStatus">
                <div [ngClass]="{'progress-bar': true}">
                    <div class="inner-bar">&nbsp;</div>
                </div>
            </div>
            <span class="zv-notification-unit-info--footer" *transloco="let t">
                <span class="patient--zovu-id">Zovu ID: {{submission?.resultUnit?.patient.zovuId}}</span>
                <span class="patient-file-container" *ngIf="submission?.resultUnit?.submission?.media?.pagesCount === 1"
                    [innerHTML]="t('notification_drawer.unit_content.page_single',{pageCount: submission?.resultUnit?.submission?.media?.pagesCount}) | safe:'html'">
                </span>
                <span class="patient-file-container" *ngIf="submission?.resultUnit?.submission?.media?.pagesCount > 1"
                    [innerHTML]="t('notification_drawer.unit_content.page_multiple',{pageCount: submission?.resultUnit?.submission?.media?.pagesCount}) | safe:'html'">
                </span>
                <span class="patient-file-type">
                    {{submission?.resultUnit?.submission?.media?.fileExtension | titlecase}}
                </span>
                <span class="time">{{ submission?.submissionDateTime | timeago:true }}</span>
            </span>
        </div>
    </div>
</div>
<ng-template #showReuploadTile>
    <ng-container>
        <div class="zv-notification-unit rety-mode">
            <div class="zv-notification-unit-content">
                <div class="zv-notification-unit-avatar--container">&nbsp;</div>
                <div class="zv-notification-unit-reupload--content">
                    <span class="reupload-message">
                        {{ 'notification_drawer.reupload_unit_content.message' | transloco }}
                    </span>
                    <div class="actions">
                        <button class="outline" (click)="cancelSubmission()">
                            {{ 'notification_drawer.reupload_unit_content.actions.cancel' | transloco }}
                        </button>
                        <button (click)="reUploadSubmission()">
                            {{ 'notification_drawer.reupload_unit_content.actions.reupload' | transloco }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</ng-template>

<!-- TODO: Update translation fetch -->