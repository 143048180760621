import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { TSubmissionStatistics, EMonthType } from '../../models';

@Component({
  selector: 'ztp-submission-statistics',
  templateUrl: './submission-statistics.component.html',
  styleUrls: ['./submission-statistics.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubmissionStatisticsComponent implements OnInit {
  @Input() submissionStatistics: TSubmissionStatistics;

  @Input() period: {
    year: number;
    month: EMonthType;
  };

  totalSubmissions: number;

  stats: any[];

  departmentStats: any = [];

  studiesStats: any[] = [];

  technicianStats: any[] = [];

  options: string[] = [];

  selectedOption = 0;

  constructor(private readonly translocoService: TranslocoService) {}

  ngOnInit(): void {
    this.loadStatistics();
    this.loadTranslations();
  }

  loadStatistics(): void {
    const statistics =
      this.submissionStatistics[this.period.year][this.period.month]
        .submissionStatistics.statistics;
    this.totalSubmissions =
      this.submissionStatistics[this.period.year][
        this.period.month
      ].submissionStatistics.totalSubmissions;
    this.departmentStats = statistics['departments'];
    this.studiesStats = statistics['studies'];
    this.technicianStats = statistics['technicians'];
    this.stats = this.departmentStats;
  }

  loadTranslations(): void {
    this.translocoService
      .selectTranslate(
        'dashboard.operation-summary.submission-statistics.options.per-department'
      )
      .subscribe((value) => this.options.push(value));
    this.translocoService
      .selectTranslate(
        'dashboard.operation-summary.submission-statistics.options.per-study'
      )
      .subscribe((value) => this.options.push(value));
    this.translocoService
      .selectTranslate(
        'dashboard.operation-summary.submission-statistics.options.per-technician'
      )
      .subscribe((value) => this.options.push(value));
  }

  selectOption(option: number): void {
    this.selectedOption = option;
    if (option === 2) {
      this.stats = this.technicianStats;
    }
    if (option === 1) {
      this.stats = this.studiesStats;
    }
    if (option === 0) {
      this.stats = this.departmentStats;
    }
  }
}
