/* eslint-disable @angular-eslint/component-selector */
/* istanbul ignore file */
import { Component, OnInit } from '@angular/core';
import { FeatureFlagsService } from './feature-flags.service';

import {
  FlagKey,
  FlagSelectAction,
  IFeatureFlag,
} from '../shared/feature-flag/feature-flag.interface';

@Component({
  selector: 'ztw-feature-flags-page',
  templateUrl: './feature-flags-page.component.html',
  styleUrls: ['./feature-flags-page.component.scss'],
})
export class FeatureFlagsPageComponent implements OnInit {
  features: Record<FlagKey, IFeatureFlag> = {};

  selectedFeatures: IFeatureFlag[] = [];

  selectAll: boolean;

  checkAll: boolean;

  flagsItemCount = 0;

  constructor(private featureFlagService: FeatureFlagsService) {}

  ngOnInit(): void {
    // this.featureFlagService.getFeatureFlags().subscribe((data) => {
    //   this.features = data;
    //   this.flagsItemCount = Object.keys(data).length;
    // });
  }

  markAllAsSelected(): void {
    this.selectAll = !this.selectAll;
    this.selectedFeatures = this.selectAll ? Object.values(this.features) : [];
  }

  performSelectedAction(action: {
    feature: IFeatureFlag;
    payload: FlagSelectAction;
  }): void {
    if (action.payload === 'ADD') {
      this.selectedFeatures.push(action.feature);
    } else {
      this.selectedFeatures = this.selectedFeatures.filter((feature) => feature.id !== action.feature.id);
    }
  }

  performSwitchAction(): void {
    this.checkAll = !this.checkAll;
    if (this.selectedFeatures.length > 0) {
      this.selectedFeatures.map((fflag) => {
        fflag.activated = this.checkAll;
        this.featureFlagService
          .updateFeatureFlag(fflag);
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        // .subscribe((data) => {});
      });
    }
  }
}
