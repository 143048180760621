<div id="modal" #modal class="modal modal-visible" role="dialog" aria-hidden="true" *ngIf="modalContent">
  <div class="modal-dialog disable-selection" (ztpClickOutside)="onClickOustide()">
    <div class="title">
      {{ modalContent?.title }}
    </div>
    <div class="message" [ngSwitch]="modalContent?.type">
      <ng-template *ngSwitchCase="'renaming'" transloco let-t>
        {{ t('modal_renaming.message', { fileName: modalContent?.fileName}) }}
      </ng-template>
      <ng-template *ngSwitchCase="'deletion'" transloco let-t>
        {{ t('modal_deletion.message', { fileName: modalContent?.fileName}) }}
      </ng-template>
      <p *ngSwitchCase="'confirmation'">
        {{modalContent?.message }}
      </p>
      <p *ngSwitchCase="'warning'">
        {{modalContent?.message }}
      </p>
    </div>
    <div class="actions">
      
      <ng-container *ngIf="modalContent?.type !== 'warning'">
        <button class="btn btn-secondary outline" (click)="onSecondaryClick()">
          {{ modalContent?.actions?.secondaryAction}}
        </button>
        <button class="btn btn-primary" (click)="onPrimaryClick()">
          {{ modalContent?.actions?.primaryAction}}
        </button>
      </ng-container>
      <ng-container *ngIf="modalContent?.type === 'warning'">
        <button class="btn btn-primary warning" (click)="onPrimaryClick()">
          {{ modalContent?.actions?.primaryAction}}
        </button>
        <button class="btn btn-primary" (click)="onSecondaryClick()">
          {{ modalContent?.actions?.secondaryAction}}
        </button>
      </ng-container>
    </div>
  </div>
</div>