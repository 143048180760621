/* istanbul ignore file */
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ObservableStore } from '@codewithdan/observable-store';

import {
  AppCoreActions,
  IStoreState,
} from '../../../core/models/store.interface';
import { ResultUnit } from '../../result-unit-list/components/result-unit-card/result-unit.interface';
import { ISearchParams } from 'src/app/core/models/search-params.interface';
import { ITechnician } from 'src/app/core/models';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AdvanceSearchService extends ObservableStore<IStoreState> {
  private url = `${environment.apimBaseUrl}/technician/result-units`;
  private headers = new HttpHeaders({
    'Ocp-Apim-Subscription-Key': environment.apimSubscriptionKey,
    'Ocp-Apim-Trace': 'true',
    'Content-Type': 'application/json',
  });

  constructor(private http: HttpClient) {
    super({ trackStateHistory: true, logStateChanges: false });
  }

  public searchResultUnits(labTechnician: ITechnician, requestBody: ISearchParams): Observable<ResultUnit[]> {
    return this.http.post<ResultUnit[]>(`${this.url}/${labTechnician.id}`,
      {
        labTechnicianId: labTechnician.id,
        labTechnicianRole: labTechnician.role,
        labCenterAlias: labTechnician.labCenterAlias,
        previousPageLastResultUnitOid: '',
        ...requestBody
      },
      { headers: this.headers }
    )
      .pipe(
        map((labResults: ResultUnit[]) => {
          this.saveLabResults(labResults);
          return labResults;
        }),
        catchError(this.handleError('searchResultUnits'))
      );
  }

  public refreshLabResults(labTechnician: ITechnician, params: ISearchParams = {}): Observable<ResultUnit[]> {
    return this.searchResultUnits(labTechnician, params);
  }

  public loadNextLabResults(labTechnician: ITechnician, requestBody: ISearchParams, lastLabResultOid: string): Observable<void> {
    const state = this.getState();
    if (state && state.labResults?.length > 0) {
      return this.http.post<ResultUnit[]>(`${this.url}/${labTechnician.id}`,
        {
          labTechnicianId: labTechnician.id,
          labTechnicianRole: labTechnician.role,
          labCenterAlias: labTechnician.labCenterAlias,
          previousPageLastResultUnitOid: lastLabResultOid,
          ...requestBody
        },
        { headers: this.headers }
      )
        .pipe(
          map((labResults: ResultUnit[]) => {
            state.labResults.push(...labResults);
            this.saveLabResults(state.labResults);
          }),
          catchError(this.handleError('getNextLabResults'))
        );
    }
  }

  public saveLabResults(labResults: ResultUnit[]): void {
    this.setState({ labResults }, AppCoreActions.setLabResults);
  }

  public saveLabResultApproval(labResult: ResultUnit, labResultIndex: number): Observable<ResultUnit> {
    const { labResults } = this.getState();
    if (labResults[labResultIndex].id === labResult.id) {
      labResults[labResultIndex] = labResult;
    } else {
      for (let i = 0; i < labResults.length; i++) {
        if (labResults[i].id === labResult.id) {
          labResults[i] = labResult;
          break;
        }
      }
    }

    this.setState({ labResults }, AppCoreActions.setLabResults);
    return of(labResult);
  }

  public removeLabResults(): void {
    this.setState({ labResults: [] }, AppCoreActions.setLabResults);
  }

  private handleError(operation: string) {
    return (err: any) => {
      let errMsg = `ERROR in ${operation}() retrieving ${this.url}`;
      console.error(`${errMsg}:`, err);
      if (err instanceof HttpErrorResponse) {
        return throwError(() => new HttpErrorResponse({ status: 404 }));
      }
      errMsg = `status: ${err.status}, ${err.statusText}`;
      return throwError(() => new Error(errMsg));
    };
  }
}
