import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/core/services';
import {
  TOperationSummary,
  TSubmissionStatistics,
  EMonthType,
} from '../../models';
import { OperationSummaryService } from '../../services/operation-summary.service';
import { SubmissionStatisticsService } from '../../services/submission-statistics.service';
import { extractMonthFromSummary } from '../../utils/count-summary.utils';

@Component({
  selector: 'ztp-operation-summary',
  templateUrl: './operation-summary.component.html',
  styleUrls: ['./operation-summary.component.scss'],
})
export class OperationSummaryComponent implements OnInit, OnDestroy {
  private operationSummarySub: Subscription;
  private submissionStatisticsSub: Subscription;
  private labTechnicianSub: Subscription;

  operationSummary: TOperationSummary;
  submissionStatistics: TSubmissionStatistics;
  showLoadPlaceHolder = true;

  period: {
    year: number;
    month: EMonthType;
  };

  constructor(
    private readonly operationSummaryService: OperationSummaryService,
    private readonly submissionStatisticsService: SubmissionStatisticsService,
    private readonly authService: AuthenticationService
  ) {}

  ngOnInit(): void {
    this.fetchOperationSummary();
    this.fetchSubmissionStatistics();
  }

  private fetchOperationSummary(): void {
    this.labTechnicianSub = this.authService
      .getCurrentLabTechnician()
      .subscribe((currentLabTechnician) => {
        this.operationSummarySub = this.operationSummaryService
          .getOperationSummary(currentLabTechnician, 'previous')
          .subscribe((operationSummary) => {
            this.operationSummary = operationSummary;
            this.period = extractMonthFromSummary(this.operationSummary);
            this.showLoadPlaceHolder = false;
          });
      });
  }

  private fetchSubmissionStatistics(): void {
    this.labTechnicianSub = this.authService
      .getCurrentLabTechnician()
      .subscribe((currentLabTechnician) => {
        this.submissionStatisticsSub = this.submissionStatisticsService
          .getSubmissionStatistics(currentLabTechnician, 'previous')
          .subscribe((submissionStatistics) => {
            this.submissionStatistics = submissionStatistics;
          });
      });
  }

  ngOnDestroy(): void {
    this.operationSummarySub.unsubscribe();
    this.submissionStatisticsSub.unsubscribe();
  }
}
