import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ztp-placeholder-loading',
  templateUrl: './placeholder-loading.component.html',
  styleUrls: ['./placeholder-loading.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlaceholderLoadingComponent implements OnInit, AfterViewInit {

  @Input() numberOfLine = 1;

  @Input() color = '#888E96';

  @Input() height = 10;

  @Input() width = 30;

  @Input() radius = 5;

  @Input() loadingParams: ILoadingParams = {
    animation: 'linear',
    delay: 1900,
    duration: Infinity,
    offset: 0.5,
  };

  constructor(private elementRef: ElementRef) { }

  ngOnInit(): void {
    document.querySelectorAll('.text-loading').forEach(value => {
      const boxFrames = [
        { backgroundPosition: '100% 50%', opacity: '0.5' },
        { backgroundPosition: '50% 100%', offset: this.loadingParams.offset, opacity: '1' },
        { backgroundPosition: '0% 50%', opacity: '0.5' },
      ];

      value.animate(boxFrames, {
        duration: this.loadingParams.delay,
        easing: this.loadingParams.animation,
        iterations: this.loadingParams.duration,
      });
    });
  }

  ngAfterViewInit() {
    this.elementRef.nativeElement.querySelectorAll('.text-loading').forEach(value => {
      const boxFrames = [
        { backgroundPosition: '100% 50%', opacity: '0.5' },
        { backgroundPosition: '50% 100%', offset: this.loadingParams.offset, opacity: '1' },
        { backgroundPosition: '0% 50%', opacity: '0.5' },
      ];

      value.animate(boxFrames, {
        duration: this.loadingParams.delay,
        easing: this.loadingParams.animation,
        iterations: this.loadingParams.duration,
      });
    });
  }

  get numberOfLines() {
    return new Array(this.numberOfLine);
  }

  get style() {
    return {
      height: this.height + 'rem',
      width: this.width + '%',
      borderRadius: this.radius + 'px',
      background: `linear-gradient(to right, ${this.color},80%,${this.color})`,
      // backgroundSize: '200%',

    };
  }
}
export interface ILoadingParams {
  animation: string;
  delay: number;
  duration: number;
  offset: number;
}
