import { Component, Input, AfterViewInit } from '@angular/core';
import { catchError, of, switchMap } from 'rxjs';
import { ILabCenter } from 'src/app/core/models';
import { AuthenticationService } from 'src/app/core/services';
import { TReportCard } from '../../models/index.d';
import { ReportArchiveService } from '../../services/report-archives.service';

@Component({
  selector: 'ztp-report-card',
  templateUrl: './report-card.component.html',
  styleUrls: ['./report-card.component.scss'],
})
export class ReportCardComponent implements AfterViewInit {
  @Input() status: 'active' | 'inactive';
  @Input() report: TReportCard;
  @Input() year: number;
  @Input() currentLabCenter: ILabCenter;
  reportAvailable = true;
  reportDownloadInProgress = false;

  constructor(
    private readonly authService: AuthenticationService,

    private readonly reportArchiveService: ReportArchiveService
  ) {}

  ngAfterViewInit(): void {
    if (!this.report[1].submissionStatistics) {
      this.reportAvailable = false;
    }
  }

  downloadPdf(report: TReportCard): void {
    this.reportDownloadInProgress = true;

    this.authService
      .getCurrentLabTechnician()
      .pipe(
        switchMap((currentLabTechnician) =>
          this.reportArchiveService.downloadReportPdf(
            currentLabTechnician,
            report
          )
        ),
        catchError((error) => {
          console.error('An error occurred:', error);
          this.reportDownloadInProgress = false;
          return of(null);
        })
      )
      .subscribe((pdfBase64) => {
        this.reportDownloadInProgress = false;
        const binaryData = Buffer.from(pdfBase64, 'base64');
        const blob = new Blob([binaryData], { type: 'application/pdf' });
        const blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl, '_blank');
        URL.revokeObjectURL(blobUrl);
      });
  }
}
