import { Component, Input } from '@angular/core';
import {
  keyframes,
  style,
  transition,
  animate,
  trigger,
} from '@angular/animations';

@Component({
  selector: 'ztp-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
  animations: [
    trigger('keyframes', [
      transition(':enter', [
        animate(
          '1s',
          keyframes([style({ width: '0%' }), style({ width: '{{level}}%' })])
        ),
      ]),
    ]),
  ],
})
export class ProgressBarComponent {
  @Input() index: number;
  @Input() level: number;
  @Input() quantity: number;
  @Input() label: string;
  @Input() surname: string;
  @Input() givenName: string;

  colors = ['#62B592', '#9DDCC3', '#D1EFE3', '#B3E2FC', '#CCEBFD', '#FF8300'];

}
