import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ObservableStore } from '@codewithdan/observable-store';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ITechnician } from 'src/app/core/models';
import {
  AppCoreActions,
  IStoreState,
  TechnicianActions,
} from '../../../core/models/store.interface';
import { ESubmissionMode } from '../../result-unit-upload/submission-page/submission.interface';
import { ResultUnit } from '../components/result-unit-card/result-unit.interface';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ResultUnitListService extends ObservableStore<IStoreState> {
  private url = `${environment.apimBaseUrl}/technician/result-units`;
  private headers = new HttpHeaders().set('Ocp-Apim-Subscription-Key', environment.apimSubscriptionKey);

  constructor(private readonly http: HttpClient) {
    super({ trackStateHistory: true, logStateChanges: false });
  }

  private fetchLabs(labTechnician: ITechnician, requestBody = {}): Observable<ResultUnit[]> {
    return this.http.post<ResultUnit[]>(`${this.url}/${labTechnician.id}`, {
      labTechnicianId: labTechnician.id,
      labTechnicianRole: labTechnician.role,
      labCenterAlias: labTechnician.labCenterAlias,
      previousPageLastResultUnitOid: '',
      ...requestBody
    }, { headers: this.headers }).pipe(
      map((labResults) => {
        this.setState({ labResults }, AppCoreActions.setLabResults);
        return labResults;
      }),
      catchError(this.handleError('fetchLabs', this.url))
    );
  }

  public getLabResults(labTechnician: ITechnician, requestBody?: any): Observable<ResultUnit[]> {
    const state = this.getState();
    if (state && state.labResults?.length > 0) {
      return of(state.labResults);
    }
    return this.fetchLabs(labTechnician, requestBody);
  }

  public refreshLabResults(labTechnician: ITechnician, requestBody?: any): Observable<ResultUnit[]> {
    return this.fetchLabs(labTechnician, requestBody);
  }

  public editCurrentLabResult(): void {
    this.setState({ submissionMode: ESubmissionMode.EDIT_SUBMISSON }, TechnicianActions.setSubmissionMode);
  }

  public saveCurrentLabResult(currentLabResult: ResultUnit): void {
    this.setState({ currentLabResult }, TechnicianActions.setCurrentLabResult);
    this.setState({ currentPatient: currentLabResult?.patient }, TechnicianActions.setCurrentPatient);
  }

  public clearCurrentLabResult(): void {
    const currentLabResult = null;
    const currentPatient = null;
    this.setState({ currentLabResult }, TechnicianActions.setCurrentLabResult);
    this.setState({ currentPatient }, TechnicianActions.setCurrentPatient);
  }

  private handleError(operation: string, url: string) {
    return (err: any) => {
      let errMsg = `ERROR in ${operation}() retrieving ${url}`;
      console.error(`${errMsg}:`, err);
      if (err instanceof HttpErrorResponse) {
        errMsg = `status: ${err.status}, ${err.statusText}`;
      }
      return throwError(() => new Error(errMsg));
    };
  }
}
