import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { NgSelectComponent, NgSelectModule } from '@ng-select/ng-select';
import { FormControl, FormsModule } from '@angular/forms';
import { SvgIconsModule } from '@ngneat/svg-icon';

import { CommonModule } from '@angular/common';
import { TranslocoRootModule } from 'src/app/transloco/transloco-root.module';


@Component({
  selector: 'ztp-auto-complete',
  standalone: true,
  templateUrl: './auto-complete.component.html',
  styleUrls: ['./auto-complete.component.scss'],
  imports: [
    NgSelectModule,
    SvgIconsModule,
    CommonModule,
    FormsModule,
    TranslocoRootModule,
  ],
})
export class AutoCompleteComponent {
  @Output() onValueChange = new EventEmitter<any>();
  @Output() onBoxOpen = new EventEmitter<boolean>();

  @Input() label: string;
  @Input() placeholder: string;
  @Input() items = [];

  @ViewChild('Selecter') selectBox: NgSelectComponent;

  @Input()
  set item(item: FormControl) {
    this.selectedItem = item?.value?.trim() ?? null;
  }

  selectedItem: string;

  public openSelectBox(): void {
    this.selectBox.open();
  }

  public selectValue(data: any): void {
    const value = this.items.find((item) => item.name === data?.name);
    this.onValueChange.emit(value);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public onOpen(_data: any): void {
    this.onBoxOpen?.emit(true);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public onClose(data: any): void {
    this.onBoxOpen?.emit(false);
  }
}
