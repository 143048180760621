enum EGender {
  Male = 'male',
  Female = 'female',
  Other = 'other'
}

enum EPrimaryLang {
  ENGLISH = 'english',
  FRENCH = 'french'
}

enum ESecondaryLang {
  ENGLISH = 'english',
  EWE = 'ewe',
  FRENCH = 'french',
  KABYE = 'kabye',
  KOTOKOLI = 'kotokoli'
}

enum ERole {
  PATIENT = 'patient',
  KIOSK = 'kiosk'
}

export interface IPatient {
  id: string;
  givenName: string;
  surname: string;
  mobilePhone: string;
  mail: string;
  secondaryPhone: string;
  primaryLanguage: EPrimaryLang;
  zovuId: string;
  dateOfBirth: string;
  gender: EGender;
}

export interface IGraphPatient {
  '@odata.context': string;
  givenName: string;
  surname: string;
  displayName: string;
  mobilePhone: string;
  businessPhones: string[];
  mail: string;
  streetAddress: string;
  city: string;
  country: string;
  extension_eb8f31090f284bb99c9716c466ac5729_PrimaryLanguage: EPrimaryLang;
  extension_eb8f31090f284bb99c9716c466ac5729_SecondaryLanguage: ESecondaryLang;
  extension_eb8f31090f284bb99c9716c466ac5729_ZovuId: string;
  extension_eb8f31090f284bb99c9716c466ac5729_Role: ERole;
  extension_eb8f31090f284bb99c9716c466ac5729_DateOfBirth: string;
  extension_eb8f31090f284bb99c9716c466ac5729_CountryCodeISO2: string;
  extension_eb8f31090f284bb99c9716c466ac5729_Gender: EGender;
  extension_eb8f31090f284bb99c9716c466ac5729_MobilePhoneOnWhatsapp: boolean;
}

export class Patient {
  id: string;
  givenName: string;
  surname: string;
  mobilePhone: string;
  mail: string;
  secondaryPhone: string;
  primaryLanguage: EPrimaryLang;
  zovuId: string;
  dateOfBirth: string;
  gender: EGender;

  constructor(data: IGraphPatient, guid: string) {
    this.id = guid;
    this.givenName = data.givenName;
    this.surname = data.surname;
    this.mobilePhone = data.mobilePhone;
    this.mail = data.mail ?? '';
    this.secondaryPhone = data.businessPhones[0] ?? '';
    this.primaryLanguage =
      data.extension_eb8f31090f284bb99c9716c466ac5729_PrimaryLanguage;
    this.zovuId = data.extension_eb8f31090f284bb99c9716c466ac5729_ZovuId;
    this.dateOfBirth =
      data.extension_eb8f31090f284bb99c9716c466ac5729_DateOfBirth;
    this.gender = data.extension_eb8f31090f284bb99c9716c466ac5729_Gender;
  }
}
