/* istanbul ignore file */
import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
@Pipe({
  name: 'zovuDateFormat',
})
export class ZovuDateFormatPipe extends
  DatePipe implements PipeTransform {
  lang = (navigator.language || 'en-US').split('-')[0];
  timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  constructor() {
    super('en-US');
  }

  transform(value: any): string | any {
    switch (this.lang) {
    case 'fr':
      if (!this.getFormatDate(value).includes('.')) {
        return super.transform(new Date(value), 'dd MMM. yy - H:mm', this.timezone, this.lang);
      }
      return this.getFormatDate(value);
    default:
      return super.transform(new Date(value), 'MMM dd \'\'yy - h:mm aaaaa\'m\'', this.timezone, this.lang);
    }
  }

  getFormatDate(date): string {
    return super.transform(new Date(date), 'dd MMM yy - H:mm', this.timezone, this.lang);
  }

}
