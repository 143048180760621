<div class="report-archives">
  <div class="title">
    <span>{{ 'dashboard.report-archives.title' | transloco }}</span>
  </div>
  <div>
    <ztp-report-archive-card-placeholder
      *ngIf="showLoadPlaceHolder"
    ></ztp-report-archive-card-placeholder>
  </div>
  <div class="months" *ngIf="sizeArchivesCards > 0">
    <span
      [class.active]="selectedMonth === i"
      *ngFor="let archiveCard of archivesCards; let i = index"
      (click)="goToMonth(i)"
    >
      {{ archiveCard[0] | slice: 0:3 }}
    </span>
  </div>
  <div class="monthly-archives" *ngIf="sizeArchivesCards > 0">
    <div class="arrow-icon" (click)="goToMonth(selectedMonth - 1)">
      <svg-icon *ngIf="selectedMonth > 0" key="arrow-left"></svg-icon>
    </div>
    <div class="report-archives-cards">
      <div class="archives">
        <ztp-report-card
          #reportCard
          [status]="selectedMonth === i && 'active'"
          [report]="archiveCard"
          [year]="selectedYear"
          [currentLabCenter]="currentLabCenter"
          *ngFor="let archiveCard of archivesCards; let i = index"
          [id]="'month-' + i"
        ></ztp-report-card>
      </div>
    </div>
    <div class="arrow-icon arrow-right" (click)="goToMonth(selectedMonth + 1)">
      <svg-icon
        *ngIf="selectedMonth < sizeArchivesCards - 1"
        key="arrow-left"
      ></svg-icon>
    </div>
  </div>
</div>
