import { IPatient } from 'src/app/features/result-unit-list/components/patient-card/patient.interface';
import { ILabCenter } from './lab-center.interface';
import { ESubmissionMode, SubmissionDraft, SubmissionDraftKey } from '../../features/result-unit-upload/submission-page/submission.interface';
import { ResultUnit } from 'src/app/features/result-unit-list/components/result-unit-card/result-unit.interface';
import { ITechnician } from './technician.interface';
import { ISearchParams } from './search-params.interface';

export interface IStoreState {
  currentPatient: IPatient;
  currentLabResult: ResultUnit;
  labCenter: ILabCenter;
  labTechnician: ITechnician;
  labResults: ResultUnit[];
  labResultsSearchParams: ISearchParams;
  submissionDrafts: Record<SubmissionDraftKey, SubmissionDraft>;
  submissionMode: ESubmissionMode;
}

export enum AppCoreActions {
  initState = 'INIT_STATE',
  setLabCenter = 'SET_LAB_CENTER',
  setLabTechnician = 'SET_LAB_TECHNICIAN',
  setLabResults = 'SET_LAB_RESULTS',
  setSubmissionDraftFailedStatus = 'SET_FAILED_TECHNICIAN_LAB_SUBMISSION',
  setSubmissionDraftSuccessStatus = 'SET_SUCCESS_TECHNICIAN_LAB_SUBMISSION',
  setSubmissionDraftReuploadStatus = 'SET_RE-UPLOAD_TECHNICIAN_LAB_SUBMISSION',
  removeSuccessfullSubmissionDraft = 'REMOVE_SUCCESS_TECHNICIAN_LAB_SUBMISSION',
}


export enum TechnicianActions {
  setCurrentPatient = 'SET_CURRENT_PATIENT',
  setCurrentLabResult = 'SET_CURRENT_LAB_RESULT',
  setLabResultListSearchParams = 'SET_LAB_RESULT_LIST_QUERY_PARAMS',
  setCurrentSubmissionDraft = 'SET_CURRENT_SUBMISSION_DRAFT',
  setSubmissionMode = 'SET_SUBMISSION_MODE',
  uploadLabSubmission = 'UPLOAD_LAB_SUBMISSION',
  reUploadLabSubmission = 'RE-UPLOAD_LAB_SUBMISSION',
  editLabSubmission = 'EDIT_LAB_SUBMISSION',
  cancelSubmissionDraft = 'CANCEL_LAB_SUBMISSION',
  deleteSubmissionDraft = 'DELETE_LAB_SUBMISSION',
}
