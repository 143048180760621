import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  Output,
  EventEmitter,
} from '@angular/core';

import { ESubmissionStatus, SubmissionDraft } from 'src/app/features/result-unit-upload/submission-page/submission.interface';
import { ESubmissionNotificationAction } from './notification.interface';

@Component({
  selector: 'ztp-notification-tile',
  templateUrl: './notification-tile.component.html',
  styleUrls: ['./notification-tile.component.scss'],
})
export class NotificationTileComponent implements OnDestroy, OnInit {
  @Input() submission: SubmissionDraft;

  @Output() onSubmissionEvent = new EventEmitter<{ data: SubmissionDraft; action: ESubmissionNotificationAction }>();

  ngOnInit() { }

  public reUploadSubmission(): void {
    this.onSubmissionEvent.emit({
      data: this.submission,
      action: ESubmissionNotificationAction.RE_UPLOAD
    });
  }

  public retrySubmission(): void {
    this.onSubmissionEvent.emit({
      data: this.submission,
      action: ESubmissionNotificationAction.RETRY
    });
  }

  public cancelSubmission(): void {
    this.onSubmissionEvent.emit({
      data: this.submission,
      action: ESubmissionNotificationAction.CANCEL
    });
  }

  public closeSubmission(): void {
    this.onSubmissionEvent.emit({
      data: this.submission,
      action: ESubmissionNotificationAction.CLOSE
    });
  }

  public getTileMessage(): string {
    switch (this.submission?.submissionStatus) {
    case ESubmissionStatus.PROGRESS:
      return 'notification_drawer.unit_content.in_progress_message';
    case ESubmissionStatus.FAILED:
      return 'notification_drawer.unit_content.failed_message';
    case ESubmissionStatus.SUCCESS:
      return 'notification_drawer.unit_content.success_message';

    default:
      break;
    }
  }

  ngOnDestroy() { }
}
