<div class="patient-card" *transloco="let t; read: 'upload_panel'">
  <div class="patient-card-header">
    <span class="avatar">
      <span
        >{{ patient?.givenName | slice: 0:1
        }}{{ patient?.surname | slice: 0:1 }}</span
      >
    </span>
    <div class="info" *ngIf="patient; else showPlaceholderInfoLoading">
      <span class="name"
        >{{ patient?.givenName | titlecase }}&nbsp;{{
          patient?.surname | titlecase
        }}</span
      >
      <span class="zovu-id">Zovu ID: {{ patient?.zovuId | ztpZovuId }}</span>
    </div>
    <ng-template #showPlaceholderInfoLoading>
      <div class="placeholder info"></div>
    </ng-template>
  </div>
  <ul class="patient-card-body">
    <li class="patient-card-body-item">
      <svg-icon key="calendar" color="#061E24" fontSize="30px"></svg-icon>
      <div *ngIf="patient; else showPlaceholderdetail1Loading">
        <span class="title">{{ t('date_of_birth') }}</span>
        <span
          class="value dob"
          [ztpTooltip]="
            patient?.dateOfBirth.replace('-', '/')
              | date
                : 'longDate'
                : 'UTC'
                : (browserLanguage[0] === 'fr' ? 'fr' : 'en')
          "
          >{{
            patient?.dateOfBirth.replace('-', '/')
              | date
                : (browserLanguage[0] === 'fr' ? 'mediumDate' : "MMM dd ''yy")
                : 'UTC'
                : (browserLanguage[0] === 'fr' ? 'fr' : 'en')
          }}</span
        >
      </div>
      <ng-template #showPlaceholderdetail1Loading>
        <div class="placeholder detail"></div>
      </ng-template>
    </li>
    <li class="patient-card-body-item">
      <svg-icon key="person" fontSize="30px"></svg-icon>
      <div *ngIf="patient; else showPlaceholderdetail2Loading">
        <span class="title">{{ t('gender') }}</span>
        <span class="value">{{ patient?.gender | titlecase }}</span>
      </div>
      <ng-template #showPlaceholderdetail2Loading>
        <div class="placeholder detail"></div>
      </ng-template>
    </li>
  </ul>
</div>
