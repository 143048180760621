import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'ztp-submission-statistics-card-placeholder',
  templateUrl: './submission-statistics-card-placeholder.html',
  styleUrls: ['./submission-statistics-card-placeholder.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubmissionStatisticsCardPlaceholderComponent implements OnInit {
  placeholders = Array.from({ length: 6 });

  options: string[] = [];

  selectedOption = 0;

  constructor(private readonly translocoService: TranslocoService) {}

  ngOnInit(): void {
    this.loadTranslations();
  }

  loadTranslations(): void {
    this.translocoService
      .selectTranslate(
        'dashboard.operation-summary.submission-statistics.options.per-department'
      )
      .subscribe((value) => this.options.push(value));
    this.translocoService
      .selectTranslate(
        'dashboard.operation-summary.submission-statistics.options.per-study'
      )
      .subscribe((value) => this.options.push(value));
    this.translocoService
      .selectTranslate(
        'dashboard.operation-summary.submission-statistics.options.per-technician'
      )
      .subscribe((value) => this.options.push(value));
  }
}
