<div class="operation-summary" *ngIf="showLoadPlaceHolder">
  <p class="title">{{ 'dashboard.operation-summary.title' | transloco }}</p>
  <div class="content">
    <div class="count-summary">
      <ztp-count-summary-card-placeholder></ztp-count-summary-card-placeholder>
    </div>
    <div class="submission-statistics">
      <div class="submission-statistics-placeholder">
        <div class="content-placeholder">
          <div class="stats-container-placeholder">
            <ztp-submission-statistics-card-placeholder
            ></ztp-submission-statistics-card-placeholder>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="operation-summary" *ngIf="!showLoadPlaceHolder">
  <p class="title">{{ 'dashboard.operation-summary.title' | transloco }}</p>
  <div class="content">
    <div class="count-summary">
      <ztp-count-summary
        [operationSummary]="operationSummary"
        [period]="period"
        *ngIf="operationSummary[period.year][period.month] && period"
      ></ztp-count-summary>
    </div>
    <div class="submission-statistics">
      <ztp-submission-statistics
        [submissionStatistics]="submissionStatistics"
        [period]="period"
        *ngIf="submissionStatistics && period"
      ></ztp-submission-statistics>
    </div>

    <div
      class="summary-not-available"
      *ngIf="!operationSummary[period.year][period.month]"
    >
      <div class="body-summary">
        <div class="image-summary">
          <svg-icon key="notAvailable" width="162px" height="159px"></svg-icon>
        </div>
        <div class="text-summary">
          <div class="resume-title">
            {{ 'dashboard.operation-summary.not_available_title' | transloco }}
          </div>
          <div class="resume-text">
            {{
              'dashboard.operation-summary.not_available_content.result_not_available_text'
                | transloco
            }}.
            <br />
            {{
              'dashboard.operation-summary.not_available_content.check_back_text'
                | transloco
            }}.
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
