<div class="submission-statistics">
  <p class="title">
    {{ 'dashboard.operation-summary.submission-statistics.title' | transloco }}
  </p>
  <div class="options-group">
    <div
      [ngClass]="selectedOption === i && 'active'"
      *ngFor="let option of options; let i = index"
    >
      {{ option }}
    </div>
  </div>
  <div class="content">
    <div class="container-1" *ngFor="let placeholder of placeholders">
      <div class="stats-1 placeholder"></div>
      <div class="stats-container-1">
        <div class="stats-2 placeholder"></div>
        <div class="stats-3 placeholder"></div>
      </div>
    </div>
  </div>
</div>
