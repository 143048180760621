import { CommonModule } from '@angular/common';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
  NO_ERRORS_SCHEMA,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SvgIconsModule } from '@ngneat/svg-icon';

import { TranslocoRootModule } from 'src/app/transloco/transloco-root.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { SharedModule } from 'src/app/shared/shared.module';

import { AdvanceSearchComponent } from './advance-search/advance-search.component';

import {
  DatePickerComponent,
  FilterBarComponent,
  SearchBarComponent
} from './components';


import { appCalendarIcon } from 'src/app/svg/zovu/calendar';
import { appFilterIcon } from 'src/app/svg/zovu/filter';
import { appSearchLineIcon } from 'src/app/svg/zovu/search-line';
import { AdvanceSearchService } from './advance-search/advance-search.service';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    NgSelectModule,
    ReactiveFormsModule,
    SvgIconsModule.forChild([
      appCalendarIcon,
      appFilterIcon,
      appSearchLineIcon,
    ]),
    TranslocoRootModule,
    SharedModule,
  ],
  declarations: [
    FilterBarComponent,
    SearchBarComponent,
    DatePickerComponent,
    AdvanceSearchComponent,
  ],
  providers: [
    AdvanceSearchService
  ],
  exports: [AdvanceSearchComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class AdvanceSearchModule {}
