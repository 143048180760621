import { trigger, transition, style, animate } from '@angular/animations';
import { Component } from '@angular/core';

import { ToasterService } from './toaster.service';

import { IToast } from './toast-tile/toast-tile.interface';

@Component({
  selector: 'ztp-toaster',
  templateUrl: './toaster.component.html',
  styleUrls: ['./toaster.component.scss'],
  animations: [
    trigger('fadeAnimation', [
      transition(':enter', [
        style({ opacity: 0 }), animate('200ms', style({ opacity: 1 }))]
      ),
      transition(':leave',
        [style({ opacity: 1 }), animate('200ms', style({ opacity: 0 }))]
      )
    ])
  ]
})
export class ToasterComponent {
  currentToasts: IToast[] = [];


  constructor(private toastService: ToasterService) {
    this.subscribeToToasts();
  }


  subscribeToToasts() {
    this.toastService.toastEvents.subscribe((toast) => {
      this.currentToasts.push(toast);
    });
  }

  dispose(index: number) {
    this.currentToasts.splice(index, 1);
  }
}
