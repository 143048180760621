/* eslint-disable import/no-extraneous-dependencies */
/*istanbul ignore file */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AccountInfo } from '@azure/msal-common';
import { catchError } from 'rxjs/operators';

import { AuthenticationService } from './authentication.service';

import { ILog, ILogEntry, LogEntry, LogState } from '../models/log.interface';
import { ISubmissionNotification } from '../components/notification-drawer/notification-tile/notification.interface';

@Injectable({
  providedIn: 'root',
})
export class LogService {
  private client = 'Technician-Portal';

  private url = 'http://localhost:7071/api/operationlog/';

  private accountInfo: AccountInfo;

  constructor(private http: HttpClient, private authService: AuthenticationService) {
    this.accountInfo = this.authService.getActiveAccount();
  }

  log(event: string, type: string | object, subject: string, state: LogState, notificationData: ISubmissionNotification) {
    const entry: ILogEntry = {
      client: this.client,
      subject,
      value: notificationData.submissionId,
      userId: this.accountInfo.localAccountId,
      type,
      state,
      labCenterId: notificationData.labCenter.id,
      event,
    };
    const log = new LogEntry(entry);
    const logData = log.buildLogObject();
    this.sendLog(logData);
  }

  sendLog(entry: ILog) {
    this.http.put<ILog>(this.url, entry)
      .pipe(
        catchError(error => {
          console.log(error);
          throw new Error(error);
        }),
      )
      .subscribe(data => {
        console.log(data);
      });
  }
}
