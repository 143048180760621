import Dexie, { Table } from 'dexie';

export interface IDbMedia {
  id?: number;
  submissionId: string;
  rawFile: string;
}

class DBClient extends Dexie {
  submissionMediaRawFiles!: Table<IDbMedia, number>;

  constructor() {
    super('submissionsMediaDB');
    this.version(3).stores({
      submissionMediaRawFiles: '++id, submissionId',
    });
  }

  public async retrieveSubmissionDraftMedia(submissionId: string): Promise<IDbMedia> {
    return await dbService.submissionMediaRawFiles
      .where('submissionId')
      .equals(submissionId)
      .first();
  }

  public async insertSubmissionDraftMedia(submissionId: string, rawFile: string): Promise<number> {
    return await dbService.submissionMediaRawFiles
      .add({
        submissionId,
        rawFile
      });
  }

  public async updateSubmissionDraftMedia(submissionId: string, rawFile: string): Promise<number> {
    return await dbService.submissionMediaRawFiles
      .where('submissionId')
      .equals(submissionId)
      .modify({
        submissionId,
        rawFile
      });
  }

  public async deleteSubmissionDraftMedia(submissionId: string): Promise<number> {
    return await dbService.submissionMediaRawFiles
      .where('submissionId')
      .equals(submissionId)
      .delete();
  }

  public async flush(): Promise<void> {
    return await dbService.transaction('rw', 'submissionMediaRawFiles', () => {
      this.submissionMediaRawFiles.clear();
    });
  }
}

export const dbService = new DBClient();
