<div class="step">
    <div class="step-circle" *ngIf="icon">
        <!-- <img [src]="icon" class="icon"> -->
        <svg-icon [key]="icon"></svg-icon>
    </div>
    <div class="step-title">
        <h1 *ngIf="title">{{title}}</h1>
    </div>
    <div class="step-desc" *ngIf="description">{{description}}</div>
</div>
<!-- TODO: Rename component Help Page -->
<!-- This component does not need to be shared. Please remove it  from this module and put in a module together with the contact us. Name this module UserAssistance and lazy load/ preload it.-->
