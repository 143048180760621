import {
  Component,
  Renderer2,
  AfterViewInit,
  OnDestroy,
  ViewChild,
  Input,
  ElementRef,
  ViewChildren,
  QueryList,
  OnChanges,
} from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Subscription } from 'rxjs';
import { ILabCenter } from 'src/app/core/models';
import { AuthenticationService } from 'src/app/core/services';
import { ReportArchiveService } from '../../services/report-archives.service';
import { TReportCard } from '../../models/index';

@Component({
  selector: 'ztp-report-archives',
  templateUrl: './report-archives.component.html',
  styleUrls: ['./report-archives.component.scss'],
})
export class ReportArchivesComponent
implements AfterViewInit, OnChanges, OnDestroy
{
  @Input() currentLabCenter: ILabCenter;

  private reportArchiveSub: Subscription;

  private selectReportCardSub: Subscription;

  currentYear = new Date().getFullYear();

  archivesCards: TReportCard[];

  selectedMonth = 0;

  selectedYear = 2022;

  showLoadPlaceHolder = true;

  archivesObserver: IntersectionObserver;

  @ViewChild('YearSelecter') yearSelectBox: NgSelectComponent;

  @ViewChildren('reportCard', { read: ElementRef })
    selectReportCard!: QueryList<ElementRef>;

  constructor(
    private renderer: Renderer2,
    private readonly authService: AuthenticationService,
    private readonly reportArchiveService: ReportArchiveService
  ) {}

  ngOnChanges(): void {
    this.showLoadPlaceHolder = true;
    this.archivesCards = [];
    this.retrieveReportArchives();
  }

  public openYearSelectBox(): void {
    this.yearSelectBox.open();
  }

  goToMonth(index: number): void {
    const month = this.renderer.selectRootElement(`#month-${index}`, true);
    this.scrollCard(month);
    this.selectedMonth = index;
  }

  ngAfterViewInit(): void {
    this.selectReportCardSub = this.selectReportCard.changes.subscribe(
      (data) => {
        data.toArray().forEach((element, index, array) => {
          if (index === array.length - 2) {
            this.scrollCard(element.nativeElement);
            this.selectedMonth = index;
          }
        });
      }
    );
  }

  private retrieveReportArchives(): void {
    this.authService
      .getCurrentLabTechnician()
      .subscribe((currentLabTechnician) => {
        this.reportArchiveSub = this.reportArchiveService
          .getReportArchives(currentLabTechnician)
          .subscribe((reportArchives) => {
            this.archivesCards = this.pullAllMonths(
              reportArchives
            ) as TReportCard[];
            this.showLoadPlaceHolder = false;
          });
      });
  }

  private scrollCard(element: any) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'center',
    });
  }

  private pullAllMonths(reportArchives) {
    delete reportArchives['_id'];
    const transformedData = [];

    Object.values(reportArchives).forEach((year) => {
      transformedData.push(...Object.entries(year));
    });
    return transformedData;
  }

  ngOnDestroy(): void {
    this.reportArchiveSub.unsubscribe();
    this.selectReportCardSub.unsubscribe();
  }
}
