import { Directive, ElementRef, HostListener, Input, OnDestroy } from '@angular/core';

@Directive({
  selector: '[ztpTooltip]'
})
export class TooltipDirective implements OnDestroy {

  @Input() ztpTooltip = '';
  @Input() delay?= 75;

  private myPopup;
  private timer;

  constructor(private el: ElementRef) {}

  ngOnDestroy(): void {
    if (this.myPopup) { this.myPopup.remove(); }
  }

  @HostListener('mouseenter')
  onMouseEnter() {
    this.timer = setTimeout(() => {
      let pos = this.getOffset(this.el.nativeElement);
      this.createTooltipPopup(pos.left, pos.top + 5);
    }, this.delay);
  }

  @HostListener('mouseleave')
  @HostListener('mousedown')
  onMouseLeave() {
    if (this.timer) { clearTimeout(this.timer); }
    if (this.myPopup) { this.myPopup.remove(); }
  }

  private createTooltipPopup(x: number, y: number) {
    let popup = document.createElement('div');
    popup.innerHTML = this.ztpTooltip;
    popup.setAttribute('class', 'tooltip-container');
    popup.style.top = y.toString() + 'px';
    popup.style.left = x.toString() + 'px';
    document.body.appendChild(popup);
    this.myPopup = popup;
    // setTimeout(() => {
    //   if (this.myPopup) { this.myPopup.remove(); }
    // }, 5000);
  }

  private getOffset(el: any) {
    const rect = el.getBoundingClientRect();
    return {
      left: rect.left + window.scrollX + el.offsetWidth / 2 + 10,
      top: rect.top + window.scrollY + el.offsetHeight
    };
  }
}
