/* eslint-disable import/no-extraneous-dependencies */
/*istanbul ignore file */
import { Injectable } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { AccountInfo } from '@azure/msal-common';
import { ObservableStore } from '@codewithdan/observable-store';
import { Observable, of, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { LabCenterService } from './lab-center.service';

import { AppCoreActions, IStoreState } from '../models/store.interface';
import {
  IAssignedLabCenter,
  ITechnician,
  Technician,
} from '../models/technician.interface';
import { ESubmissionMode } from 'src/app/features/result-unit-upload/submission-page/submission.interface';

import { environment } from 'src/environments/environment';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService extends ObservableStore<IStoreState> {
  public loggedIn = false;
  private labCenterSub: Subscription;

  constructor(
    private readonly msalService: MsalService,
    private readonly labCenterService: LabCenterService,
    private readonly msalBroadcastService: MsalBroadcastService,
    private readonly localStorageService: LocalStorageService
  ) {
    super({ trackStateHistory: true, logStateChanges: false });
    const initialState = {
      currentPatient: null,
      currentLabResult: null,
      labCenter: null,
      labTechnician: null,
      labResults: [],
      labResultsSearchParams: {},
      submissionDrafts: {},
      submissionMode: ESubmissionMode.NEW_SUBMISSON,
    };
    this.setState(initialState, AppCoreActions.initState);
  }

  saveCurrentLabTechnician(
    labTechnician: ITechnician,
    defaultAssignedLabCenter: IAssignedLabCenter
  ): void {
    if (this.localStorageService.getItem('labCenter' as string) === null) {
      this.localStorageService.setItem(
        'labCenter',
        `${defaultAssignedLabCenter.alias}_${defaultAssignedLabCenter.id}`
      );
      labTechnician.labCenterAlias = defaultAssignedLabCenter.alias;
      labTechnician.labCenterId = defaultAssignedLabCenter.id;
    } else {
      labTechnician.labCenterAlias = this.localStorageService
        .getItem<string>('labCenter')
        .split('_')[0];
      labTechnician.labCenterId = this.localStorageService
        .getItem<string>('labCenter')
        .split('_')[1];
    }

    this.setState({ labTechnician }, AppCoreActions.setLabTechnician);
    this.labCenterSub = this.labCenterService
      .getTechnicianLabCenter(labTechnician?.id, labTechnician?.labCenterAlias)
      .subscribe((labCenter) => {
        this.setState({ labCenter }, AppCoreActions.setLabCenter);
        this.labCenterSub?.unsubscribe();
      });
  }

  getCurrentLabTechnician(): Observable<ITechnician> {
    const state = this.getState();
    if (state && state.labTechnician) {
      return of(state.labTechnician);
    } else {
      const accounts = this.msalService.instance.getAllAccounts();
      return of(new Technician(accounts[0]));
    }
  }

  public handleUserLoginProcess(): Observable<ITechnician> {
    return new Observable((subscriber) => {
      this.msalBroadcastService.inProgress$
        .pipe(
          filter(
            (status: InteractionStatus) => status === InteractionStatus.None
          )
        )
        .subscribe(() => {
          this.loggedIn = this.msalService.instance.getAllAccounts().length > 0;
          if (this.msalService.instance.getAllAccounts().length > 0) {
            const accounts = this.msalService.instance.getAllAccounts();
            this.msalService.instance.setActiveAccount(accounts[0]);
            const labTechnician = new Technician(accounts[0]);
            this.saveCurrentLabTechnician(
              labTechnician,
              labTechnician.assignedLabCenters[0]
            );
            subscriber.next(labTechnician);
          }
        });
    });
  }

  public getActiveAccount(): AccountInfo | null {
    return this.msalService.instance.getActiveAccount();
  }

  public async logout(): Promise<void> {
    this.msalService
      .logoutRedirect({
        account: this.getActiveAccount(),
        postLogoutRedirectUri: environment.postLogoutRedirectUri,
      })
      .subscribe();
  }
}
