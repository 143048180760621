import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Subscription, timer } from 'rxjs';
import { IPatient } from './patient.interface';

@Component({
  selector: 'ztp-patient-card',
  templateUrl: './patient-card.component.html',
  styleUrls: ['./patient-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class PatientCardComponent implements OnInit {
  private placeHolderTimer = timer(300);

  private timerSub: Subscription;

  @Input()
  set patient(data: IPatient) {
    this._patient = data;
    this.showPlaceHolder = true;
    this.startTimer();
  }

  showPlaceHolder: boolean;

  _patient: IPatient;

  togglerText = 'More Info';

  browserLanguage: string[];

  ngOnInit(): void {
    this.browserLanguage = navigator.language?.split('-') ?? ['en','US'];
  }

  public changeTogglerText(): void {
    this.togglerText = this.togglerText === 'More Info' ? 'Show Less' : 'More Info';
  }

  public startTimer(): void {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    this.timerSub = this.placeHolderTimer.subscribe((_output) => {
      this.showPlaceHolder = false;
      this.timerSub?.unsubscribe();
    });
  }
}
