export const appDashBoardIcon = {
  data: `<svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M3 2H11.5263V11.4737H3V2ZM3.94737 2.86124V10.6124H10.5789V2.86124H3.94737Z" fill="currentColor" stroke="currentColor" stroke-width="0.2"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.4219 9.57861H21.9482V19.9997H13.4219V9.57861ZM14.3692 10.526V19.0523H21.0008V10.526H14.3692Z" fill="currentColor" stroke="currentColor" stroke-width="0.2"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M3 13.3687H11.5263V20.0002H3V13.3687ZM3.94737 14.316V19.0529H10.5789V14.316H3.94737Z" fill="currentColor" stroke="currentColor" stroke-width="0.2"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.4219 2H21.9482V7.68421H13.4219V2ZM14.3692 2.81203V6.87218H21.0008V2.81203H14.3692Z" fill="currentColor" stroke="currentColor" stroke-width="0.2"/>
    </svg>
    `,
  name: 'dashboard',
};
