<div class="help-container">
    <div class="help-body" *transloco="let t; read: 'help_page'">
        <div class="help-title">{{t('title')}}</div>
        <div class="help-description">{{t('message')}}</div>
        <div class="help-step">
            <div class="step1">
                <ztp-step-widget icon="log-in" [title]="t('first_step_message_header')"
                    [description]="t('first_step_message')">
                </ztp-step-widget>
            </div>
            <div class="space1"></div>
            <div class="step2">
                <ztp-step-widget icon="check-circle" [title]="t('second_step_message_header')"
                    [description]="t('second_step_message')">
                </ztp-step-widget>
            </div>
            <div class="space2"></div>
            <div class="step3">
                <ztp-step-widget icon="file-text" [title]="t('third_step_message_header')"
                    [description]="t('third_step_message')">
                </ztp-step-widget>
            </div>
        </div>
    </div>
</div>