<form class="card" *transloco="let t; read: 'upload_panel'">
    <h3 class="title">{{ t('comments_header') }}</h3>
    <textarea
        [formControl]="commentControl"
        (focus)="onBoxFocus($event)"
        (focusout)="onBoxFocusOut($event)"
        [placeholder]="t('comments_box_message')"
        name="comment"
        ></textarea>
</form>
