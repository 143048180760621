import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SvgIconsModule } from '@ngneat/svg-icon';
import { TranslocoModule } from '@ngneat/transloco';
import { NgChartsModule, NgChartsConfiguration } from 'ng2-charts';
import { PeriodSelectorComponent } from '../components/period-selector/period-selector.component';

import { LiveChartComponent } from './components/live-chart/live-chart.component';
import { LiveCountComponent } from './components/live-count/live-count.component';
import { AblyService } from '../../../core/services/ably.service';
import { SseService } from '../../../core/services/sse.service';
import { LiveCounterService } from './services/live-counter.service';
import { LiveCounterTabsComponent } from './components/live-counter-tabs/live-counter-tabs.component';
import { LiveCounterCardPlaceholderComponent } from './components/live-counter-card-placeholder/live-counter-card-placeholder.component';

@NgModule({
  imports: [
    TranslocoModule,
    BrowserModule,
    BrowserAnimationsModule,
    SvgIconsModule,
    NgChartsModule,
    PeriodSelectorComponent,
  ],
  declarations: [
    LiveCounterTabsComponent,
    LiveCountComponent,
    LiveChartComponent,
    LiveCounterCardPlaceholderComponent,
  ],
  providers: [
    { provide: NgChartsConfiguration, useValue: { generateColors: false } },
    AblyService,
    SseService,
    LiveCounterService,
  ],
  exports: [
    LiveCounterTabsComponent,
    LiveCountComponent,
    LiveChartComponent,
    LiveCounterCardPlaceholderComponent,
  ],
})
export class LiveCounterModule {}
