import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { map, Observable, take } from 'rxjs';
import { AuthenticationService } from 'src/app/core/services';

@Injectable({ providedIn: 'root' })
export class LabHeadGuard implements CanActivate {
  constructor(private authService: AuthenticationService) {}

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return this.authService.getCurrentLabTechnician().pipe(
      take(1),
      map((labTechnician) => {
        if (labTechnician.role === 'lab-head') {
          return true;
        }
        return false;
      })
    );
  }
}
