export const appBarChartOutlinedIcon = {
  data: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_4348_1573)">
    <path d="M21.333 3.3335H2.66634C2.31272 3.3335 1.97358 3.47397 1.72353 3.72402C1.47348 3.97407 1.33301 4.31321 1.33301 4.66683V19.3335C1.33301 19.6871 1.47348 20.0263 1.72353 20.2763C1.97358 20.5264 2.31272 20.6668 2.66634 20.6668H21.333C21.6866 20.6668 22.0258 20.5264 22.2758 20.2763C22.5259 20.0263 22.6663 19.6871 22.6663 19.3335V4.66683C22.6663 4.31321 22.5259 3.97407 22.2758 3.72402C22.0258 3.47397 21.6866 3.3335 21.333 3.3335ZM2.66634 19.3335V4.66683H21.333V19.3335H2.66634Z" fill="white"/>
    <path d="M4.66699 6.6665H8.66699V17.3332H7.60033V7.73317H5.73366V17.3332H4.66699V6.6665Z" fill="white"/>
    <path d="M10 12.6665H14V17.3332H12.9333V13.7332H11.0667V17.3332H10V12.6665Z" fill="white"/>
    <path d="M15.333 10.6665H19.333V17.3332H18.2663V11.7332H16.3997V17.3332H15.333V10.6665Z" fill="white"/>
    </g>
    <defs>
    <clipPath id="clip0_4348_1573">
    <rect width="24" height="24" fill="white"/>
    </clipPath>
    </defs>
    </svg>`,
  name: 'bar-chart-outlined',
};
