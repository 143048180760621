export const appNotAvailableIcon = {
  data: `
     <svg xmlns="http://www.w3.org/2000/svg" width="162" height="159" viewBox="0 0 162 159" fill="none">
    <path d="M55.5355 113.029L45.8384 129.139L26.5216 129.093L16.9018 112.936L26.5988 96.826L45.9157 96.8722L55.5355 113.029Z" fill="#59A585"/>
    <path d="M143.452 60.4941L127.675 122.2L64.9472 140.834L17.9974 97.7618L33.775 36.0554L96.5025 17.4216L143.452 60.4941Z" fill="#CEE8DD"/>
    <path d="M138.993 36.1447L129.295 52.2549L109.979 52.2087L100.359 36.0524L110.056 19.9422L129.373 19.9884L138.993 36.1447Z" fill="#B4CBD1"/>
    <path d="M111.65 124H49.35C45.8094 124 42.4137 122.593 39.9101 120.09C37.4065 117.586 36 114.191 36 110.65V39.45C36 38.2698 36.4688 37.1379 37.3034 36.3034C38.1379 35.4688 39.2698 35 40.45 35H102.75C103.93 35 105.062 35.4688 105.897 36.3034C106.731 37.1379 107.2 38.2698 107.2 39.45V92.85H125V110.65C125 114.191 123.593 117.586 121.09 120.09C118.586 122.593 115.191 124 111.65 124ZM107.2 101.75V110.65C107.2 111.83 107.669 112.962 108.503 113.797C109.338 114.631 110.47 115.1 111.65 115.1C112.83 115.1 113.962 114.631 114.797 113.797C115.631 112.962 116.1 111.83 116.1 110.65V101.75H107.2ZM98.3 115.1V43.9H44.9V110.65C44.9 111.83 45.3688 112.962 46.2034 113.797C47.0379 114.631 48.1698 115.1 49.35 115.1H98.3ZM53.8 57.25H89.4V66.15H53.8V57.25ZM53.8 75.05H89.4V83.95H53.8V75.05ZM53.8 92.85H76.05V101.75H53.8V92.85Z" fill="#0C586C"/>
  </svg>
    `,
  name: 'notAvailable',
};
