/* istanbul ignore file */
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { map, catchError, switchMap } from 'rxjs/operators';
import { ObservableStore } from '@codewithdan/observable-store';

import { IStoreState, TechnicianActions } from '../../../core/models/store.interface';
import { IGraphPatient, IPatient, Patient } from '../../result-unit-list/components/patient-card/patient.interface';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PatientSearchService extends ObservableStore<IStoreState> {
  private url = `${environment.apimBaseUrl}/patient/patient`;
  private headers = new HttpHeaders().set('Ocp-Apim-Subscription-Key', environment.apimSubscriptionKey);

  constructor(private http: HttpClient) {
    super({ trackStateHistory: true, logStateChanges: false });
  }

  public getPatientByZovuId(zovuid: string): Observable<Patient> {
    return this.http.get<{ guid: string }>(`${this.url}/zovuid-alias/${zovuid}`, { headers: this.headers }).pipe(
      switchMap(alias => this.http.get<IGraphPatient>(`${this.url}/demographic/${alias?.guid}`, { headers: this.headers }).pipe(
        map(patientData => new Patient(patientData, alias?.guid))
      )),
      catchError(this.handleError('getPatientByZovuId'))
    );
  }

  public getPatientByGuid(guid: string): Observable<Patient> {
    return this.http.get<IGraphPatient>(`${this.url}/demographic/${guid}`, { headers: this.headers }).pipe(
      map(patientData => new Patient(patientData, guid)),
      catchError(this.handleError('getPatientByGuid'))
    );
  }

  saveCurrentPatient(currentPatient: IPatient): void {
    this.setState({ currentPatient }, TechnicianActions.setCurrentPatient);
  }

  getCurrentPatient(): Observable<IPatient> {
    const state = this.getState();
    if (state) {
      return of(state.currentPatient);
    }
  }

  private handleError(operation: string) {
    return (err: any) => {
      let errMsg = `ERROR in ${operation}() retrieving ${this.url}`;
      console.error(`${errMsg}:`, err);
      if (err instanceof HttpErrorResponse) {
        return throwError(() => new HttpErrorResponse({ status: 404 }));
      }
      errMsg = `status: ${err.status}, ${err.statusText}`;
      return throwError(() => new Error(errMsg));
    };
  }
}
