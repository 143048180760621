<div class="submission-statistics">
  <p class="title">
    {{ 'dashboard.operation-summary.submission-statistics.title' | transloco }}
  </p>
  <div class="options-group">
    <div
      (click)="selectOption(i)"
      [ngClass]="selectedOption === i && 'active'"
      *ngFor="let option of options; let i = index"
    >
      {{ option }}
    </div>
  </div>
  <div class="content">
    <ztp-progress-bar
      *ngFor="let stat of stats; let i = index"
      [index]="i"
      [level]="(stat.count / totalSubmissions) * 100"
      [label]="stat.labDepartment ?? stat.studyName"
      [surname]="stat.technician?.surname || stat.labTechnician"
      [givenName]="stat.technician?.givenName"
      [quantity]="stat.count"
    ></ztp-progress-bar>
  </div>
</div>
