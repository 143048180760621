/* istanbul ignore file */
import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalService } from 'src/app/shared/modal/modal.service';
import { PatientSearchService } from '../../patient-search-page/patient-search.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { IPatient } from 'src/app/features/result-unit-list/components/patient-card/patient.interface';
import sudoNames from '../../../../../assets/names';
import { VerificationModalActions } from 'src/app/shared/modal/modal.interface';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'ztp-patient-verificator',
  templateUrl: './patient-verificator-modal.component.html',
  styleUrls: ['./patient-verificator-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class PatientVerificatorModalComponent implements OnInit, OnDestroy {

  @Input() zovuId?: string;

  namePlaceholders = Array.from({ length: 4 });
  patientsNameList: string[] = [];

  browserLanguage: string[];
  private searchSub: Subscription;
  private expectedPatient: string;


  constructor(
    private readonly modalService: ModalService,
    private readonly patientSearchService: PatientSearchService,
  ) { }

  ngOnInit() {
    this.searchSub = this.patientSearchService
      .getPatientByZovuId(this.zovuId)
      .subscribe({
        next: (patient: IPatient) => {
          if (patient) {
            this.expectedPatient = patient.givenName + ' ' + patient.surname;
            this.patientSearchService.saveCurrentPatient(patient);
            this.patientsNameList = this.shuffleArray([this.generateSudoPatient(patient.givenName), this.generateSudoPatient(patient.givenName), this.generateSudoPatient(patient.givenName), this.expectedPatient]);
          }
        },
        error: (e) => {
          if (e instanceof HttpErrorResponse && e.status === 404) {
            this.executeModalAction(VerificationModalActions.FAILED_INVALID_ID);

          } else { this.executeModalAction(VerificationModalActions.FAILED_SERVER_ERROR); }
        },
      });
  }

  selectPatient(patientName: string): void {
    if (patientName !== this.expectedPatient) {
      this.executeModalAction(VerificationModalActions.FAILED_VERIFICATION);
    } else {
      this.executeModalAction(VerificationModalActions.PASSED_VERIFICATION);
    }
  }

  public closeModal() {
    this.executeModalAction(VerificationModalActions.CLOSED_MODAL);
  }

  private _closeModal() {
    const element = document.getElementById('modal');
    element.className = element.className.replace(
      /\bmodal-visible\b/g,
      'modal-hidden'
    );
    this.modalService.destroy();
  }

  private executeModalAction(action: VerificationModalActions): void {
    this.modalService.emitPatientVerificationAction(action);
    this._closeModal();
  }

  private generateSudoPatient(patientFirstName: string): string {
    const lastNameIndex = Math.floor(Math.random() * sudoNames.length);
    return patientFirstName + ' ' + sudoNames[lastNameIndex];
  }

  private shuffleArray<T>(array: T[]): T[] {
    // Should be moved into a shared utils
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  ngOnDestroy(): void {
    this.searchSub?.unsubscribe();
  }
}
