import { ERole } from './technician.interface';

export enum EApprovalStatus {
  APPROVED = 'approved',
  UNAPPROVED = 'unapproved',
  CANCELLED = 'cancelled',
}

export interface ISearchParams {
  resultUnitIdAlias?: number;
  labSubmissionStatus?: EApprovalStatus;
  zovuId?: string;
  patientName?: string;
  labDepartment?: string;
  studyName?: string;
  resultMinDate?: number;
  resultMaxDate?: number;
  labTechnicianId?: string;
  labTechnicianRole?: ERole | string;
  labCenterAlias?: string;
}
