import { NgModule } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { ReportArchiveModule } from './report-archives/report-archives.module';
import { DashboardPageComponent } from './dashboard-page/dashboard-page.component';
import { OperationSummaryModule } from './operation-summary/operation-summary.module';
import { LiveCounterModule } from './live-counter/live-counter.module';

@NgModule({
  imports: [
    TranslocoModule,
    OperationSummaryModule,
    ReportArchiveModule,
    LiveCounterModule,
  ],
  declarations: [DashboardPageComponent],
})
export class DashboardModule {}
