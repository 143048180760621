import {
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Subscription } from 'rxjs';

import { ResultUnitListService } from './result-unit-list.service';
import { AdvanceSearchService } from '../../advance-search/advance-search/advance-search.service';
import { AuthenticationService, LabCenterService } from 'src/app/core/services';

import { ResultUnit } from '../components/result-unit-card/result-unit.interface';
import { ISearchParams } from 'src/app/core/models/search-params.interface';
import { ITechnician } from 'src/app/core/models';

@Component({
  selector: 'ztp-result-unit-list-page',
  templateUrl: './result-unit-list-page.component.html',
  styleUrls: ['./result-unit-list-page.component.scss'],
})
export class ResultUnitListPageComponent implements OnInit, OnDestroy {
  private labResultsSub: Subscription;

  private labTechnicianSub: Subscription;

  labResults: ResultUnit[] = [];

  currentLabResult: ResultUnit;

  currentLabResultIndex: number;

  currentLabTechnician: ITechnician;

  showLoadPlaceHolder = true;

  showMorePlaceHolder = false;

  showFilters: boolean;

  showLoadRequestLoader: boolean;

  searchParams: ISearchParams = {};

  fakeLabs = Array(15);

  moreFakeLabs = Array(3);

  searchResultsMeta = {};

  lastLabResultOid: string;

  constructor(
    private readonly labResultService: ResultUnitListService,
    private readonly advancedSearchService: AdvanceSearchService,
    private readonly authService: AuthenticationService,
    private readonly labCenterService: LabCenterService,
    private readonly labSearchService: AdvanceSearchService,
  ) { }

  ngOnInit(): void {
    this.fetchLabs();
    this.observeLabResultSearchStateChanges();

    this.labCenterService.stateWithPropertyChanges.subscribe((state) =>{
      if ('labCenter' in state.stateChanges) {
        this.authService.getCurrentLabTechnician().subscribe((labTechnician) => {
          this.showLoadPlaceHolder = true;
          if (labTechnician) {
            this.labSearchService
              .refreshLabResults({...labTechnician, labCenterAlias: state.stateChanges.labCenter.alias})
              .subscribe((labResults) => {
                this.showLoadPlaceHolder = false;
              });
          }
        });
      }
    });
  }

  public fetchLabs(): void {
    this.labTechnicianSub = this.authService.getCurrentLabTechnician()
      .subscribe((currentLabTechnician) => {
        this.currentLabTechnician = currentLabTechnician;
        this.labResultsSub = this.labResultService
          .getLabResults(currentLabTechnician)
          .subscribe({
            next: (labResults: ResultUnit[]) => {
              this.labResults = labResults;
              if (labResults.length > 0) {
                this.lastLabResultOid = labResults[labResults.length - 1]['_id'];
              }
              this.showLoadPlaceHolder = false;

            },
            error: () => {
              this.labResults = [];
              this.showLoadPlaceHolder = false;
            },
          });
      });
  }

  public observeLabResultSearchStateChanges(): void {
    this.labResultsSub = this.advancedSearchService.stateChanged.subscribe((state) => {
      if (state) {
        this.labResults = state.labResults;
        if (state.labResults.length > 0) {
          this.searchResultsMeta['searchResultsCount'] = state.labResults.length;
          // this.searchResultsMeta['searchResultsMaxDate'] = state.labResults[0].creationDateTime;
          // this.searchResultsMeta['searchResultsMinDate'] = state.labResults[state.labResults.length - 1].creationDateTime;
          this.lastLabResultOid = state.labResults[state.labResults.length - 1]['_id'];
        }
      }
    });
  }

  public closeDetailsBody(): void {
    this.currentLabResult = null;
  }

  public toggleFiltersView(show: boolean): void {
    this.showFilters = show;
  }

  public setSearchParams(params: ISearchParams): void {
    this.searchParams = params;
  }

  public showSearchLoader(show: boolean): void {
    this.showLoadPlaceHolder = show;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public showLoader(_show: boolean): void {
    // this.showLoadRequestLoader = show;
  }

  public setSelectedLabResult(labResult: ResultUnit, labResultIndex?: number): void {
    this.currentLabResult = labResult;
    if (labResultIndex !== null) {
      this.currentLabResultIndex = labResultIndex;
    }
  }

  public onScroll(): void {
    if (!this.showMorePlaceHolder) {
      this.showMorePlaceHolder = true;
      this.labResultsSub = this.advancedSearchService
        .loadNextLabResults(this.currentLabTechnician, this.searchParams, this.lastLabResultOid)
        .subscribe({
          next: () => {
            this.showMorePlaceHolder = false;
          },
          error: (err) => {
            this.showMorePlaceHolder = false;
          }
        });
    }
  }

  public trackByFn(item): any {
    return item?.id;
  }

  ngOnDestroy(): void {
    this.labResultsSub?.unsubscribe();
    this.labTechnicianSub?.unsubscribe();
  }
}
