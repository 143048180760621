import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';
import { ITechnician } from 'src/app/core/models';
import { environment } from 'src/environments/environment';
import { TMonthOptions, TSubmissionStatistics } from '../models';

@Injectable({
  providedIn: 'root',
})
export class SubmissionStatisticsService {
  private url = `${environment.apimBaseUrl}/dashboard/submission-statistics`;
  private headers = new HttpHeaders().set(
    'Ocp-Apim-Subscription-Key',
    environment.apimSubscriptionKey
  );

  constructor(private http: HttpClient) {}

  public getSubmissionStatistics(
    labTechnician: ITechnician,
    month: TMonthOptions
  ): Observable<TSubmissionStatistics> {
    return this.http
      .get<TSubmissionStatistics>(
      `${this.url}/${labTechnician.id}/${labTechnician.labCenterAlias}?month=${month}`,
      { headers: this.headers }
    )
      .pipe(
        map((result) => result),
        catchError(this.handleError('fetchSubmissionStatistics', this.url))
      );
  }

  private handleError(operation: string, url: string) {
    return (err: any) => {
      let errMsg = `ERROR in ${operation}() retrieving ${url}`;
      console.error(`${errMsg}:`, err);
      if (err instanceof HttpErrorResponse) {
        errMsg = `status: ${err.status}, ${err.statusText}`;
      }
      return throwError(() => new Error(errMsg));
    };
  }
}
