/* eslint-disable @typescript-eslint/no-unused-vars */
/*istanbul ignore file */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ObservableStore } from '@codewithdan/observable-store';
import { Observable, of } from 'rxjs';

import {
  FlagKey,
  IFeatureFlag,
} from '../shared/feature-flag/feature-flag.interface';
import { IStoreState, TechnicianActions } from '../core/models/store.interface';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagsService extends ObservableStore<IStoreState> {
  private url = 'https://zovuapp.herokuapp.com/featureflags';

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'my-auth-token',
    }),
  };

  constructor(private http: HttpClient) {
    super({ trackStateHistory: true, logStateChanges: false });
  }

  private fetchAllFeatureFlags(): Observable<Record<FlagKey, IFeatureFlag>> | void {
    // return this.http.get<Record<FlagKey, IFeatureFlag>>(this.url).pipe(
    //   map((allFeatureFlags) => {
    //     this.setState(
    //       { allFeatureFlags },
    //       TechnicianActions.getAllFeatureFlags,
    //     );
    //     return allFeatureFlags;
    //   }),
    // );
  }

  public getFeatureFlags(): Observable<Record<FlagKey, IFeatureFlag>> | void {
    // const state = this.getState();
    // if (state && state.allFeatureFlags) {
    //   return of(state.allFeatureFlags);
    // } else {
    //   return this.fetchAllFeatureFlags();
    // }
  }

  public updateFeatureFlag(fflag: IFeatureFlag): Observable<IFeatureFlag> | void {
    // const fflags = this.getState().allFeatureFlags;
    // return this.http.put<IFeatureFlag>(this.url, fflag, this.httpOptions).pipe(
    //   map((feactureFlag) => {
    //     fflags[feactureFlag.name] = feactureFlag;
    //     this.setState(
    //       { allFeatureFlags: fflags },
    //       TechnicianActions.updateAllFeatureFlags,
    //     );
    //     return feactureFlag;
    //   }),
    // );
  }

  isFeatureFlagEnabled(flagName: string) {
    // const state = this.getState();
    // if (
    //   !state ||
    //   !state.allFeatureFlags ||
    //   Object.keys(state.allFeatureFlags).length === 0
    // ) {
    //   return false;
    // }
    // return state.allFeatureFlags[flagName].activated;
  }
}
