import { appAltGreenIcon } from './alt-green';
import { appArobaseIcon } from './arobase';
import { appBellIcon } from '../zovu/bell';
import { appCalendarIcon } from './calendar';
import { appCallGreenIcon } from './call-green';
import { appCameraIcon } from './camera';
import { appCheckCircleIcon } from './check-circle';
import { appCheckIcon } from './check';
import { appChevronDownIcon } from './chevron-down';
import { appChevronIcon } from './chevron';
import { appCloseIcon } from './close';
import { appDragDropIcon } from './drag-drop';
import { appEditIcon } from './edit';
import { appFileTextGreenIcon } from './file-text-green';
import { appFileTextIcon } from './file-text';
import { appHelpCircleIcon } from './help-circle';
import { appHomeIcon } from './home';
import { appInfoIcon } from './info';
import { appLogInIcon } from './log-in';
import { appLogOutIcon } from './log-out';
import { appMailIcon } from './mail';
import { appMaximizeIcon } from './maximize';
import { appPersonIcon } from './person';
import { appPhoneCallIcon } from './phone-call';
import { appPhoneIcon } from './phone';
import { appPhone2Icon } from './phone2';
import { appScannerIcon } from './scanner';
import { appSearchIcon } from './search';
import { appUploadIcon } from './upload';
import { appUserIcon } from './user';
import { appUsersIcon } from './users';
import { appX32X32Icon } from './x-32x32';
import { appXCircleIcon } from './x-circle';
import { appExitIcon } from './exit';
import { appErrorIcon } from './error';
import { appCloseTileIcon } from './close-tile';
import { appPdfIcon } from './pdf';
import { appDotMenuIcon } from './dot-menu';
import { appEditActionIcon } from './edit-action';
import { appApproveActionIcon } from './approve-action';
import { appUnapproveActionIcon } from './unapprove-action';
import { appChevronBackIcon } from './chevron-back';
import { appFilterIcon } from './filter';
import { appSearchLineIcon } from './search-line';
import { appRetry } from './retry';
import { appSuccessNotifIcon } from './success-notif';
import { appFailedNotifIcon } from './failed-notif';
import { appDeleteFailedNotifIcon } from './delete-failed-notif';
import { appNotifItemMenuIcon } from './notif-item-menu';
import { appCloseNotifPanelIcon } from './close-notif-panel';
import { appCameraOffIcon } from './camera-off';
import { appCloseToastIcon } from './close-toast';
import { appEditInfoIcon } from './edit-info';
import { appWarningIcon } from './warning';
import { appCloseModalIcon } from './close-modal';
import { appDashBoardIcon } from './dashboard';
import { appPopOutIcon } from './pop-out';
import { appArrowLeftIcon } from './arrow-left';
import { appBarChartFilledIcon } from './bar-chart-filled';
import { appBarChartOutlinedIcon } from './bar-chart-outlined';
import { appNotAvailableIcon } from './not-available';
import { printerPdf } from './printer';
import { appTestTubeIcon } from './test-tube';
import { appLocationIcon } from './location';

export const zovuIcons = [
  appAltGreenIcon,
  appArobaseIcon,
  appBellIcon,
  appCalendarIcon,
  appCallGreenIcon,
  appCameraIcon,
  appCheckCircleIcon,
  appCheckIcon,
  appChevronDownIcon,
  appChevronIcon,
  appCloseIcon,
  appDragDropIcon,
  appEditIcon,
  appFileTextGreenIcon,
  appFileTextIcon,
  appHelpCircleIcon,
  appHomeIcon,
  appInfoIcon,
  appLogInIcon,
  appLogOutIcon,
  appMailIcon,
  appMaximizeIcon,
  appPersonIcon,
  appPhoneCallIcon,
  appPhoneIcon,
  appPhone2Icon,
  appScannerIcon,
  appSearchIcon,
  appUploadIcon,
  appUserIcon,
  appX32X32Icon,
  appUsersIcon,
  appXCircleIcon,
  appExitIcon,
  appErrorIcon,
  appCloseTileIcon,
  appPdfIcon,
  appDotMenuIcon,
  appEditActionIcon,
  appApproveActionIcon,
  appUnapproveActionIcon,
  appChevronBackIcon,
  appFilterIcon,
  appSearchLineIcon,
  appRetry,
  appSuccessNotifIcon,
  appFailedNotifIcon,
  appDeleteFailedNotifIcon,
  appNotifItemMenuIcon,
  appCloseNotifPanelIcon,
  appCameraOffIcon,
  appCloseToastIcon,
  appEditInfoIcon,
  appWarningIcon,
  appCloseModalIcon,
  appDashBoardIcon,
  appPopOutIcon,
  appArrowLeftIcon,
  appBarChartFilledIcon,
  appBarChartOutlinedIcon,
  appNotAvailableIcon,
  printerPdf,
  appTestTubeIcon,
  appLocationIcon
];
