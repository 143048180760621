/*istanbul ignore file */
import { Injectable } from '@angular/core';
import { ObservableStore } from '@codewithdan/observable-store';
import { Observable } from 'rxjs';
import * as io from 'socket.io-client';

import { environment } from 'src/environments/environment';
import { IStoreState } from '../models/store.interface';

@Injectable({
  providedIn: 'root',
})
export class SocketServer extends ObservableStore<IStoreState> {
  private socket: io.Socket = null;

  init(labCenterAlias: string) {
    const serverPort = environment.webNodeServer.socketPorts[labCenterAlias];
    try {
      this.socket = io.io(`${environment.webNodeServer.socketUrl}:${serverPort}`, { reconnection: true, reconnectionAttempts: 10 });
      this.socket.on('connect', () => {
        console.log('Socket server connection initialized');
        this.socket.emit('init', `connected to ${labCenterAlias} --> ${serverPort}`);
      });

    } catch (error) {
      throw new Error(error);
    }
  }

  listenToSocketServerEvent(eventName: string) {
    return new Observable(subscriber => {
      this.socket?.on(eventName, eventData => {
        console.log(eventData);
        subscriber.next(eventData);
      });
    });
  }
}
