import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ILabCenter, ITechnician } from 'src/app/core/models';
import { AuthenticationService, LabCenterService } from 'src/app/core/services';

@Component({
  selector: 'ztp-dashboard-page',
  templateUrl: './dashboard-page.component.html',
  styleUrls: ['./dashboard-page.component.scss'],
})
export class DashboardPageComponent implements OnInit, OnDestroy {
  private authSub: Subscription;

  private labCenterSub: Subscription;

  labTechnician: ITechnician;

  currentLabCenter: ILabCenter;

  constructor(
    private authService: AuthenticationService,
    private labCenterService: LabCenterService
  ) {}

  ngOnInit(): void {
    this.getLabTechnician();
    this.getCurrentLab();
  }

  getLabTechnician(): void {
    this.authSub = this.authService.stateChanged.subscribe(() => {
      this.authSub = this.authService
        .getCurrentLabTechnician()
        .subscribe((labTechnician) => {
          this.labTechnician = labTechnician;
        });
    });
  }

  getCurrentLab(): void {
    this.labCenterSub = this.labCenterService.stateWithPropertyChanges.subscribe((state) =>{
      if ('labCenter' in state.stateChanges) {
        this.currentLabCenter = state.stateChanges.labCenter;
      }
    });
  }

  ngOnDestroy(): void {
    this.authSub?.unsubscribe();
    this.labCenterSub?.unsubscribe();
  }
}
