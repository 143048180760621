<div class="loader">
    <svg class="svg" xmlns="http://www.w3.org/2000/svg" width="550" height="500" viewBox="-100 -90 250 200" aspect-ratio="XminYmin"
        preserveAspectRatio="none">
        <defs>
            <style>
                .a {
                    fill: #62b592;
                }

                .b {
                    fill: #c9f5c0;
                }

                .c {
                    fill: #6ccba6;
                }
            </style>
        </defs>
        <g transform="translate(-145.64 -281.854)">
            <path class="a" d="M145.64,335.3H165.9l14.98-12.4H160.453Z" transform="translate(0 -28.641)" />
            <path class="b" d="M151.251,281.854l-5.611,12.4h14.812l14.812-12.4Z" transform="translate(0 0)" />
            <path class="c" d="M209.477,281.854l-14.812,12.4h20.423Z" transform="translate(-34.212 0)" />
            <path class="b" d="M187.45,376.337l5.611-12.4H178.08l-14.98,12.4Z" transform="translate(-12.185 -57.282)" />
            <path class="c" d="M150.916,376.337l14.98-12.4H145.64Z" transform="translate(0 -57.282)" />
        </g>
    </svg>
    <!-- <img src="/assets/images/logo/logo-green.svg" width="250" height="200"> -->
</div>