/* istanbul ignore file */
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { IPatient } from 'src/app/features/result-unit-list/components/patient-card/patient.interface';

@Component({
  selector: 'ztp-patient-card',
  templateUrl: './patient-card.component.html',
  styleUrls: ['./patient-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PatientCardComponent implements OnInit {
  @Input() patient: IPatient;

  browserLanguage: string[];

  ngOnInit(): void {
    this.browserLanguage = navigator.language?.split('-') ?? ['en','US'];
  }
}
