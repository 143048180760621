import { Pipe, PipeTransform } from '@angular/core';
import { monthNames } from '../../utils/constants.utils';
import { EMonthType } from '../models';

@Pipe({
  name: 'monthDays',
})
export class MonthDaysPipe implements PipeTransform {
  transform(value: EMonthType): number {
    return new Date(
      new Date().getFullYear(),
      Object.values(monthNames).indexOf(value) + 1,
      0
    ).getDate();
  }
}
