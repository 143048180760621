export interface Study {
  id: string;
  name: string;
  protected: boolean;
  aliases: string[];
  category: string;
}

export interface ILabCenterDetails {
  departments: string[];
  availableStudies: Study[];
}

export interface ILabCenter {
  id: string;
  name: string;
  alias: string;
  country: string;
  city: string;
  address: string;
  officialContactEmail: string;
  officialContactNumber: string;
  guarantorEmail: string;
  guarantorPhoneNumber: string;
  websiteUrl: string;
  logo: string;
}

