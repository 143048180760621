import {
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
  NgModule,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { FileUploadModule } from 'ng2-file-upload';
import { NgSelectModule } from '@ng-select/ng-select';
import { SvgIconsModule } from '@ngneat/svg-icon';
import { DragulaModule } from 'ng2-dragula';
import { PdfViewerModule } from 'ng2-pdf-viewer';

import { TranslocoRootModule } from '../transloco/transloco-root.module';

import { HelpStepComponent } from './help-step/help-step.component';
import { PatientInfoItemComponent } from './patient-info-item/patient-info-item.component';
import { PlaceholderLoadingComponent } from './placeholder-loading/placeholder-loading.component';
import { ModalComponent } from './modal/modal.component';
import { FeatureFlagComponent } from './feature-flag/feature-flag.component';

import { InputAnimationDirective } from './directives/input-animation.directive';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { TooltipDirective } from './directives/tooltip.directive';

import { DateFnsPipe } from './pipes/date-fns.pipe';
import { ZovuDateFormatPipe } from './pipes/zovu-date-format.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { ZovuIdFormatPipe } from './pipes/zovu-id-format.pipe';
import { ZovuFullDateFormatPipe } from './pipes/zovu-full-date-format.pipe';
import { ZovuGradiantAnglePipe } from './pipes/zovu-gradiant-angle.pipe';

import { zovuIcons } from '../svg/zovu';

@NgModule({
  declarations: [
    HelpStepComponent,
    DateFnsPipe,
    PatientInfoItemComponent,
    PlaceholderLoadingComponent,
    InputAnimationDirective,
    ModalComponent,
    ZovuDateFormatPipe,
    FeatureFlagComponent,
    SafePipe,
    ClickOutsideDirective,
    ZovuIdFormatPipe,
    TooltipDirective,
    ZovuFullDateFormatPipe,
    ZovuGradiantAnglePipe,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    FileUploadModule,
    PdfViewerModule,
    TranslocoRootModule,
    SvgIconsModule.forRoot({
      sizes: {
        xs: '10px',
        sm: '24px',
        md: '50px',
        lg: '100px',
      },
      defaultSize: 'sm',
      icons: zovuIcons,
    }),
    DragulaModule.forRoot(),
  ],
  exports: [
    HelpStepComponent,
    DateFnsPipe,
    PatientInfoItemComponent,
    PlaceholderLoadingComponent,
    InputAnimationDirective,
    ZovuDateFormatPipe,
    TranslocoRootModule,
    FeatureFlagComponent,
    SafePipe,
    ClickOutsideDirective,
    ZovuIdFormatPipe,
    TooltipDirective,
    ZovuFullDateFormatPipe,
    ZovuGradiantAnglePipe,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class SharedModule {}
