export type FlagKey = keyof { [flagName: string]: IFeatureFlag };
export interface IFeatureFlag {
  id: number;
  activated: boolean;
  name: string;
  description: string;
  epic: string;
  date: Date;
}

export enum FlagSelectAction {
  ADD = 'ADD',
  REMOVE = 'REMOVE',
}
