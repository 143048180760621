import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { debounceTime, distinctUntilChanged, Subject, Subscription } from 'rxjs';

@Component({
  selector: 'ztp-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchBarComponent implements OnInit, OnDestroy {
  private inputSub: Subscription;

  @Input() translation;

  @Output() onFiltersToggle = new EventEmitter<void>();

  @Output() onSearchTermChange = new EventEmitter<string>();

  inputValue = new Subject<string>();

  inputTrigger = this.inputValue.pipe(
    debounceTime(500),
    distinctUntilChanged()
  );

  ngOnInit(): void {
    this.inputSub = this.inputTrigger.subscribe(
      currentValue => this.onSearchTermChange.emit(currentValue)
    );
  }

  public onInputChange(e: any): void {
    this.inputValue.next(e.target.value);
  }

  public toggleFilters(): void {
    this.onFiltersToggle.emit();
  }

  ngOnDestroy(): void {
    this.inputSub?.unsubscribe();
  }
}
