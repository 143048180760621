<div class="advance-search" *transloco="let t; read: 'advance_search'">
  <div [ngClass]="{ 'search-filter': true, 'search-active': !showFilters }">
    <ztp-filter-bar
      class="filter-bar"
      *ngIf="showFilters; else showSearchBar"
      (onFiltersChange)="setFilters($event)"
      [countriesCodeIso2]="africaCountriesCodeIso2"
      [selectedCountryCode]="selectedCountryCodeIso2"
      [departmentsData]="departments"
      [testNamesData]="studyNames"
      #filterBar
    ></ztp-filter-bar>

    <ng-template #showSearchBar>
      <ztp-search-bar
        class="search-bar"
        [translation]="t"
        (onSearchTermChange)="setSearchTerm($event)"
        (onFiltersToggle)="toggleFiltersBar()"
      >
      </ztp-search-bar>
    </ng-template>

    <span
      [ngClass]="{
        'show-unapproved-button': true,
        'show-unapproved-button-active': isShowUnApproved
      }"
    >
      <input
        type="checkbox"
        id="show-unapproved"
        (change)="showUnapproved($event)"
      />
      <label for="show-unapproved" class="custom-checkbox"></label>
      <label for="show-unapproved">{{ t('show_unapproved_box') }}</label>
    </span>
  </div>

  <div *ngIf="showFilters" class="filter-actions">
    <span
      [ngClass]="{
        'button button-clear': true,
        'button-disable': !isFilterActive()
      }"
      (click)="clearFilters()"
    >
      <span>{{ t('clear_button') }}</span>
    </span>
    <span class="button button-cancel" (click)="cancelFilters()">
      <span>{{ t('cancel_button') }}</span>
    </span>
    <span
      [ngClass]="{
        'button button-search': true,
        'button-disable': !isFilterActive() && !fetching
      }"
      (click)="searchByFilters()"
    >
      <span>{{ t('search_button') }}</span>
    </span>
  </div>

  <div
    *ngIf="(isSearchActive() || isFilterActive()) && !fetching"
    class="search-info"
  >
    <span
      class="message"
      [innerHTML]="
        (filterParams.resultMinDate === filterParams.resultMaxDate - 86400000
          ? t('search_result.single', {
              resultCount: searchBannerData?.searchResultsCount,
              startDate:
                filterParams.resultMinDate
                | date
                  : 'longDate'
                  : 'UTC'
                  : (browserLanguage[0] === 'fr' ? 'fr' : 'en')
            })
          : t('search_result.range', {
              resultCount: searchBannerData?.searchResultsCount,
              startDate:
                filterParams.resultMinDate
                | date
                  : 'longDate'
                  : 'UTC'
                  : (browserLanguage[0] === 'fr' ? 'fr' : 'en'),
              endDate:
                filterParams.resultMaxDate - 86400000
                | date
                  : 'longDate'
                  : 'UTC'
                  : (browserLanguage[0] === 'fr' ? 'fr' : 'en')
            })
        ) | safe: 'html'
      "
    ></span>
    <span *ngIf="false" class="button" (click)="fetchMore()">{{
      t('fetch_button')
    }}</span>
  </div>
</div>
