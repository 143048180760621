/* istanbul ignore file */
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { MsalService } from '@azure/msal-angular';
import { Observable, of, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ObservableStore } from '@codewithdan/observable-store';

import { environment } from 'src/environments/environment';
import { AppCoreActions, IStoreState } from '../models/store.interface';
import { ILabCenter, ILabCenterDetails } from '../models/lab-center.interface';
import { Technician } from '../models';

@Injectable({
  providedIn: 'root',
})
export class LabCenterService extends ObservableStore<IStoreState> {
  private url = `${environment.apimBaseUrl}/lab-centers/lab-centers`;
  private headers = new HttpHeaders().set('Ocp-Apim-Subscription-Key', environment.apimSubscriptionKey);

  constructor(private http: HttpClient, private msalService: MsalService) {
    super({ trackStateHistory: true, logStateChanges: false });
  }

  public getTechnicianLabCenter(labTechnicianId: string, labCenterAlias: string): Observable<ILabCenter> {
    return this.http.get<ILabCenter>(`${this.url}/${labTechnicianId}/${labCenterAlias}/details`, { headers: this.headers }).pipe(
      map(labCenterData => labCenterData),
      catchError(this.handleError('getTechnicianLabCenter'))
    );
  }

  public getTechnicianLabCenterDetails(labTechnicianId: string, labCenterAlias: string): Observable<ILabCenterDetails> {
    return this.http.get<ILabCenterDetails>(`${this.url}/${labTechnicianId}/${labCenterAlias}/services`, { headers: this.headers }).pipe(
      map(labCenterDetailsData => labCenterDetailsData),
      catchError(this.handleError('getTechnicianLabCenterDetails'))
    );
  }

  saveCurrentLabCenter(labCenter: ILabCenter): void {
    this.setState({ labCenter }, AppCoreActions.setLabCenter);
  }

  getCurrentLabCenter(): Observable<ILabCenter> {
    const state = this.getState();
    if (state && state.labCenter) {
      return of(state.labCenter);
    } else {
      const activeAccount = this.msalService.instance.getAllAccounts()[0];
      const labTechnician = new Technician(activeAccount);
      return this.getTechnicianLabCenter(labTechnician?.id, labTechnician?.labCenterAlias);
    }
  }

  private handleError(operation: string) {
    return (err: any) => {
      let errMsg = `ERROR in ${operation}() retrieving ${this.url}`;
      console.error(`${errMsg}:`, err);
      if (err instanceof HttpErrorResponse) {
        errMsg = `status: ${err.status}, ${err.statusText}`;
      }
      return throwError(() => new Error(errMsg));
    };
  }
}
