import { monthNames } from '../../utils/constants.utils';
import {
  EMonthType,
  TOperationSummary,
  TWeeklyActivity,
  TWeekStats,
  TWeekStatsPeriod,
} from '../models';

const extractMonthFromSummary = (
  summary: TOperationSummary
): { year: number; month: EMonthType } => ({
  year: Number.parseInt(Object.keys(summary)[0]),
  month: Object.keys(summary[Object.keys(summary)[0]])[0] as EMonthType,
});

const extractWeekPeriods = (weekStats: TWeekStats[]): TWeekStatsPeriod[] =>
  weekStats.map((weekStat) => weekStat.period);

const daysInMonth = (month: EMonthType, year: number): number =>
  new Date(year, monthNames.indexOf(month) + 1, 0).getDate();

const formatCoveredPeriod = (month: EMonthType, year: number): string =>
  `1 - ${daysInMonth(month, year)} ${month}`;

const computeMonthSubmittedResults = (
  weeklyActivity: TWeeklyActivity
): number =>
  Object.values(weeklyActivity)
    .map((activity) =>
      Object.values(activity.stats)
        .map((stats) => stats.totalDailyResults)
        .reduce((acc, value) => acc + value)
    )
    .reduce((acc, value) => acc + value);

export {
  extractMonthFromSummary,
  extractWeekPeriods,
  daysInMonth,
  formatCoveredPeriod,
  computeMonthSubmittedResults,
};
