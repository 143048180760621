<div class="stats-container">
  <div class="progress-bar-container">
    <div
      [ngStyle]="{ width: 0.5 + level + '%', backgroundColor: colors[index] }"
      [id]="'pb' + index"
      class="progress-bar"
      [@keyframes]="{ value: 'keyframes', params: { level: this.level } }"
    ></div>
    <span class="label">{{ label }}</span>
    <span *ngIf="givenName && surname" class="avatar"
      >{{ givenName | slice: 0:1 }}{{ surname | slice: 0:1 }}</span
    >
    <span class="label">{{ givenName }} {{ surname }}</span>
  </div>
  <div class="value-container">
    <span class="quantity"><span>{{ quantity }}</span></span>
    <span
      [id]="'level' + index"
      class="level"
      ><span>{{ level | roundNumber }}</span><span>%</span></span>
  </div>
</div>
