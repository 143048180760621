/* istanbul ignore file */
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import * as printJS from 'print-js';
import { Subscription } from 'rxjs';
import { ResultUnit } from 'src/app/features/result-unit-list/components/result-unit-card/result-unit.interface';
import { ResultUnitPreviewService } from 'src/app/features/result-unit-list/components/result-unit-preview/result-unit-preview.service';
import { TranslocoHttpLoader } from 'src/app/transloco/transloco.service';
import { ModalService } from '../../../../shared/modal/modal.service';

const ZOOM_SCALE = 0.25;
const ZOOM_MAX = 2;
const ZOOM_MIN = 0.5;

@Component({
  selector: 'ztp-file-preview-modal',
  templateUrl: './file-preview-modal.component.html',
  styleUrls: ['./file-preview-modal.component.scss'],
})
export class FilePreviewModalComponent implements OnInit, OnDestroy {
  @Input() fileItem: any;

  @Input() isReport: boolean;

  @Input() isLoading: boolean;

  @Input() labResult: ResultUnit;

  @Input() technicianId: string;

  public fileUrl: string | null = null;

  public fileType!: string;

  name: string;

  pages: number;

  zoom = 0.5;

  modalSubscription: Subscription;

  printText;

  constructor(
    private modalService: ModalService,
    private labResultPreviewService: ResultUnitPreviewService,
    private translocoService: TranslocoHttpLoader,
  ) {}

  ngOnInit(): void {
    this.loadTranslation();
    if (this.isLoading) {
      this.getLabResultReport();
    } else {
      this.formatDocument();
    }
  }

  public changeZoom(increase: boolean): void {
    this.zoom = increase
      ? Math.min(this.zoom + ZOOM_SCALE, ZOOM_MAX)
      : Math.max(this.zoom - ZOOM_SCALE, ZOOM_MIN);
  }

  public getLabResultReport() {
    this.name = `${this.labResult?.submission.category.studyName} - ${this.labResult?.patient.givenName} ${this.labResult?.patient.surname}`;
    this.pages = this.labResult?.submission.media.pagesCount;
    this.fileType = 'application/pdf';

    this.labResultPreviewService
      .getReportMedia(
        this.technicianId,
        this.labResult.submission.media.mediaId,
        this.labResult?.approval?.status
      )
      .subscribe({
        next: (reportMedia: string) => {
          this.fileUrl = `data:application/pdf;base64,${reportMedia}`;
          this.isLoading = false;
        },
        error: (error) => {
        },
      });
  }

  public formatDocument() {
    if (this.isReport) {
      this.fileType = this.fileItem?.fileContentType;
      this.fileUrl = `data:application/pdf;base64,${this.fileItem?.fileItem}`;
      this.name = this.fileItem?.fileName;
      this.pages = this.fileItem?.pagesCount;
    } else {
      this.name = this.fileItem?.file?.name;
      this.pages =
        this.fileItem?.file.type.split('/')[0] === 'image' ? 1 : null;
      const type = 'type';
      this.fileType = this.fileItem?.file?.rawFile[type];
      const reader = new FileReader();
      reader.readAsDataURL(this.fileItem?.file?.rawFile);
      reader.onload = (event) => (this.fileUrl = event.target.result as any);
    }
  }

  public docPagesCounterMessage(n: number) {
    if (String(n).length == 1) {
      return `0${n} page${n > 1 ? 's' : ''}`;
    } else {
      return `${n} pages`;
    }
  }

  public closeModal() {
    const element = document.getElementById('modal');
    element.className = element.className.replace(
      /\bmodal-visible\b/g,
      'modal-hidden'
    );
    this.modalService.destroy();
  }

  public openPrintModalTools(): void {
    const blobUrl = this.convertPdfToBlob(this.fileUrl);
    if (this.isTablet()) {
      const printWindow = window.open(blobUrl, '_blank');
      if (printWindow) {
        printWindow.onload = () => {};
      }
    } else {
      printJS({
        printable: blobUrl,
        type: 'pdf',
        onError: () => {
          this.isLoading = false;
          window.open(blobUrl, '_blank');
        }
      });
    }
  }

  public convertPdfToBlob(fileUrl: string) {
    const byteCharacters = atob(fileUrl.split(',')[1]);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/pdf' });
    const blobUrl = URL.createObjectURL(blob);
    return blobUrl;
  }

  private isTablet(): boolean {
    const userAgent = navigator.userAgent.toLowerCase();
    const isTabletSize = window.innerWidth <= 1024;

    const isMobileDevice = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk)/.test(userAgent);
    return isMobileDevice && isTabletSize;
  }

  private loadTranslation(): void {
    this.translocoService.getTranslation().subscribe((data) => {
      this.printText = data['print_action']['print'];
    });

  }

  ngOnDestroy(): void {
    if (this.modalSubscription) {
      this.modalSubscription.unsubscribe();
    }
  }
}
