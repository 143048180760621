export enum EToastStatus {
  IN_PROGRESS = 'progress',
  COMPlETED = 'completed',
  FAILED = 'failed',
}
export interface IToast {
  status: EToastStatus;
  patient: {
    givenName: string;
    surname: string;
    zovuId: string;
  };
  studyName: string;
  media: {
    type: string;
    pagesCount: number;
  };
}
