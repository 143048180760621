<div class="modal modal-visible patient-verificator" id="modal" #modal class="modal modal-visible" role="dialog"
    *transloco="let t; read: 'patient_verification_modal'" aria-hidden="true">
    <div class="modal-dialog disable-selection">
        <svg-icon key="close-modal" fontSize="12px" (click)="closeModal()"></svg-icon>

        <div class="header">
            <span class="title">{{ t('title') }}</span>
        </div>
        <div class="modalcontent">
            <p class="message">{{ t('message')}}</p>
            <div class="selection">
                <ng-container *ngIf="patientsNameList.length > 0; else showPlaceholder">
                    <div class="patient-list">
                        <div class="patient-item" *ngFor="let patient of patientsNameList">
                            <span class="name" (click)="selectPatient(patient)" (keypress)="selectPatient(patient)">{{
                                patient
                                }}</span>
                        </div>
                    </div>
                </ng-container>
                <ng-template #showPlaceholder>
                    <div class="patient-list">
                        <div class="patient-item" *ngFor="let patient of namePlaceholders">
                            <div class="placeholder">
                                    <div class="placeholder-name">
                                    </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
</div>