import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  ApplicationInsights,
  ITelemetryPlugin,
  SeverityLevel,
} from '@microsoft/applicationinsights-web';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AppInsightsService {
  private instance: ApplicationInsights;

  constructor(private router: Router) {
    const angularPlugin = new AngularPlugin();
    const plugin = angularPlugin as unknown;
    this.instance = new ApplicationInsights({
      config: {
        connectionString: environment.insightConnexionString,
        extensions: [plugin as ITelemetryPlugin],
        enableDebug: true,
        isBrowserLinkTrackingEnabled: true,
        loggingLevelConsole: 2,
        loggingLevelTelemetry: 2,
        autoTrackPageVisitTime: true,
        disableFetchTracking: false,
        disableTelemetry: !this.canEnableTelemetry(),
        extensionConfig: {
          [angularPlugin.identifier]: { router: this.router },
        },
      },
    });
  }

  canEnableTelemetry(): boolean {
    if (typeof window === 'undefined') {
      return false;
    }
    return window.location.hostname !== 'localhost';
  }

  loadAppInsights(): void {
    this.instance.loadAppInsights();
  }

  log(text: string): void {
    this.instance.trackTrace({
      message: text,
      severityLevel: SeverityLevel.Warning,
    });
  }
}
