<div class="form-container" *transloco="let t; read: 'upload_panel'">
  <h3 class="title">{{ label }}</h3>
  <div class="testname-container">
    <ng-select
      #Selecter
      class="testname-select"
      [items]="items"
      [(ngModel)]="selectedItem"
      groupBy="category"
      bindLabel="name"
      bindValue="name"
      [searchable]="true"
      [virtualScroll]="false"
      [placeholder]="placeholder"
      [loadingText]="'Loading...'"
      (change)="selectValue($event)"
      (open)="onOpen($event)"
      (close)="onClose($event)"
    >
    </ng-select>
    <svg-icon
      fontSize="12px"
      icon="chevron-down"
      key="chevron-down"
      class="arrow-icon"
      alt="arrow chevron icon"
      (click)="openSelectBox()"
    >
    </svg-icon>
  </div>
</div>
