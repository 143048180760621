<div class="form-container" *transloco="let t; read:'upload_panel'">
  <div class="flex justify-between items-center">
    <div>
      <h3 class="title">{{t('test_name_header')}}</h3>
    </div>
    <div class="flex justify-between items-center sensitive-Badge-Container">
      <div *ngIf="protected" class="sensitive-Badge">{{ 'result_body.sensitive_badge' | transloco }}</div>
    </div>
  </div>
  <div class="testname-container">
    <ng-select
          #Selecter
          class="testname-select"
          [loading]="loading"
          [searchFn]="customSearch"
          [items]="studyNames"
          [(ngModel)]="selectedStudyName"
          groupBy="category"
          bindLabel="name"
          bindValue="name"
          [searchable]="true"
          [virtualScroll]="false"
          [placeholder]="t('test_name_search_box_message')"
          [loadingText]="'Loading...'"
          (search)="onSearch($event)"
          (change)="selectStudyName($event)"
          (open)="onOpen($event)"
          (close)="onClose($event)"
        >
        </ng-select>
          <svg-icon fontSize="12px" icon='chevron-down' key="chevron-down" (click)="openSelectBox()" class="arrow-icon"
            alt="arrow chevron icon">
          </svg-icon>
  </div>
</div>
