export default [
  'Abena',
  'Abioye',
  'Abla',
  'Acheampong',
  'Addo',
  'Adewale',
  'Adeyemi',
  'Adomako',
  'Adwoa',
  'Afi',
  'Afia',
  'Afriyie',
  'Afua',
  'Agbemadon',
  'Ahoefa',
  'Akofa',
  'Akosua',
  'Akwasi',
  'Alabi',
  'Alima',
  'Ama',
  'Amadi',
  'Amavi',
  'Amédjro',
  'Amouzou',
  'Anansi',
  'Apedo',
  'Apindangoye',
  'Asamoah',
  'Assefa',
  'Aziagbe',
  'Badagri',
  'Bah',
  'Balogun',
  'Bamba',
  'Banda',
  'Bangali',
  'Bari',
  'Bassirou',
  'Batada',
  'Belhaj',
  'Bello',
  'Bemba',
  'Benissan',
  'Bintou',
  'Blu',
  'Bodjona',
  'Bolouvi',
  'Boukari',
  'Café-Shina',
  'Camara-laye',
  'Camberene',
  'Chanda',
  'Chijioke',
  'Chikere',
  'Chiwenite',
  'Chizyuka',
  'Chongo',
  'Cikou',
  'Cissoko',
  'Comlan',
  'Coudjo',
  'Coulibaly',
  'Coumare',
  'Da costa',
  'Dabla',
  'Damoin',
  'Dangbo',
  'Dembele',
  'Diabate',
  'Diop',
  'Djenabou',
  'Djogbedon',
  'Dlamini',
  'Dlodlo',
  'Dogbe',
  'Doh',
  'Doherty',
  'Dovi',
  'Dube',
  'Dzifa',
  'Edem',
  'Efua',
  'Egua',
  'Eho',
  'Ekong',
  'Ekoume',
  'Ekpo',
  'Ekwealor',
  'Ekwensi',
  'Elamine',
  'Elom',
  'Enwereji',
  'Eombi',
  'Epou',
  'Ero',
  'Esi',
  'Essafa',
  'Etchri',
  'Eze',
  'Fadika',
  'Fagnon',
  'Fatou',
  'Faye',
  'Fehou',
  'Femi',
  'Feynn',
  'Fiankou',
  'Fidegnon',
  'Fieni',
  'Fienso',
  'Fiifi',
  'Fofanah',
  'Fofie',
  'Folly',
  'Folorunso',
  'Fomba',
  'Gachanja',
  'Gakuru',
  'Gashaw',
  'Gbedemah',
  'Gbogbo',
  'Gbongli',
  'Gidisu',
  'Gitari',
  'Githinji',
  'Githuka',
  'Gnassingbe',
  'Gondo',
  'Guevara',
  'Gueye',
  'Gwanbe',
  'Habimana',
  'Hadeel',
  'Hamandishe',
  'Henna',
  'Hilawe',
  'Hilonga',
  'Hlatshwayo',
  'Hlongwane',
  'Hodji',
  'Hodonou',
  'Houdjago',
  'Houedekin',
  'Hounkpati',
  'Hountondji',
  'Howa',
  'Ibaka',
  'Ibeziako',
  'Ibezim',
  'Idohou',
  'Idrissa',
  'Igwe',
  'Ihougan',
  'Ilonga',
  'Inoussa',
  'Inoussa',
  'Isiaka',
  'Issa',
  'Issifou',
  'Iwobi',
  'Iyama',
  'Iyoriobhe',
  'Izundu',
  'Jabari',
  'Jagana',
  'Jaiteh',
  'Jalloh',
  'Jamil',
  'Jarju',
  'Jawo',
  'Jebeh',
  'Jimoh',
  'Johnson',
  'Jomatin',
  'Jondo',
  'Joof',
  'Jossou',
  'Jumaane',
  'Kadhafi',
  'Kafui',
  'Kamara',
  'Kigongo',
  'Kodjo',
  'Kofi',
  'Kojo',
  'Kojo',
  'Kokou',
  'Komi',
  'Konate',
  'kondo',
  'Kone',
  'Kossi',
  'Kouassi',
  'Koudou',
  'Koudouvor',
  'Kourouma',
  'Kouton',
  'Kpatcha',
  'Kpatinvo',
  'Kpohinto',
  'Kwabena',
  'Kwame',
  'Laba',
  'Lagona',
  'Lakota',
  'Laleye',
  'Lamidi',
  'Lamine',
  'Lankouande',
  'Lartey',
  'Lary',
  'Lassina',
  'Lassissi',
  'Lawani',
  'Loko',
  'Lumumba',
  'Lusengo',
  'Lwazi',
  'Mabaso',
  'Mabuza',
  'Mada',
  'Mahama',
  'Mandela',
  'Maouna',
  'Mariam',
  'Mate',
  'Mawuko',
  'Mawuli',
  'Mazinga',
  'Mbeki',
  'Mensah',
  'Mensah',
  'Monthe',
  'Monwanou',
  'Moumouni',
  'Mouniri',
  'Mugabe',
  'Mwale',
  'N\'guessan',
  'Nana',
  'Napo',
  'Nayo',
  'Najaro',
  'Ndaba',
  'Ndiaye',
  'Ndung\'u',
  'Ngugi',
  'Nkemdiche',
  'Nkosi',
  'Nomenyo',
  'Nougué',
  'Nouhoum',
  'Ntuli',
  'Nwachukwu',
  'Nyamekye',
  'Nyansa',
  'Obafemi',
  'Odinga',
  'Odjo',
  'Ofori',
  'Ogou',
  'Okeke',
  'Okpara',
  'Oladipo',
  'Olou',
  'Osei',
  'Ossei',
  'Osseni',
  'Ouattara',
  'Oumarou',
  'Ouro',
  'Oyewole',
  'Padonou',
  'Paka',
  'Pavi',
  'Pembe',
  'Phiri',
  'Pinda',
  'Pinto',
  'Pitala',
  'Pitang',
  'Pitassa',
  'Pito',
  'Pitroipa',
  'Pomeni',
  'Ponde',
  'Pousga',
  'Qaddaf',
  'Qadi',
  'Qamata',
  'Qashane',
  'Quansah',
  'Quao',
  'Quashie',
  'Quashigah',
  'Quattara',
  'Quaye',
  'Quenu',
  'Quenum',
  'Quist',
  'Quiterio',
  'Qwabe',
  'Radebe',
  'Radji',
  'Radjou',
  'Raimi',
  'Ramaphosa',
  'Rangwala',
  'Raouf',
  'Rasheed',
  'Rassoul',
  'Robini',
  'Rouamba',
  'Roukou',
  'Rukewe',
  'Ruto',
  'Rwigema',
  'Salif',
  'Samba',
  'Sane',
  'Sangaré',
  'Sanogo',
  'Sanvi',
  'Sarr',
  'Saa',
  'Samadogo',
  'Sawadogo',
  'Seck',
  'Segbedzi',
  'Sena',
  'Sidibé',
  'Sissoko',
  'Sogbodjor',
  'Souleymane',
  'Soumah',
  'Sowou',
  'Tambo',
  'Tandia',
  'Tariq',
  'Tchagouni',
  'Tchakou',
  'Tchiakpe',
  'Tchidjania',
  'Tchalla',
  'Tchassona',
  'Tchegnon',
  'Tchoubé',
  'Tekle',
  'Tengue',
  'Tetteh',
  'Togbui',
  'Tokplé',
  'Traoré',
  'Tshabalala',
  'Uche',
  'Udofia',
  'Udofia',
  'Udom',
  'Ugbaja',
  'Ugwu',
  'Ukaegbu',
  'Ukelele',
  'Umaru',
  'Usman',
  'Uwaifo',
  'Uwem',
  'Uyi',
  'Uzemere',
  'Uzoma',
  'Vamey',
  'Venda',
  'Vewonyi',
  'Vidzro',
  'Vignon',
  'Vingara',
  'Vinko',
  'Vinoko',
  'Vissokou',
  'Vitolowo',
  'Vivide',
  'Vlavonou',
  'Vodounou',
  'Vonvonli',
  'Vovor',
  'Wagaki',
  'Wagongo',
  'Wassi',
  'Waweru',
  'Wedah',
  'Wégah',
  'Wèzor',
  'Wofa',
  'Wole',
  'Woli',
  'Wossénou',
  'Wossou',
  'Wotsouglo',
  'Woungbou',
  'Wudi',
  'Xaba',
  'Xakou',
  'Xanamane',
  'Xebio',
  'Xeyi',
  'Xhosa',
  'Xikwembu',
  'Ximba',
  'Xolani',
  'Xolo',
  'Xongo',
  'Xossi',
  'Xubair',
  'Xulu',
  'Xuma',
  'Yacoubou',
  'Yao',
  'Yaovi',
  'Yatouba',
  'Yaw',
  'Yaya',
  'Yebi',
  'Yéha',
  'Yendoube',
  'Yenga',
  'Yenyo',
  'Yevugah',
  'Yodo',
  'Yomba',
  'Yomsa',
  'Yovodévi',
  'Yovogan',
  'Zalika',
  'Zankli',
  'Zantchi',
  'Zebaze',
  'Zewde',
  'Zewdu',
  'Zidah',
  'Zikora',
  'Zikpi',
  'Zinzou',
  'Zogo',
  'Zoume',
  'Zoume',
  'Zoundji',
  'Zuma',
];
