import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SseService {

  private evtSources: Map<string, EventSource> = new Map();
  private MAX_EVENT_SOURCES = 5;

  public getEventSource(url: string): EventSource {
    if (!this.evtSources.has(url)) {
      if (this.evtSources.size >= this.MAX_EVENT_SOURCES) {
        throw new Error('Maximum number of event sources reached');
      }
      this.evtSources.set(url, new EventSource(url));
    }
    return this.evtSources.get(url);
  }

  public disconnectEventSource(url): void {
    if (this.evtSources.has(url)) {
      this.closeEventSource(this.evtSources.get(url));
      this.evtSources.delete(url);
    }
  }

  public disconnectAllEventSources(): void {
    this.evtSources.forEach((evt) => {
      this.closeEventSource(evt);
    });
    this.evtSources.clear();
  }

  private closeEventSource(evt: EventSource): void {
    if (evt.readyState !== 2) {
      evt.close();
    }
  }
}
