<div class="operation-summary">
  <div class="header">
    <div class="label placeholder"></div>
    <div class="count placeholder"></div>
    <div class="label placeholder"></div>
  </div>
  <div class="divider"></div>
  <div class="submission-statistics">
    <div class="submission-statistics-1 placeholder"></div>
    <div class="submission-statistics-2 placeholder"></div>
    <div class="submission-statistics-3 placeholder"></div>
  </div>
</div>
