import { Component, Input, Output, EventEmitter } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { SvgIconsModule } from '@ngneat/svg-icon';
import { TWeekStatsPeriod } from '../../operation-summary/models';
import { WeekPeriodPipe } from '../../operation-summary/pipes/week-period.pipe';

type theme = 'dark' | 'light';
interface ISTYLE {
  color: '#0c586c' | '#fff';
}

@Component({
  standalone: true,
  imports: [SvgIconsModule, BrowserModule, WeekPeriodPipe],
  selector: 'ztp-period-selector',
  templateUrl: './period-selector.component.html',
  styleUrls: ['./period-selector.component.scss'],
})
export class PeriodSelectorComponent {
  @Input() theme: theme;

  @Input() weeks: TWeekStatsPeriod[];

  @Output() selectWeekEvent = new EventEmitter<number>();

  style: Record<theme, ISTYLE> = {
    dark: {
      color: '#0c586c',
    },
    light: {
      color: '#fff',
    },
  };

  selectedWeekIndex = 0;

  selectWeek(week: number): void {
    this.selectedWeekIndex = week;
    this.selectWeekEvent.emit(week);
  }
}
