import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';
import { ITechnician } from 'src/app/core/models';
import { environment } from 'src/environments/environment';
import { monthNames } from '../../utils/constants.utils';
import { TReportArchive, TReportCard } from '../models';

@Injectable({
  providedIn: 'root',
})
export class ReportArchiveService {
  private url = `${environment.apimBaseUrl}/dashboard`;
  private headers = new HttpHeaders().set(
    'Ocp-Apim-Subscription-Key',
    environment.apimSubscriptionKey
  );

  constructor(private readonly http: HttpClient) {}

  public getReportArchives(
    labTechnician: ITechnician
  ): Observable<TReportArchive> {
    return this.http
      .get<TReportArchive>(
      `${this.url}/report-archives/${labTechnician.id}/${labTechnician.labCenterAlias}`,
      { headers: this.headers }
    )
      .pipe(
        map((result) => result),
        catchError(this.handleError('fetchReportArchives', this.url))
      );
  }

  public downloadReportPdf(
    labTechnician: ITechnician,
    report: TReportCard
  ): Observable<string> {
    return this.http
      .get<string>(
      `${this.url}/blob-container/${
        labTechnician.labCenterAlias
      }?year=${new Date().getFullYear()}&monthIndex=${monthNames.findIndex(
        (month) => month === report[0]
      )}`,
      { headers: this.headers, responseType: 'text' as 'json' }
    )
      .pipe(
        map((result: string) => result),
        catchError(this.handleError('downloadReportPdf', this.url))
      );
  }

  private handleError(operation: string, url: string) {
    return (err: any) => {
      let errMsg = `ERROR in ${operation}() retrieving ${url}`;
      console.error(`${errMsg}:`, err);
      if (err instanceof HttpErrorResponse) {
        errMsg = `status: ${err.status}, ${err.statusText}`;
      }
      return throwError(() => new Error(errMsg));
    };
  }
}
