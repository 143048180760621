import { BehaviorSubject, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { ModalDomService } from './modal.dom.service';

@Injectable({
  providedIn: 'root',
})
export class ModalService {

  private readonly modalActionsSubject = new BehaviorSubject<any>(null);
  private readonly patientVerificatorSubject = new Subject<any>();

  public modalActions = this.modalActionsSubject.asObservable();
  public patientVerificator = this.patientVerificatorSubject.asObservable();

  constructor(private readonly modalDomService: ModalDomService) { }

  init(component: any, inputs: object, outputs?: object, callback?: any) {
    const componentConfig = { inputs, outputs, callback };
    this.modalDomService.appendComponentToBody(component, componentConfig);
  }

  destroy() {
    this.modalDomService.removeComponent();
  }

  emitAction(value: any) {
    this.modalActionsSubject.next(value);
  }

  emitPatientVerificationAction(value: any) {
    this.patientVerificatorSubject.next(value);
  }
}

