/* eslint-disable @angular-eslint/component-selector */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FeatureFlagsService } from 'src/app/feature-flags-page/feature-flags.service';
import { IFeatureFlag, FlagSelectAction } from './feature-flag.interface';

@Component({
  selector: 'ztw-feature-flag',
  templateUrl: './feature-flag.component.html',
  styleUrls: ['./feature-flag.component.scss'],
})
export class FeatureFlagComponent implements OnInit {
  @Input() feature: IFeatureFlag;

  @Input() selected: boolean;

  @Output() selectedEvent = new EventEmitter<{
    feature: IFeatureFlag;
    payload: FlagSelectAction;
  }>();

  constructor(private featureFlagService: FeatureFlagsService) {}

  ngOnInit(): void {}

  markAsSelected(): void {
    this.selected = !this.selected;
    this.selectedEvent.emit({
      feature: this.feature,
      payload: this.selected ? FlagSelectAction.ADD : FlagSelectAction.REMOVE,
    });
  }

  performSwitchAction(): void {
    this.feature.activated = !this.feature.activated;
    this.featureFlagService
      .updateFeatureFlag(this.feature);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    // .subscribe((data) => {});
  }
}
