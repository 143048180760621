import { Component, Output, EventEmitter, Input, ViewEncapsulation } from '@angular/core';

import { IToast } from './toast-tile.interface';

@Component({
  selector: 'ztp-toast-tile',
  templateUrl: './toast-tile.component.html',
  styleUrls: ['./toast-tile.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ToastTileComponent {
  @Input() toastData: IToast;

  @Output() disposeEvent = new EventEmitter();

  timeoutValue = 3000;

  timeoutId: any;

  constructor() {
    this.registerTimer();
  }

  registerTimer(): void {
    this.timeoutId = setTimeout(() => {
      this.close();
    }, this.timeoutValue);
  }

  close(): void {
    this.disposeEvent.emit();
    clearTimeout(this.timeoutId);
  }
}
