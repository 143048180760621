/* istanbul ignore file */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getBrowserLang, Translation, TranslocoLoader } from '@ngneat/transloco';

@Injectable({
  providedIn: 'root',
})
export class TranslocoHttpLoader implements TranslocoLoader {
  constructor(private http: HttpClient) {
  }

  getTranslation(lang?: string) {
    lang = getBrowserLang();
    return this.http.get<Translation>(`/assets/i18n/${lang}.json`);
  }
}

