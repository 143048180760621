import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() {}

  /**
   * Save an item to localStorage
   * @param key The key to identify the item
   * @param value The value to store (automatically serialized if it's an object)
   */
  setItem(key: string, value: any): void {
    const serializedValue = JSON.stringify(value);
    localStorage.setItem(key, serializedValue);
  }

  /**
   * Retrieve an item from localStorage
   * @param key The key of the item to retrieve
   * @returns The parsed object if the item is valid JSON, or the raw string
   */
  getItem<T>(key: string): T | null {
    const item = localStorage.getItem(key);
    try {
      return item ? JSON.parse(item) as T : null;
    } catch (e) {
      console.warn(`Could not parse item with key '${key}' from localStorage`, e);
      return null;
    }
  }

  /**
   * Remove an item from localStorage
   * @param key The key of the item to remove
   */
  removeItem(key: string): void {
    localStorage.removeItem(key);
  }

  /**
   * Clear all data from localStorage
   */
  clear(): void {
    localStorage.clear();
  }

  /**
   * Check if an item exists in localStorage
   * @param key The key of the item to check
   * @returns True if the item exists, false otherwise
   */
  exists(key: string): boolean {
    return localStorage.getItem(key) !== null;
  }
}
