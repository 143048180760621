import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslocoModule } from '@ngneat/transloco';
import { NgChartsModule, NgChartsConfiguration } from 'ng2-charts';
import { PeriodSelectorComponent } from '../components/period-selector/period-selector.component';

import { CountSummaryComponent } from './components/count-summary/count-summary.component';
import { OperationSummaryComponent } from './components/operation-summary/operation-summary.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { SubmissionStatisticsComponent } from './components/submission-statistics/submission-statistics.component';
import { RoundNumberPipe } from './pipes/round-number.pipe';
import { CountSummaryCardPlaceholderComponent } from './components/count-summary-card-placeholder/count-summary-card-placeholder.component';
import { SubmissionStatisticsCardPlaceholderComponent } from './components/submission-statistics-card-placeholder/submission-statistics-card-placeholder';
import { SvgIconsModule } from '@ngneat/svg-icon';

@NgModule({
  imports: [
    TranslocoModule,
    NgChartsModule,
    BrowserModule,
    BrowserAnimationsModule,
    PeriodSelectorComponent,
    SvgIconsModule,
  ],
  declarations: [
    CountSummaryComponent,
    SubmissionStatisticsComponent,
    OperationSummaryComponent,
    ProgressBarComponent,
    RoundNumberPipe,
    CountSummaryCardPlaceholderComponent,
    SubmissionStatisticsCardPlaceholderComponent,
  ],
  providers: [
    { provide: NgChartsConfiguration, useValue: { generateColors: false } },
  ],
  exports: [
    CountSummaryComponent,
    SubmissionStatisticsComponent,
    OperationSummaryComponent,
    ProgressBarComponent,
    CountSummaryCardPlaceholderComponent,
    SubmissionStatisticsCardPlaceholderComponent,
  ],
})
export class OperationSummaryModule {}
