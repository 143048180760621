/* istanbul ignore file */
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ztp-comment-card',
  templateUrl: './comment-card.component.html',
  styleUrls: ['./comment-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommentCardComponent implements OnInit {
  @Input() commentControl;

  @Output() onCommentFocus = new EventEmitter<boolean>();

  ngOnInit(): void {
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public onBoxFocus(_e: any): void {
    this.onCommentFocus?.emit(true);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public onBoxFocusOut(_e: any): void {
    this.onCommentFocus?.emit(false);
  }


}
