/* istanbul ignore file */
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import {
  MsalGuardConfiguration,
  MsalService,
  MSAL_GUARD_CONFIG,
} from '@azure/msal-angular';
import { RedirectRequest } from '@azure/msal-browser';

import { environment } from 'src/environments/environment';

@Component({
  selector: 'ztp-sign-in-page',
  templateUrl: './sign-in-page.component.html',
  styleUrls: ['./sign-in-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignInPageComponent {
  loading = false;
  loggedIn = true;

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalService: MsalService
  ) {}

  login() {
    this.loading = true;
    const authRequest = { ...this.msalGuardConfig.authRequest };
    sessionStorage.removeItem('msal.interaction.status');
    this.msalService.loginRedirect({
      redirectStartPage: environment.redirectUri,
      ...authRequest,
    } as RedirectRequest);
  }
}
