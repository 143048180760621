import { Pipe, PipeTransform } from '@angular/core';
import { format } from 'date-fns';

@Pipe({
  name: 'dateFns',
})
export class DateFnsPipe implements PipeTransform {

  transform(value: Date | number, ...args: any[]): string {
    if (!value) {
      return '';
    }
    const date = value.toString().replace(/-/g, '/');
    return format(new Date(date), args[0] ?? 'dd/MM/yyyy');
  }

}
