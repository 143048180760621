<form
  [formGroup]="submissionForm"
  class="submission-page"
  *transloco="let t; read: 'upload_panel'"
>
  <ztp-patient-card
    class="patient-card-container"
    [patient]="currentPatient"
  ></ztp-patient-card>
  <div class="result-unit-card">
    <div class="edit-info" *ngIf="this.submissionMode == 'edit'">
      <div class="icon-container">
        <svg-icon class="info-icon" fontSize="21px" key="edit-info"></svg-icon>
      </div>
      <div class="edit-info-container">
        <span class="info-title">{{
          'edit_page.edit_mode_banner.title' | transloco
        }}</span>
        <span class="info-message">
          {{ 'edit_page.edit_mode_banner.message' | transloco }}
        </span>
      </div>
    </div>
    <div class="study-report-pannel">
      <div class="study-pannel">
        <ztp-study-name-selector
          class="test-selection"
          (onStudyNameChange)="setTestName($event)"
          (onBoxOpen)="setStudyListBoxOpenStatus($event)"
          [studyName]="labTestName"
        >
        </ztp-study-name-selector>
      </div>
      <div class="report-pannel">
        <ztw-upload-card
          [canEditLabResult]="
            submissionMode == 'edit' ? canEditLabResult : true
          "
          [uploadControl]="uploadReport"
          (sendFileQueueInvalid)="setReportValidity($event)"
          (onFileCompressing)="setFileCompressStatus($event)"
          (onBoxOpen)="setFileBoxOpenStatus($event)"
          (sendFileQueue)="saveFileQueue($event)"
          (sendFile)="saveReport($event)"
        >
        </ztw-upload-card>
      </div>
      <div
        class="warning"
        *ngIf="this.submissionMode == 'edit' && !this.isResultUnitReportDeleted"
      >
        <svg-icon fontSize="21px" class="warning-icon" key="warning"></svg-icon>
        <div class="warning-message-container">
          <span class="warning-message"
            >{{ 'edit_page.warning_mode_banner.message' | transloco }}<br />
            {{ 'edit_page.warning_mode_banner.warning' | transloco }}
          </span>
        </div>
        <button (click)="showDeletionConfirmationModal()">
          {{ 'edit_page.warning_mode_banner.delete_button' | transloco }}
        </button>
      </div>
    </div>

    <div class="study-report-pannel">
      <div class="study-pannel">
        <ztp-auto-complete
          [label]="t('prescriber')"
          [placeholder]="t('prescriber_placeholder')"
          [items]="providersList | async"
          class="test-selection"
          (onValueChange)="setPrescriber($event)"
          (onBoxOpen)="setPrescriberBoxOpenStatus($event)"
          [item]="prescriber"
        >
        </ztp-auto-complete>
      </div>
      <div class="study-pannel">
        <ztp-auto-complete
          [label]="t('assigned_clinic')"
          [placeholder]="t('assigned_clinic_placeholder')"
          [items]="clinicsList | async"
          class="test-selection"
          (onValueChange)="setClinic($event)"
          (onBoxOpen)="setClinicBoxOpenStatus($event)"
          [item]="clinic"
        >
        </ztp-auto-complete>
      </div>
    </div>

    <div class="comment-pannel">
      <ztp-comment-card
        [commentControl]="comments"
        (onCommentFocus)="setCommentEditStatus($event)"
      ></ztp-comment-card>
    </div>
    <button
      type="button"
      class="btn-desktop"
      [disabled]="
        labTestName.invalid ||
        fileUploader.queue.length == 0 ||
        isReportFilesInvalid ||
        isSubmiting ||
        isFilesCompressInProgress
      "
      (click)="submitModal()"
      *ngIf="this.submissionMode !== 'edit'; else editModeActions"
    >
      {{ t('submit_button') }}
    </button>
    <ng-template #editModeActions>
      <div class="edit-mode-btn-container btn-desktop">
        <button class="outline" (click)="cancelModal()">
          {{ 'edit_page.actions_buttons.cancel_button' | transloco }}
        </button>
        <button
          [disabled]="
            labTestName.invalid ||
            isReportFilesInvalid ||
            !submissionForm.dirty ||
            isSubmiting ||
            isFilesCompressInProgress ||
            (isResultUnitReportDeleted && !currentSubmissionReport)
          "
          (click)="submitModal()"
        >
          {{ 'edit_page.actions_buttons.save_button' | transloco }}
        </button>
      </div>
    </ng-template>
  </div>
  <button
    class="btn-tablet"
    type="button"
    *ngIf="this.submissionMode !== 'edit'; else editModeActionsTablet"
    [disabled]="
      labTestName.invalid ||
      fileUploader.queue.length == 0 ||
      isReportFilesInvalid ||
      isSubmiting ||
      isFilesCompressInProgress
    "
    (click)="submitModal()"
  >
    {{ t('submit_button') }}
  </button>
  <ng-template #editModeActionsTablet>
    <div class="edit-mode-btn-container-tab">
      <button class="outline" (click)="cancelModal()">
        {{ 'edit_page.actions_buttons.cancel_button' | transloco }}
      </button>
      <button
        [disabled]="
          labTestName.invalid ||
          isReportFilesInvalid ||
          !submissionForm.dirty ||
          isSubmiting ||
          isFilesCompressInProgress ||
          (isResultUnitReportDeleted && !currentSubmissionReport)
        "
        (click)="submitModal()"
      >
        {{ 'edit_page.actions_buttons.save_button' | transloco }}
      </button>
    </div>
  </ng-template>
  <div class="preview-card">
    <div class="header">
      <h3 class="title">{{ t('preview_header') }}</h3>
    </div>
    <div class="body">
      <div class="name">
        <h3 class="title">{{ t('test_name_title') }}</h3>
        <span
          *ngIf="
            dataLiveLoadingCheck.labTestName.hasData &&
              !dataLiveLoadingCheck.labTestName.hasFocus;
            else hasNoLabTestName
          "
          class="value"
        >
          {{ labTestName?.value }}
        </span>
        <ng-template #hasNoLabTestName>
          <div
            [ngClass]="{
              placeholder: true,
              'placeholder-active': dataLiveLoadingCheck.labTestName.hasFocus
            }"
          >
            <span class="content"></span>
          </div>
        </ng-template>
      </div>
      <div class="reports">
        <h3 class="title">{{ t('upload_header') }}</h3>
        <div
          class="value"
          *ngIf="fileUploader?.queue?.length > 0; else hasNoReport"
        >
          <div class="cover">
            <svg-icon key="pdf" fontSize="55px"></svg-icon>
          </div>
          <div
            class="details"
            *ngIf="
              !isReportFilesInvalid && !isFilesCompressInProgress;
              else hasReportInvalidOrCompressing
            "
          >
            <span class="name">{{ labTestName?.value }}</span>
            <span class="pages">{{
              reportPagesCounterMessage(currentSubmissionReport?.pagesCount)
            }}</span>
            <span
              (click)="openPreview(currentSubmissionReport)"
              class="button"
              >{{
                'result_list.result_summary_pannel.preview_report' | transloco
              }}</span
            >
          </div>
          <ng-template #hasReportInvalidOrCompressing>
            <div class="details details-loading">
              <div class="placeholder placeholder-active">
                <span class="content"></span>
              </div>
              <div class="placeholder placeholder-active">
                <span class="content"></span>
              </div>
            </div>
          </ng-template>
        </div>
        <ng-template #hasNoReport>
          <div
            [ngClass]="{
              placeholder: true,
              'placeholder-active': dataLiveLoadingCheck.uploadReport.hasFocus
            }"
          >
            <span class="content"></span>
          </div>
        </ng-template>
      </div>
    
      <!-- NEW -->
      <div class="prescriber">
        <h3 class="title">{{ t('prescriber') }}</h3>
        <span
          *ngIf="
            dataLiveLoadingCheck.prescriber.hasData &&
              !dataLiveLoadingCheck.prescriber.hasFocus;
            else hasNoPrescriber
          "
          class="value"
        >
          {{ prescriber?.value }}
        </span>
        <ng-template #hasNoPrescriber>
          <div
            [ngClass]="{
              placeholder: true,
              'placeholder-active': dataLiveLoadingCheck.prescriber.hasFocus
            }"
          >
            <span class="content"></span>
          </div>
        </ng-template>
      </div>
      <div class="clinic">
        <h3 class="title">{{ t('assigned_clinic') }}</h3>
        <span
          *ngIf="
            dataLiveLoadingCheck.clinic.hasData &&
              !dataLiveLoadingCheck.clinic.hasFocus;
            else hasNoClinic
          "
          class="value"
        >
          {{ clinic?.value }}
        </span>
        <ng-template #hasNoClinic>
          <div
            [ngClass]="{
              placeholder: true,
              'placeholder-active': dataLiveLoadingCheck.clinic.hasFocus
            }"
          >
            <span class="content"></span>
          </div>
        </ng-template>
      </div>
      <div class="comments">
        <h3 class="title">{{ t('comments_header') }}</h3>
        <p
          class="value"
          *ngIf="dataLiveLoadingCheck.comments.hasData; else hasNoComment"
        >
          {{ comments?.value }}
        </p>
        <ng-template #hasNoComment>
          <div
            [ngClass]="{
              placeholder: true,
              'placeholder-active': dataLiveLoadingCheck.comments.hasFocus
            }"
          >
            <span class="content"></span>
          </div>
          <div
            [ngClass]="{
              placeholder: true,
              'placeholder-active': dataLiveLoadingCheck.comments.hasFocus
            }"
          >
            <span class="content"></span>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</form>

<!-- <div *ngIf="isSubmiting" class="submission-spinner">
  <div class="spinner"></div>
</div> -->
