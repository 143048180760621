import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { SvgIconsModule } from '@ngneat/svg-icon';
import { ReportArchivesComponent } from './components/report-archives/report-archives.component';

import { ReportCardComponent } from './components/report-card/report-card.component';
import { TranslocoModule } from '@ngneat/transloco';
import { MonthDaysPipe } from './pipes/month-days.pipe';
import { ReportArchiveCardPlaceholderComponent } from './components/report-archives-card/report-archive-card-placeholder.component';

@NgModule({
  imports: [TranslocoModule, CommonModule, BrowserModule, SvgIconsModule],
  declarations: [
    ReportCardComponent,
    ReportArchivesComponent,
    MonthDaysPipe,
    ReportArchiveCardPlaceholderComponent,
  ],
  exports: [
    ReportCardComponent,
    ReportArchivesComponent,
    ReportArchiveCardPlaceholderComponent,
  ],
})
export class ReportArchiveModule {}
