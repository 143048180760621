/* istanbul ignore file */
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
  selector: 'ztp-result-unit-card-placeholder',
  templateUrl: './result-unit-card-placeholder.component.html',
  styleUrls: ['./result-unit-card-placeholder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResultUnitCardPlaceholderComponent implements OnInit {
  ngOnInit(): void { }
}
