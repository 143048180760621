<div class="zv-drawer" [ngClass]="{'hide': hidden}">
    <div class="drawer-head">
        <div class="title">
            <h2>Notifications</h2>
        </div>
        <div class="close-icon" (click)="onClose()">
            <svg-icon key="close-notif-panel" fontSize="18px"></svg-icon>
        </div>
    </div>
    <div class="drawer-content">
        <ztp-notification-tile *ngFor="let labSub of labSubmissions" [submission]="labSub"
            (onSubmissionEvent)="handleNotificationEvent($event)"></ztp-notification-tile>
    </div>
</div>