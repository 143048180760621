<div class="live-chart-container">
  <div class="period-selector-container">
    <ztp-period-selector
      *ngIf="showPeriodSelector"
      [theme]="'light'"
      [weeks]="data"
      (selectWeekEvent)="constructChart($event)"
    ></ztp-period-selector>
  </div>
  <div class="chart-container">
    <canvas baseChart class="chart" [data]="barChartData" [options]="overrides">
    </canvas>
  </div>
</div>
