import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  TimeagoClock,
  TimeagoCustomFormatter,
  TimeagoDefaultClock,
  TimeagoFormatter,
  TimeagoIntl,
  TimeagoModule,
} from 'ngx-timeago';

import { SvgIconsModule } from '@ngneat/svg-icon';
import { SharedModule } from '../shared/shared.module';

import { appBellIcon } from '../svg/zovu/bell';
import { appChevronDownIcon } from '../svg/zovu/chevron-down';
import { appUploadIcon } from '../svg/zovu/upload';
import { appHomeIcon } from '../svg/zovu/home';
import { appExitIcon } from '../svg/zovu/exit';
import { appHelpCircleIcon } from '../svg/zovu/help-circle';

import { NotificationDrawerService } from './components/notification-drawer/notification-drawer.service';
import { ToasterService } from './components/toaster/toaster.service';

import {
  HeaderComponent,
  NotificationTileComponent,
  NotificationDrawerComponent,
  ToasterComponent,
  ToastTileComponent,
  EnvBadgeComponent,
} from './components';

import { appFailedNotifIcon } from '../svg/zovu/failed-notif';
import { appDeleteFailedNotifIcon } from '../svg/zovu/delete-failed-notif';
import { appSuccessNotifIcon } from '../svg/zovu/success-notif';
import { UserIdleModule } from 'angular-user-idle';
import { AblyService } from './services/ably.service';
import { SseService } from './services/sse.service';
import { LocalStorageService } from './services';

@NgModule({
  imports: [
    SharedModule,
    SvgIconsModule.forChild([
      appBellIcon,
      appChevronDownIcon,
      appExitIcon,
      appHelpCircleIcon,
      appHomeIcon,
      appUploadIcon,
      appFailedNotifIcon,
      appDeleteFailedNotifIcon,
      appSuccessNotifIcon,
    ]),
    RouterModule,
    CommonModule,
    TimeagoModule.forChild({
      formatter: {
        provide: TimeagoFormatter,
        useClass: TimeagoCustomFormatter,
      },
      clock: { provide: TimeagoClock, useClass: TimeagoDefaultClock },
      intl: { provide: TimeagoIntl, useClass: TimeagoIntl },
    }),
    UserIdleModule.forRoot({ idle: 900, timeout: 120, ping: 100 }),
  ],
  declarations: [
    HeaderComponent,
    NotificationDrawerComponent,
    NotificationTileComponent,
    ToasterComponent,
    ToastTileComponent,
    EnvBadgeComponent,
  ],
  exports: [
    HeaderComponent,
    NotificationDrawerComponent,
    ToasterComponent,
    EnvBadgeComponent,
  ],
  providers: [NotificationDrawerService, ToasterService, AblyService, SseService, LocalStorageService]
})
export class CoreModule { }
