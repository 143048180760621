<div
  #datepicker
  [ngClass]="{
    'zovu-datepicker': true,
    'range-date': datePickerMode !== 'single'
  }"
  (changeDate)="onDateChange()"
>
  <span class="date-mode" (click)="toggleDatePickerMode()">{{
    (datePickerMode === 'single'
      ? 'advance_search.date_range_button'
      : 'advance_search.date_single_button'
    ) | transloco
  }}</span>
  <input
    type="text"
    name="range-start"
    class="input"
    (changeDate)="onDatesChange()"
  />
  <input
    type="text"
    name="range-end"
    class="input"
    (changeDate)="onDatesChange()"
  />
</div>
