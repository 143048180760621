<div class="period-selector">
  <div
    [style.color]="style[theme].color"
    class="left-icon icon"
    (click)="selectWeek(selectedWeekIndex - 1)"
  >
    <svg-icon *ngIf="selectedWeekIndex > 0" key="arrow-left"></svg-icon>
  </div>
  <div class="periods">
    <div class="label" [style.color]="style[theme].color">
      {{ weeks[selectedWeekIndex] | weekPeriod }}
    </div>
    <div class="indicators">
      <div
        *ngFor="let week of weeks; let i = index"
        [class.active]="selectedWeekIndex === i"
        (click)="selectWeek(i)"
      ></div>
    </div>
  </div>
  <div
    [style.color]="style[theme].color"
    class="right-icon icon"
    (click)="selectWeek(selectedWeekIndex + 1)"
  >
    <svg-icon
      *ngIf="selectedWeekIndex < weeks.length - 1"
      key="arrow-left"
    ></svg-icon>
  </div>
</div>
