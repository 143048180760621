<div class="patient-search-page" *transloco="let t; read: 'retrieving_account'">
  <div *ngIf="showLoader" class="loader">
    <ztp-loader></ztp-loader>
  </div>
  <div class="body" *ngIf="!showLoader">
    <span class="id-text">{{ t('zovu_id') }}</span>
    <div class="id-container">
      <div [ngClass]="{'id-field': true, 'id-field-error': showIdError}">
        <div class="iso-code-container">
          <ng-select #Selecter class="iso-code-select" [items]="africaCountriesCodeIso2" bindLabel="iso2"
            bindValue="iso2" [(ngModel)]="selectedCountryCodeIso2" [searchable]="false" [virtualScroll]="false">
          </ng-select>
          <svg-icon fontSize="12px" icon='chevron-down' key="chevron-down" (click)="openSelectBox()" class="arrow-icon"
            alt="arrow chevron icon">
          </svg-icon>
        </div>

        <input type="text" name="zovuid" [placeholder]="t('zovu_id_field')" [formControl]="zovuInput" #zovuinput
          autocomplete="off" (keydown)="onKeyDown($event)" (ngModelChange)="onInputValueChange($event)" autofocus
          tabindex="0" maxlength="11" />
      </div>
      <button type="button" (click)="submit('_top')" [disabled]="zovuInput.invalid || zovuInput.disabled">
        {{ t('submit_button') }}
      </button>
    </div>
    <span class="id-error" *ngIf="showIdError || showIdServerError || showPatientVerificationError || noPatientSelectionError">
      <svg-icon key="error"></svg-icon>
      <span *ngIf="showIdError; else showIdServerErr" class="text">{{ t('zovu_id_error_msg') }}</span>
      <ng-template #showIdServerErr>
        <span *ngIf="showIdServerError; else showPatientVerificationErr" class="text">{{ t('server_error_msg') }}</span>
      </ng-template>
      <ng-template #showPatientVerificationErr>
        <span *ngIf="showPatientVerificationError; else showNoPatientSelectionErr" class="text">{{ t('patient_verification_error_msg') }}</span>
      </ng-template>
      <ng-template #showNoPatientSelectionErr>
        <span *ngIf="noPatientSelectionError" class="text">{{ t('no_patient_selection_error_msg') }}</span>
      </ng-template>
    </span>

    <div class="separator">
      <span class="line"></span>
      <p class="text">{{ t('or') }}</p>
      <span class="line"></span>
    </div>

    <span class="scan-text">{{ t('qr_code') }}</span>
    <div class="scan-space">
      <div class="scan-body">
        <div class="slider" *ngIf="cameraPermission; else noCameraAccess"></div>
        <ng-template #noCameraAccess>
          <div class="camera-off">
            <svg-icon key="camera-off" fontSize="35px"></svg-icon>
            <span>{{t('no_camera_access_msg')}}</span>
          </div>
        </ng-template>
        <div class="scanner">
          <zxing-scanner [torch]="true" #scanner (scanSuccess)="scan($event, '_top')"
            (permissionResponse)="hasPermission($event)" [formats]="allowedFormats">
          </zxing-scanner>
        </div>
      </div>
    </div>
    <span class="scan-error" *ngIf="showScanError || showScanServerError">
      <svg-icon key="error"></svg-icon>
      <span *ngIf="showScanError; else showScanServerErr" class="text">{{ t('qr_code_error_msg') }}</span>
      <ng-template #showScanServerErr>
        <span class="text">{{ t('server_error_msg') }}</span>
      </ng-template>
    </span>
  </div>
</div>