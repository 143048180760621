import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Subscription } from 'rxjs';
import { FormControl } from '@angular/forms';

import { AuthenticationService, LabCenterService } from 'src/app/core/services';
import { Study } from 'src/app/core/models';

const frEngDiacriticMap: { [key: string]: string } = {
  à: 'a',
  â: 'a',
  ä: 'a',
  é: 'e',
  è: 'e',
  ê: 'e',
  ë: 'e',
  î: 'i',
  ï: 'i',
  ô: 'o',
  ö: 'o',
  ù: 'u',
  û: 'u',
  ü: 'u',
  ÿ: 'y',
  ç: 'c',
};

const protectedStudyNames = [
  'VIH (Sérologie rétrovirale)',
  'Charge virale VIH',
  'Antigène HBs',
  'Charge virale Hépatite B',
];

@Component({
  selector: 'ztp-study-name-selector',
  templateUrl: './study-name-selector.component.html',
  styleUrls: ['./study-name-selector.component.scss'],
})
export class StudyNameSelectorComponent implements OnInit, OnDestroy {
  @Output() onStudyNameChange = new EventEmitter<{
    labTestName: string;
    labDepartment: string;
  }>();

  @Output() onBoxOpen = new EventEmitter<boolean>();

  @ViewChild('Selecter') selectBox: NgSelectComponent;

  @Input()
  set studyName(studyName: FormControl) {
    this.selectedStudyName = studyName?.value?.trim() ?? null;
  }

  selectedStudyName: string;

  studyNames = [];

  searchtearm = '';

  loading = true;

  private studyNamesSubscription: Subscription;

  private storeSub: Subscription;

  constructor(
    private labCenterService: LabCenterService,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit(): void {
    this.loadStudyNames();
  }

  public onSearch(data: any): void {
    this.searchtearm = data?.term;
  }

  public selectStudyName(data: any): void {
    this.onStudyNameChange.emit({
      labTestName: data?.name,
      labDepartment: data?.category,
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public onOpen(_data: any): void {
    this.onBoxOpen?.emit(true);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public onClose(data: any): void {
    this.onBoxOpen?.emit(false);
  }

  public openSelectBox(): void {
    this.selectBox.open();
  }

  public customSearch(term: string, item: any): boolean {
    return item?.name
      .toLowerCase()
      .split('')
      .map((char: string) => frEngDiacriticMap[char] || char)
      .join('')
      .includes(
        term
          .toLowerCase()
          .split('')
          .map((char) => frEngDiacriticMap[char] || char)
          .join('')
      );
  }

  private loadStudyNames(): void {
    this.storeSub = this.authenticationService
      .getCurrentLabTechnician()
      .subscribe(
        (labTechnician) =>
          (this.studyNamesSubscription = this.labCenterService
            .getTechnicianLabCenterDetails(
              labTechnician?.id,
              labTechnician?.labCenterAlias
            )
            .subscribe((labCenterDetails) => {
              this.studyNames = this.filterProtectedStudies(
                labCenterDetails?.availableStudies
              );
              this.loading = false;
            }))
      );
  }

  private filterProtectedStudies(studies: Study[]): Study[] {
    const protectedStudyNameSet = new Set(protectedStudyNames);
    const filteredStudies: Study[] = [];

    for (const study of studies) {
      if (!protectedStudyNameSet.has(study.name)) {
        filteredStudies.push(study);
      }
    }

    return filteredStudies;
  }

  ngOnDestroy(): void {
    this.studyNamesSubscription?.unsubscribe();
    this.storeSub?.unsubscribe();
  }
}
