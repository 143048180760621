import { OnInit, Component, Input } from '@angular/core';
import { ChartOptions, ChartData } from 'chart.js';
import { chartConfig } from '../../configs/chart.config';
import { EMonthType, TOperationSummary, TWeekStats, TWeekStatsPeriod } from '../../models';
import {
  computeMonthSubmittedResults,
  extractWeekPeriods,
  formatCoveredPeriod,
} from '../../utils/count-summary.utils';

@Component({
  selector: 'ztp-count-summary',
  templateUrl: './count-summary.component.html',
  styleUrls: ['./count-summary.component.scss'],
})
export class CountSummaryComponent implements OnInit {
  @Input() operationSummary: TOperationSummary;

  @Input() period: {
    year: number;
    month: EMonthType;
  };

  weekStats: TWeekStats[];

  selectedWeekStats: TWeekStats;

  coveredPeriod: string;

  totalSubmitted: number;

  overrides: ChartOptions = chartConfig;

  public barChartData: ChartData<'bar'>;

  ngOnInit(): void {
    this.coveredPeriod = formatCoveredPeriod(
      this.period.month,
      this.period.year
    );
    this.totalSubmitted = computeMonthSubmittedResults(
      this.operationSummary[this.period.year][this.period.month].weeklyActivity
    );
    this.weekStats = Object.values(
      this.operationSummary[this.period.year][this.period.month].weeklyActivity
    );
    this.selectedWeekStats = this.weekStats[0];
    this.selectWeek();
  }

  selectWeek(weekIndex: number = 0): void {
    this.selectedWeekStats = this.weekStats[weekIndex];
    const stats = this.selectedWeekStats.stats;
    const statsDays = Object.keys(stats);
    this.chartConstructor(
      statsDays.map(
        (day) => ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][day]
      ),
      Object.values(stats).map((stat: any) => stat.totalDailyResults)
    );
  }

  extractWeekPeriods(): TWeekStatsPeriod[] {
    return extractWeekPeriods(this.weekStats);
  }

  private chartConstructor(labels: string[], data: number[]): void {
    this.barChartData = {
      labels,
      datasets: [
        {
          data,
          label: '',
          backgroundColor: '#b0b0b0',
          hoverBackgroundColor: '#333333',
          borderRadius: 5,
          borderSkipped: false,
          barThickness: 20,
        },
      ],
    };
  }
}
