/* eslint-disable @angular-eslint/directive-selector */
import { Directive, ElementRef, HostListener, Input } from '@angular/core';

/*istanbul ignore next */
@Directive({
  selector: '[ztwInputAnimation]',
})

export class InputAnimationDirective {

  // tslint:disable-next-line:no-input-rename
  @Input() ztwInputAnimation;

  @HostListener('keyup') onKeyUp() {
    this.domElmt.nativeElement.style.border = `1px solid ${this.ztwInputAnimation}`;
    this.domElmt.nativeElement.style.transition = 'all 0.25s linear';

  }

  @HostListener('keydown') onKeyDown() {
    this.domElmt.nativeElement.style.border = `1px solid ${this.ztwInputAnimation}`;
  }

  constructor(private domElmt: ElementRef) {
  }

}
