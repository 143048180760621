<div [ngClass]="{'result-unit-card': true, 'result-unit-card-active': active}" *ngIf="labResult">
  <span class="avatar" [style.background]="'linear-gradient('+(labResult?.patient.zovuId | ztpGradiantAngle)+'deg, #0C586C -36.29%, #79C99A 77.8%)'">
    <span>
      {{ labResult?.patient?.givenName | slice: 0:1}}{{ labResult?.patient?.surname | slice: 0:1 }}
    </span>
  </span>
  <div class="patient">
    <span class="name">{{ labResult?.patient?.givenName | titlecase }} {{ labResult?.patient?.surname | titlecase }}</span>
    <span class="id">Zovu ID : {{labResult?.patient?.zovuId | ztpZovuId}}</span>
    <span
    class="date"
    [ztpTooltip]="labResult?.submission.submissionDateTime | zovuFullDateFormat"
    >{{ labResult?.submission.submissionDateTime | zovuDateFormat }}</span>
  </div>
  <div class="result">
    <div class="pills">
      <span class="badge approve" *ngIf="labResult?.approval?.status === 'approved'">
        <svg-icon key="check" fontSize='18px'></svg-icon>&nbsp;
        <span>{{"result_list.approved_marker" | transloco}}</span>
      </span>
      <!-- <span class="badge unapprove" *ngIf="labResult?.approval?.status == 'unapproved'">
        <svg-icon key="unapprove-action" fontSize='18px'></svg-icon>&nbsp;
        <span>Unapproved</span>
      </span> -->
      <span class="id">
        <span>{{labResult?.idAlias}}</span>
      </span>
    </div>
    <span class="name">{{ labResult?.submission.category.studyName }}</span>
    <span class="department" [ztpTooltip]="labResult?.submission.category.labDepartment">
      {{(labResult?.submission.category.labDepartment | slice: 0:15) + (labResult?.submission.category.labDepartment.length > 15 ? '...': '')}}
    </span>
  </div>
</div>