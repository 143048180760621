/* istanbul ignore file */
import { FileItem, FileUploader } from 'ng2-file-upload';

export const renameItem = (itemToBeRenamed: FileItem, uploader: FileUploader): FileItem => {
  const name = itemToBeRenamed?.file.name;
  const fileNameExtension = getFileNameExtension(name);
  const newName = getFileNewName(uploader?.queue, fileNameExtension);
  const newFile = new File([uploader.queue[0]._file], newName, {
    type: itemToBeRenamed?.file.type,
  });
  const newFileItem = new FileItem(
    uploader,
    newFile,
    uploader?.options,
  );
  return newFileItem;
};

export const getFileRealName = (fileName: string): string => {
  if (!fileName) {
    return null;
  }
  const index = fileName?.lastIndexOf('.');
  return fileName.substring(0, index);
};

export const getFileNameExtension = (fileName: string): string => {
  if (!fileName) {
    return null;
  }
  const fileNameExtension = fileName.substr(fileName?.lastIndexOf('.') + 1);
  return fileNameExtension;
};

export const getFileNameWithOccurence = (item: FileItem, times: number, extension: string): string => `${getFileRealName(item.file.name)}_${times}.${extension}`;


export const getFileNewName = (filesQueue: any[], fileNameExtension: string): string => {
  const fileNamesFrequencyTable = {};
  const renamedFileName = filesQueue
    .map((item) => {
      let nameOccurrenceTimes = fileNamesFrequencyTable[item.file.name] || 0;
      fileNamesFrequencyTable[item.file.name] = nameOccurrenceTimes + 1;
      if (!nameOccurrenceTimes) {
        return item.file.name;
      }
      while (fileNamesFrequencyTable[getFileNameWithOccurence(item, nameOccurrenceTimes, fileNameExtension)]) {
        nameOccurrenceTimes++;
      }
      fileNamesFrequencyTable[getFileNameWithOccurence(item, nameOccurrenceTimes, fileNameExtension)] = 1;
      return getFileNameWithOccurence(item, nameOccurrenceTimes, fileNameExtension);
    })
    .find((fileName, fileNameIndex) => fileNameIndex === filesQueue.length - 1);

  return renamedFileName;
};
