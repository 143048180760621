import { Component, Input, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { ILabCenter } from 'src/app/core/models';
import { AuthenticationService } from 'src/app/core/services';
import { ILiveCounter, LiveCounter } from '../../models/live-counter.model';
import { AblyService } from '../../../../../core/services/ably.service';
import { LiveCounterService } from '../../services/live-counter.service';

enum LiveCounterTabs {
  today,
  this_week,
  this_month,
}

@Component({
  selector: 'ztp-live-counter-tabs',
  templateUrl: './live-counter-tabs.component.html',
  styleUrls: ['./live-counter-tabs.component.scss'],
})
export class LiveCounterTabsComponent implements OnDestroy, OnChanges {
  @Input() currentLabCenter: ILabCenter;

  tabs: typeof LiveCounterTabs = LiveCounterTabs;

  selectedTab = LiveCounterTabs.today;

  showLoadPlaceHolder = true;

  showChart = false;

  ablyRealTimeCount = 0;

  private liveCountSub: Subscription;
  private liveCounterSub: Subscription;
  liveCounter: ILiveCounter;
  chartData: any;

  constructor(
    private readonly ablyService: AblyService,
    private readonly liveCounterService: LiveCounterService,
    private readonly authService: AuthenticationService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.showLoadPlaceHolder = true;
    this.liveCounter = null;
    this.getLiveCounter();
    this.subscribeToAblyLiveCount();
  }

  chooseTab(index: number): void {
    if (this.selectedTab !== index) {
      this.selectedTab = index;
    }
  }

  getTabs(): Array<any> {
    const _tabs = Object.values(this.tabs).filter(
      (value) => typeof value === 'string'
    );
    return _tabs;
  }

  toogleChart(): void {
    this.showChart = !this.showChart;
  }

  subscribeToAblyLiveCount(): void {
    this.liveCountSub = this.ablyService
      .subscribeToLiveCount(this.currentLabCenter.id)
      .subscribe(() => {
        ++this.ablyRealTimeCount;
      });
  }

  getLiveCounter(): void {
    this.authService
      .getCurrentLabTechnician()
      .subscribe((currentLabTechnician) => {
        this.liveCounterSub = this.liveCounterService
          .getLiveCounter(currentLabTechnician)
          .subscribe((counter) => {
            this.liveCounter = new LiveCounter(counter);
            this.chartData = {
              this_week: [
                {
                  startDate: this.liveCounter.this_week.startDate,
                  endDate: this.liveCounter.this_week.endDate,
                  labels: Object.keys(this.liveCounter.this_week.days),
                  values: Object.values(this.liveCounter.this_week.days),
                },
              ],
              this_month: Object.values(this.liveCounter.this_month.weeks).map(
                (value) => ({
                  startDate: value.startDate,
                  endDate: value.endDate,
                  labels: Object.keys(value.days),
                  values: Object.values(value.days),
                })
              ),
            };
            this.showLoadPlaceHolder = false;
          });
      });
  }

  ngOnDestroy(): void {
    this.liveCountSub.unsubscribe();
    this.liveCounterSub.unsubscribe();
    this.ablyService.disconnectLiveCountEventSource(this.currentLabCenter.id);
  }

  /**
   * This method's role is to keep the object order when looping with keyvalue directive
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  keepOrder = (a, _b) => a;
}
