<div class="operation-summary">
  <div class="header">
    <span class="label">{{ coveredPeriod }}</span>
    <span class="count">{{ totalSubmitted }}</span>
    <span class="label">{{
      'dashboard.operation-summary.count-summary.results-submitted' | transloco
    }}</span>
  </div>
  <div class="divider"></div>
  <ztp-period-selector
    [theme]="'dark'"
    (selectWeekEvent)="selectWeek($event)"
    [weeks]="extractWeekPeriods()"
  ></ztp-period-selector>
  <canvas baseChart class="chart" [data]="barChartData" [options]="overrides">
  </canvas>
</div>
