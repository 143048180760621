<div class="toast">
    <div class="toast-avatar--container">
        <span class="avatar">
            {{ toastData?.patient.givenName | slice: 0:1}}{{ toastData?.patient.surname | slice: 0:1 }}
        </span>
    </div>
    <div class="toast-info--container">
        <div class="toast-info--header">
            <span class="toast--message">
                <ng-container class="toast--message" *transloco="let t">
                    <div
                        [innerHTML]="t('toast.in_progress_message',{lastName: toastData?.patient.surname}) | safe:'html'">
                    </div>
                </ng-container>
            </span>
            <span class="patient--testname">{{toastData?.studyName}}</span>
        </div>
        <div class="toast-info--loader">
            <div [ngClass]="{'progress-bar': toastData.status === 'progress'}">
                <div class="inner-bar">&nbsp;</div>
            </div>
        </div>
        <span class="toast-info--footer">
            <span class="patient--zovu-id">Zovu ID : {{ toastData?.patient.zovuId }}</span>
            <ng-container *transloco="let t">
                <span class="patient-file"
                    [innerHTML]="t('toast.file',{fileType: toastData.media.type, pageCount: toastData?.media.pagesCount})| safe:'html'">
                </span>
            </ng-container>
            <div class="dot">&nbsp;</div>
            <span class="time">{{ 'toast.elapsed_time' | transloco }}</span>
        </span>
    </div>
    <div class="toast-close-btn-container">
        <svg-icon class="toast-close" (click)="close()" fontSize="18px" key="toast-close"></svg-icon>
    </div>
</div>