/* eslint-disable import/no-extraneous-dependencies */
/*istanbul ignore file */
import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { AuthenticationService, LabCenterService, LocalStorageService } from '../../services';
import { NotificationDrawerService } from '../notification-drawer/notification-drawer.service';
import { SubmissionService } from 'src/app/features/result-unit-upload/submission-page/submission-page.service';

import {
  IAssignedLabCenter,
  ITechnician,
} from '../../models/technician.interface';
import { ESubmissionStatus } from '../../../features/result-unit-upload/submission-page/submission.interface';

@Component({
  selector: 'ztp-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() loggedIn: boolean;

  private authSub: Subscription;

  private failedLabSubmissionsSub: Subscription;

  badgeCounter = 0;

  showNavMenu: boolean;

  showLabCentersDropdown: boolean;

  labTechnician: ITechnician;

  currentRoute: string;

  displayRole = {
    'lab-head': 'Lab Head',
    'lab-technician': 'Technician',
  };

  currentAssignedLabCenter: IAssignedLabCenter;

  constructor(
    private readonly auth: AuthenticationService,
    private readonly submissionsService: SubmissionService,
    private readonly router: Router,
    private readonly drawerService: NotificationDrawerService,
    private readonly localStorageService: LocalStorageService,
    private readonly labCenterService: LabCenterService
  ) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = event.url;
      }
    });
  }

  ngOnInit(): void {
    this.getloggedUser();
    this.getBadgerCount();
  }

  getloggedUser(): void {
    this.authSub = this.auth.stateChanged.subscribe(() => {
      this.authSub = this.auth
        .getCurrentLabTechnician()
        .subscribe((labTechnician) => {
          this.labTechnician = labTechnician;
        });
    });
  }

  getBadgerCount(): void {
    this.failedLabSubmissionsSub =
      this.submissionsService.stateChanged.subscribe((state) => {
        if (state && state.submissionDrafts) {
          const labSubs = Object.values(state.submissionDrafts).filter(
            (labSub) =>
              labSub.submissionStatus === ESubmissionStatus.FAILED ||
              labSub.submissionStatus === ESubmissionStatus.RE_UPLOAD
          );
          this.badgeCounter = labSubs.length;
        }
      });
  }

  logUserOut() {
    this.localStorageService.clear();
    this.auth.logout();
  }

  toggleDrawer() {
    this.drawerService.togglePanel();
  }

  toggleNavMenu() {
    this.showNavMenu = !this.showNavMenu;
  }

  toggleLabCentersDropdown() {
    this.showLabCentersDropdown = !this.showLabCentersDropdown;
  }

  onNavClickOustide() {
    this.showNavMenu = false;
  }

  onLabCenterDropdownClickOutside() {
    this.showLabCentersDropdown = false;
  }

  onSwitchLabCenter(labCenter: IAssignedLabCenter) {
    this.localStorageService.setItem(
      'labCenter',
      `${labCenter.alias}_${labCenter.id}`
    );
    this.auth.saveCurrentLabTechnician(this.labTechnician, labCenter);
    this.toggleLabCentersDropdown();
  }

  ngOnDestroy(): void {
    this.authSub?.unsubscribe();
    this.failedLabSubmissionsSub?.unsubscribe();
  }
}
