import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
  Datepicker,
  DateRangePicker,
} from '../../../../../../../../node_modules/ztp-datepicker/js/main.js';

@Component({
  selector: 'ztp-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatePickerComponent implements OnInit, AfterViewInit {
  @Output() onDateChanges = new EventEmitter<FormGroup>();

  @ViewChild('datepicker') datepickerRef: ElementRef;

  datepicker: typeof Datepicker | typeof DateRangePicker;

  dateForm = new FormGroup({
    startDateInput: new FormControl(''),
    endDateInput: new FormControl(''),
  });

  datepickerOptions = {
    allowOneSidedRange: true,
    autohide: false,
    beforeShowDay: null,
    beforeShowDecade: null,
    beforeShowMonth: null,
    beforeShowYear: null,
    calendarWeeks: false,
    clearBtn: false,
    dateDelimiter: ',',
    datesDisabled: [],
    daysOfWeekDisabled: [],
    daysOfWeekHighlighted: [],
    defaultViewDate: undefined,
    disableTouchKeyboard: true,
    format: 'mm/dd/yyyy',
    language: 'en',
    maxDate: null,
    maxNumberOfDates: 1,
    maxView: 2,
    minDate: null,
    nextArrow:
      '<svg width="10" height="14" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.6843 9.84824L6.35156 5.3376L1.6843 0.82696C1.4194 0.571789 0.997802 0.579678 0.742631 0.844579C0.48746 1.10948 0.495349 1.53108 0.760251 1.78625L4.43689 5.3376L0.760251 8.89286C0.495349 9.14803 0.48746 9.56963 0.742631 9.83453C0.997802 10.0994 1.4194 10.1073 1.6843 9.85215V9.84824Z" fill="#0C586C"/></svg>',
    orientation: 'auto',
    pickLevel: 0,
    prevArrow:
      '<svg width="10" height="13" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.33327 0.597077L0.666016 5.10772L5.33327 9.61835C5.59818 9.87352 6.01978 9.86563 6.27495 9.60073C6.53012 9.33583 6.52223 8.91423 6.25733 8.65906L2.58069 5.10772L6.25733 1.55245C6.52223 1.29729 6.53012 0.875683 6.27495 0.610781C6.01978 0.34588 5.59818 0.337991 5.33327 0.593162V0.597077Z" fill="#0C586C"/></svg>',
    showDaysOfWeek: true,
    showOnClick: true,
    showOnFocus: true,
    startView: 0,
    title: '',
    todayBtn: false,
    todayBtnMode: 0,
    todayHighlight: false,
    updateOnBlur: true,
    weekStart: 0,
  };

  datePickerMode: 'single' | 'range' = 'single';

  ngOnInit(): void {
    this.datepickerOptions['language'] = 'en';
    this.datepickerOptions['format'] = 'mm/dd/yyyy';
    this.datepickerOptions['maxDate'] = new Date();
  }

  async ngAfterViewInit() {
    this.datepicker = new Datepicker(
      this.datepickerRef?.nativeElement,
      this.datepickerOptions
    );
    await this.datepicker?.setDate(new Date());
    this.onDateChange();
  }

  public async toggleDatePickerMode(): Promise<void> {
    this.dateForm.reset({ startDateInput: '', endDateInput: '' });
    if (this.datePickerMode === 'single') {
      this.datePickerMode = 'range';
      this.datepicker?.destroy();
      this.datepicker = new DateRangePicker(
        this.datepickerRef?.nativeElement,
        this.datepickerOptions
      );
    } else {
      this.datePickerMode = 'single';
      this.datepicker?.destroy();
      this.datepicker = new Datepicker(
        this.datepickerRef?.nativeElement,
        this.datepickerOptions
      );
      await this.datepicker?.setDate(new Date());
      this.onDateChange();
    }
  }

  public onDateChange(): void {
    const selectedDate = this.datepicker?.getDate('mm/dd/yyyy');
    this.dateForm.setValue({
      startDateInput: selectedDate,
      endDateInput: selectedDate,
    });
    this.onDateChanges.emit(this.dateForm);
  }

  public onDatesChange(): void {
    // eslint-disable-next-line no-unsafe-optional-chaining
    const [selectedStartDate, selectedEndDate] = this.datepicker?.getDates('mm/dd/yyyy');
    this.dateForm.setValue({
      startDateInput: selectedStartDate ?? '',
      endDateInput: selectedEndDate ?? '',
    });
    this.onDateChanges.emit(this.dateForm);
  }
}
